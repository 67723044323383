import React from "react";

const ModalBodyDelete = ({ name }) => (
  <div className="modal-body">
    <div className="form-row">
      <div className="col-md">
        Are you sure you want to delete <b>{name}</b>?
      </div>
    </div>
  </div>
);

export default ModalBodyDelete;
