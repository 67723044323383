import {
  // AsnVolumeColumnDefinition,
  CreatedAtColumnDefinition,
  ShipToStoreColumnDefinition,
  AsnNumberColumnDefinition,
  WeightColumnDef,
  TotalCarton,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { AddASNToShipment } from "components/AGTable/NewColumns/AddASNToShipment";
import { AsnDetails } from "components/AGTable/NewColumns/AsnDetails";
import { GoToPoDetailFromAsn } from "components/AGTable/NewColumns/GoToPoDetailFromAsn";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const asnAddToShipmentGrid = {
  columnDefs: [
    AddASNToShipment,
    GoToPoDetailFromAsn,
    AsnDetails,
    AsnNumberColumnDefinition,
    TotalCarton,
    ShipToStoreColumnDefinition,
    CreatedAtColumnDefinition,
    // AsnVolumeColumnDefinition("volume"),
    WeightColumnDef,
    {
      headerName: "Ship from",
      field: "warehouse.name",
      width: 125,
    },
  ],
  defaultColDef,
};
