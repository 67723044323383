import React from "react";

const ModalFooterUpdateClose = (props) => (
  <div className="modal-footer">
    <button
      ref={props.inputRef}
      type="button"
      className="btn btn-secondary"
      data-dismiss="modal"
    >
      Close
    </button>
    <button id="submit-btn" type="submit" className="btn btn-primary">
      Update
    </button>
  </div>
);

export default ModalFooterUpdateClose;
