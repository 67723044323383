import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getClients } from "redux/actions/client.action";
import { resetUpToDateFlag } from "redux/actions/global.action";
import { client as c } from "config/constants";
import { clientGrid } from "./tables/clientGrid";
import {
  ContentHeader,
  Content,
  AGTable,
  ActionAlerts,
  Card,
} from "components";

const Clients = ({ user, client, getClients, resetUpToDateFlag }) => {
  useEffect(() => {
    if (user.user.isSuper() && !client.clientsUpToDate) {
      getClients();
    }
  }, [user.user, getClients, client.clientsUpToDate]);

  useEffect(() => {
    return () => {
      resetUpToDateFlag();
    };
  }, [resetUpToDateFlag]);

  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Clients" />
      <Content>
        <Card title="Clients">
          <ActionAlerts item={client} constants={c} />
          <AGTable
            tableName="clients"
            paginate={true}
            gridConfig={clientGrid}
            rowData={client.clients}
            isDoneLoading={client.clientsRetrieved}
            rowWidth={200}
          />
        </Card>
      </Content>
    </div>
  );
};

export default connect(({ user, client }) => ({ user, client }), {
  getClients,
  resetUpToDateFlag,
})(Clients);
