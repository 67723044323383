import Service from "./service";
import { resetAlerts } from "./global.action";

export const getCartons = (queryParam) => (dispatch) => {
  Service.get(dispatch, "carton/cartons", queryParam || {}, {
    init: "GET_CARTONS",
    success: "GET_CARTONS_SUCCESS",
    error: "GET_CARTONS_FAILED",
  });
};

export const getAllCartons = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.page_size = 20000;
  Service.get(dispatch, "carton/cartons", qp, {
    init: "GET_ALL_CARTONS",
    success: "GET_ALL_CARTONS_SUCCESS",
    error: "GET_ALL_CARTONS_FAILED",
  });
};

export const createCarton = (carton) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "carton/cartons/", carton, {
    init: "ADD_CARTON",
    success: "ADD_CARTON_SUCCESS",
    error: "ADD_CARTON_FAILED",
  });
};

export const deleteCarton = (id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.delete(dispatch, `carton/cartons/${id}`, {
    init: "DELETE_CARTON",
    success: "DELETE_CARTON_SUCCESS",
    error: "DELETE_CARTON_FAILED",
  });
};

export const updateCarton = (carton) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.patch(dispatch, `carton/cartons/${carton.id}`, carton, {
    init: "UPDATE_CARTON",
    success: "UPDATE_CARTON_SUCCESS",
    error: "UPDATE_CARTON_FAILED",
  });
};
