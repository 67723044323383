import React from "react";
import { connect } from "react-redux";
import { Card, AGTable, ActionAlerts } from "components";
import { asnClientGrid } from "../../ASN/tables/asnClientGrid";
import { getExpiredASNs } from "redux/actions/asn.action";
import { carrier as c } from "config/constants";

class ExpiredAsns extends React.Component {
  componentDidMount() {
    this.props.getExpiredASNs();
  }

  // expiredAsns(asns) {
  //   const today = () => moment(Date.now()).format("YYYY-MM-DD");

  //   return asns.filter(
  //     asn =>
  //       asn.details.length !== 0 &&
  //       moment(asn.details[0].po_line.cancel_date).isBefore(today()) &&
  //       asn.shipment === null &&
  //       (!config.getFlaggedFeatureFromLocalStorage() || !asn.flagged)
  //   );
  // }

  render() {
    const { asn } = this.props;
    return (
      <Card title="Expired ASNs">
        <ActionAlerts item={asn} constants={c} />
        <AGTable
          tableName="expiredAsn"
          paginate={true}
          gridConfig={asnClientGrid}
          rowData={asn.expiredAsns}
          isDoneLoading={asn.expiredAsnsRetrieved}
          rowHeight={"35"}
          action={this.props.getExpiredASNs}
          next={asn.enext}
          previous={asn.eprevious}
          totalRows={asn.etotalRows}
        />
      </Card>
    );
  }
}
export default connect(({ asn }) => ({ asn }), { getExpiredASNs })(ExpiredAsns);
