import { RemainingQtyColumnDef } from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const poLineClientGrid = {
  columnDefs: [
    {
      headerName: "Vendor",
      colId: "vendor_name",
      field: "poLine.vendor_name",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 225,
    },
    {
      headerName: "PO #",
      colId: "po_number",
      field: "poLine.po_number",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 105,
    },
    {
      headerName: "PO Line #",
      colId: "line_number",
      field: "poLine.line_number",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 85,
      sort: "asc",
    },
    {
      headerName: "ASN",
      colId: "Asn",
      field: "poLine.Asn",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 85,
    },
    {
      headerName: "Start Date",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "start_date",
      field: "poLine.start_date",
      width: 105,
      filter: "RadioSetFilter",
    },
    {
      headerName: "Cancel Date",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "cancel_date",
      field: "poLine.cancel_date",
      width: 115,
      filter: "RadioSetFilter",
    },
    {
      headerName: "Description",
      colId: "description",
      field: "poLine.description",
      width: 155,
    },
    {
      headerName: "Style",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "style",
      field: "poLine.style",
      width: 165,
    },
    {
      headerName: "Vendor Style",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "vendor_style",
      field: "poLine.vendor_style",
      width: 125,
    },
    {
      headerName: "SKU",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "sku",
      field: "poLine.sku",
      width: 145,
    },
    {
      headerName: "Vendor SKU",
      colId: "vendor_sku",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.vendor_sku",
      width: 125,
    },
    {
      headerName: "Color",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "color",
      field: "poLine.color",
      width: 150,
    },
    {
      headerName: "Size",
      colId: "size",
      field: "poLine.size",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 85,
    },

    RemainingQtyColumnDef({
      field: "poLine.remaining_qty",
      colId: "remaining_qty",
    }),
    {
      headerName: "Warehouse",
      colId: "ware_house",
      field: "poLine.ware_house",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 105,
    },
  ],
  defaultColDef,
};
