import React from "react";
import OpenModalIcon from "./components/OpenModalIcon";

const cellRender = ({ data, context }) => {
  return (
    <OpenModalIcon
      click={() =>
        context.componentParent.updateData(data, "shipment", "Carrier")
      }
      icon={"icon-truck"}
    />
  );
};

export const ShipmentCarrier = {
  headerName: "Carrier",
  filter: null,
  cellStyle: { display: "flex", "justify-content": "center" },
  field: "carrier",
  width: 75,
  sortable: false,
  cellRendererFramework: cellRender,
  suppressSizeToFit: true,
};
