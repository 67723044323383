import React from "react";

const CellRendererFramework = ({ data }) => (
  <div>
    {data?.details?.map((e, index) => (
      <div key={index}>{e.po_line.cancel_date}</div>
    ))}
    {!data?.details?.length && <div>-</div>}
  </div>
);

export const AsnIgnoreShipButton = {
  headerName: "Cancel Date",
  colId: "cancel_date",
  filter: null,
  field: "created_at",
  cellRendererFramework: CellRendererFramework,
  width: 145,
  cellStyle: { display: "flex", justifyContent: "center" },
};
