import React from "react";
import "./ContentHeaderMenu.css";
import BasicMenu from "./BasicMenu";
import AddDeleteMenu from "./AddDeleteMenu";

class ContentHeaderMenu extends React.Component {
  render() {
    const { menuType } = this.props;
    return (
      <>
        {menuType === "Basic" ? (
          <BasicMenu />
        ) : (
          <AddDeleteMenu action={this.props.action} />
        )}
      </>
    );
  }
}
export default ContentHeaderMenu;
