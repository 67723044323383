import {
  NameColumnDef,
  EditDeleteColumnDef,
  EmailColumnDef,
  AddressColumnDef,
  CityColumnDef,
  CountryColumnDef,
  StateColumnDef,
  ZipcodeColumnDef,
  PhoneColumnDef,
  ContactColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },
  filter: true,
};

export const carrierDefaultGrid = {
  columnDefs: [
    EditDeleteColumnDef,
    NameColumnDef,
    AddressColumnDef,
    CityColumnDef,
    StateColumnDef,
    CountryColumnDef,
    ZipcodeColumnDef("zipcode"),
    PhoneColumnDef,
    EmailColumnDef,
    ContactColumnDef,
  ],
  defaultColDef,
};
