import React from "react";
import { AGTable } from "components";
import { connect } from "react-redux";
import { shipmentWarehouseGrid } from "./tables/shipmentWarehouseGrid";

const ShipmentWarehouseView = ({ modal: { selectedData } }) => {
  return (
    <>
      <div className="modal-body">
        <AGTable
          isDoneLoading={true}
          isSearchEnable={true}
          tableName="shipmentWarehouseView"
          gridConfig={shipmentWarehouseGrid}
          rowData={[selectedData.warehouse]}
        />
      </div>
    </>
  );
};
export default connect(({ modal }) => ({ modal }), {})(ShipmentWarehouseView);
