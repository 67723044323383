import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteUser } from "redux/actions/user.action";
import ModalFooterDeleteClose from "../components/ModalFooterDeleteClose";
import ModalBodyDelete from "../components/ModalBodyDelete";

class UserModalForm extends React.Component {
  render() {
    const {
      modal: { selectedData },
    } = this.props;
    return (
      <>
        <form>
          <ModalBodyDelete name={selectedData.first_name} />
          <ModalFooterDeleteClose
            click={() => {
              this.props.deleteUser(selectedData.id);
            }}
          />
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user }) => ({ modal, user }),
  (dispatch) => bindActionCreators({ deleteUser }, dispatch),
)(UserModalForm);
