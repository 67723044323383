import {
  CreatedAtColumnDefinition,
  ActiveColumnDef,
  NameColumnDef,
  PhoneColumnDef,
  ContactColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { VendorDetails } from "components/AGTable/NewColumns/VendorDetails";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const vendorGrid = {
  columnDefs: [
    VendorDetails,
    {
      headerName: "Vendor #",
      width: 105,
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "vendor_number",
    },
    {
      headerName: "# of Users",
      width: 105,
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "users",
    },
    NameColumnDef,
    ContactColumnDef,
    PhoneColumnDef,
    ActiveColumnDef,
    CreatedAtColumnDefinition,
  ],
  defaultColDef,
};
