import Service from "redux/actions/service";
export default class Config {
  static baseUrl = (function () {
    let baseUrl;
    if (process.env.NODE_ENV === "development") {
      baseUrl = "http://18.217.52.96:8000/api/";
    } else {
      baseUrl =
        window.location.protocol + "//" + window.location.host + "/api/";
    }
    return baseUrl;
  })();

  static serviceHeaders = {
    "Content-Type": "application/json",
    Authorization: this.getAuthTokenFromLocalStorage(),
  };

  static getAuthTokenFromLocalStorage() {
    return window.sessionStorage.getItem("token");
  }

  static setAuthToken(data) {
    const { token, user, refresh } = data;
    const tokenPhrase = "Bearer " + token;
    if (token) this.serviceHeaders.Authorization = tokenPhrase;
    token && window.sessionStorage.setItem("token", tokenPhrase);
    refresh && window.sessionStorage.setItem("refresh", refresh);
    window.sessionStorage.setItem("user", JSON.stringify(user));
  }

  static setOnOffFeature(data) {
    const { ENABLE_FLAGGED_POS, together_allowed_stores } = data;
    window.sessionStorage.setItem("flagged_feature", ENABLE_FLAGGED_POS);
    window.sessionStorage.setItem(
      "together_allowed_stores",
      together_allowed_stores,
    );
  }

  static getFlaggedFeatureFromLocalStorage() {
    if (window.sessionStorage.getItem("flagged_feature") === "true")
      return true;
    else return false;
  }

  static getTogetherAllowedStores() {
    return JSON.parse(window.sessionStorage.getItem("together_allowed_stores"));
  }

  static log(activity, description) {
    try {
      if (typeof description === "object") {
        description = JSON.stringify(description);
      }
      const userId = JSON.parse(window.sessionStorage.getItem("user")).id;
      const data = {
        user: userId,
        activity: activity,
        description: description,
      };
      Service.fetchpost("tools/user_logs/", data);
    } catch (e) {}
  }
}

export const log = (action) => {
  const { type, data, error } = action;
  const activity = type;
  const description = data ? data : error;
  if (description) {
    Config.log(activity, description);
  }
};
