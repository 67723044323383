import React, { useEffect } from "react";
import { Card, AGTable, ActionAlerts } from "components";
import { poGrid } from "../tables/poGrid";
import { getExpiringPos } from "redux/actions/pos.action";
import { connect } from "react-redux";
import { carrier as c } from "config/constants";

const ExpiringPos = ({ po, getExpiringPos }) => {
  useEffect(() => {
    getExpiringPos();
  }, [getExpiringPos]);
  return (
    <>
      <Card title="Expiring POs">
        {po.error && <ActionAlerts item={po} constants={c} />}
        <AGTable
          tableName="expiringPOs"
          paginate
          gridConfig={poGrid}
          rowData={po.expiringPos}
          isDoneLoading={po.expiringPosRetrieved}
          action={getExpiringPos}
          next={po.exNext}
          previous={po.exPrevious}
          totalRows={po.exTotalRows}
        />
      </Card>
    </>
  );
};

export default connect(({ po }) => ({ po }), { getExpiringPos })(ExpiringPos);
