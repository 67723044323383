import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";
import Users from "./Users";
import Carrier from "./Carrier";
import Clients from "./Client";
import Vendor from "./Vendor";
import Reports from "./Reports";
import Pos from "./Pos";
import PoDetails from "./Pos/details";
import Recover from "./Recover";
import Login from "./Login";
import Profile from "./Profile";
import ASN from "./ASN";
import Shipment from "./Shipment";
import Carton from "./Cartons";
import Logs from "./Logs";
import Tools from "./Tools";
import CarrierDetails from "./Carrier/details";
import VendorDetails from "./Vendor/details";
import ShipmentDetails from "./Shipment/details";
import Warehouse from "./Warehouse";
// import Settings from "./Settings"; COMMENT CODE 3112020-SETTINGS
import Email from "./Email";
import { Auth } from "components";

import "./App.css";
import TokenRefresher from "components/TokenRefresher";

class App extends Component {
  render() {
    const { layout, user } = this.props;
    return (
      <div>
        {user.isLoggedIn && <TokenRefresher />}

        <Router>
          <div className={"navbar-top " + layout.sidebarClass}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/recover" component={Recover} />

              <Auth>
                <Route exact path="/home" component={Home} />
                <Route path="/report" component={Reports} />
                <Route path="/user" component={Users} />
                <Route exact path="/carrier" component={Carrier} />
                <Route path="/carrier/:carrier" component={CarrierDetails} />
                <Route path="/client" component={Clients} />
                <Route exact path="/vendor" component={Vendor} />
                <Route path="/vendor/:vendor" component={VendorDetails} />
                <Route exact path="/pos" component={Pos} />
                <Route path="/pos/:po" component={PoDetails} />
                <Route path="/profile" component={Profile} />
                <Route path="/asn" component={ASN} />
                <Route path="/carton" component={Carton} />
                <Route exact path="/shipment" component={Shipment} />
                <Route path="/shipment/:shipment" component={ShipmentDetails} />
                <Route path="/logs" component={Logs} />
                <Route path="/warehouse" component={Warehouse} />
                <Route path="/tools" component={Tools} />
                {/* COMMENT CODE 3112020-SETTINGS */}
                {/* <Route path="/settings" component={Settings} /> */}
                <Route path="/email" component={Email} />
              </Auth>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default connect(({ layout, user }) => ({ layout, user }))(App);
