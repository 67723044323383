/* eslint-disable eqeqeq, no-unused-vars, react-hooks/exhaustive-deps, react/no-direct-mutation-state */

import { useState, useRef, useEffect } from "react";
import requestAnimationFrame from "raf";

const ScrollManager = ({ children, ...props }) => {
  const randomNumber = Math.random();
  const [verticalPosition, setVerticalPosition] = useState(0);
  const [horizontalPosition, setHorizontalPosition] = useState(0);
  const ref = useRef(null);
  const scrollTarget = ref.current;

  const customScroll = (horizontalPosition, verticalPosition) => {
    if (scrollTarget instanceof window.Window) {
      requestAnimationFrame(() => {
        scrollTarget.scrollTo(horizontalPosition, verticalPosition);
      });
    } else {
      scrollTarget.scrollLeft = horizontalPosition;
      scrollTarget.scrollTop = verticalPosition;
    }
  };

  useEffect(() => {
    if (scrollTarget) {
      const { scrollTop, scrollLeft } = scrollTarget;
      if (verticalPosition !== scrollTop || horizontalPosition !== scrollLeft) {
        customScroll(horizontalPosition, verticalPosition);
      }
    }
  }, [scrollTarget, horizontalPosition, verticalPosition, randomNumber]);
  if (scrollTarget) {
    const { scrollTop, scrollLeft } = scrollTarget;
    if (verticalPosition !== scrollTop) {
      setVerticalPosition(scrollTop);
    }
    if (horizontalPosition !== scrollLeft) {
      setHorizontalPosition(scrollLeft);
    }
  }

  return children && children({ ...props, connectScrollTarget: ref });
};

export default ScrollManager;
