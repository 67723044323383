// TODO:remove this file
import {
  // AsnVolumeColumnDefinition,
  CreatedAtColumnDefinition,
  FlaggedColumnDefinition,
  AsnNumberColumnDefinition,
  WeightColumnDef,
  TotalCarton,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { AsnDetails } from "components/AGTable/NewColumns/AsnDetails";
import config from "config/config";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
};

const columnDefs = [
  {
    width: 70,
    cellStyle: { display: "flex", "justify-content": "center" },

    cellRenderer: "EditDeleteAsnButtons",
  },
  AsnDetails,
  AsnNumberColumnDefinition,
  CreatedAtColumnDefinition,

  // AsnVolumeColumnDefinition("volume"),
  WeightColumnDef,
  TotalCarton,
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(4, 0, FlaggedColumnDefinition);

export const editAsnGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
