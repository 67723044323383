import React, { Component } from "react";

export default class RadioSetFilter extends Component {
  constructor(props) {
    super(props);
    this.ALL = "All";
    const title = props.colDef.headerName;
    this.state = { possibleValues: [], title, filterValue: this.ALL };
  }

  arraysEqual(firstArray, secondArray) {
    return JSON.stringify(firstArray) === JSON.stringify(secondArray);
  }

  updatePossibleValues() {
    const { valueGetter, rowModel } = this.props;
    const potentiallyNewValues = rowModel.rowsToDisplay.map(valueGetter);
    const savedValues = this.state.possibleValues;
    const possibleValues = potentiallyNewValues
      .concat(savedValues)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();
    if (!this.arraysEqual(possibleValues, savedValues)) {
      this.setState({ possibleValues });
    }
  }

  get possibleValues() {
    const { possibleValues } = this.state;
    const result = possibleValues.slice(0);
    result.unshift(this.ALL);
    return result;
  }

  componentDidUpdate() {
    this.updatePossibleValues();
  }

  componentWillMount() {
    this.updatePossibleValues();
  }

  getModel() {
    return { value: this.state.filterValue };
  }

  setModel(model) {
    const currentValue = this.state.filterValue;
    const newValue = model ? model.value : this.ALL;
    if (currentValue !== newValue) {
      this.setState({ filterValue: newValue });
    }
  }

  onNewRowsLoaded() {
    this.updatePossibleValues();
  }

  isFilterActive() {
    return this.state.filterValue !== this.ALL;
  }

  doesFilterPass(params) {
    const { filterValue } = this.state;
    const { valueGetter } = this.props;
    const value = String(valueGetter(params.node));
    return value === filterValue;
  }

  onChange(event) {
    const newValue = event.target.value;
    if (this.state.filterValue !== newValue) {
      this.setState({ filterValue: newValue }, () => {
        this.props.filterChangedCallback();
      });
    }
  }

  formatValue(value) {
    return String(value).charAt(0).toUpperCase() + String(value).slice(1);
  }

  render() {
    const { title } = this.state;
    const possibleValues = this.possibleValues;
    return (
      <form
        style={{
          margin: "1px",
          height: "79px",
          marginRight: "7px",
          paddingTop: "4px",
        }}
      >
        {possibleValues.map((possibleValue, index) => (
          <label key={index}>
            <input
              type="radio"
              name={title}
              defaultChecked={index === 0}
              value={possibleValue}
              onChange={this.onChange.bind(this)}
            />
            {this.formatValue(possibleValue)}
          </label>
        ))}
      </form>
    );
  }
}
