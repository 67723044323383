import {
  NameColumnDef,
  EmailColumnDef,
  AddressColumnDef,
  CityColumnDef,
  CountryColumnDef,
  StateColumnDef,
  ZipcodeColumnDef,
  PhoneColumnDef,
  ContactColumnDef,
  PickUpWindowColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },
  filter: true,
};

export const carrierGrid = {
  columnDefs: [
    NameColumnDef,
    AddressColumnDef,
    CityColumnDef,
    StateColumnDef,
    CountryColumnDef,
    ZipcodeColumnDef("zipcode"),
    PhoneColumnDef,
    EmailColumnDef,
    ContactColumnDef,

    // {
    //   headerName: "Max Vol (ft³)",
    //   cellStyle: NumberCellStyle,
    //   width: 115,

    //   field: "max_volume",
    // },
    // {
    //   headerName: "Min Vol (ft³)",
    //   cellStyle: NumberCellStyle,
    //   width: 115,

    //   field: "min_volume",
    // },
    PickUpWindowColumnDef,
    {
      headerName: "Consolidator",
      width: 125,
      field: "xml",
      valueGetter: (params) => {
        return params.data.xml === true ? "Yes" : "No";
      },
    },
  ],
  defaultColDef,
};
