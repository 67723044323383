import React from "react";
import OpenModalIcon from "../NewColumns/components/OpenModalIcon";

class Components extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <OpenModalIcon
        click={() =>
          this.props.context.componentParent.updateData(
            data,
            "view",
            "Components",
          )
        }
        icon={"icon-menu6"}
      />
    );
  }
}
export default Components;
