import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createUser } from "redux/actions/user.action";
import { Typeahead } from "react-bootstrap-typeahead";
import { validateEmail } from "utils/helperFunctions";

class UserModalForm extends React.Component {
  state = this.initialState();

  updateUser(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  clearFields() {
    if (this.refs.typeahead) {
      this.refs.typeahead.getInstance().clear();
      this.setState(this.initialState());
    } else {
      this.setState(this.initialState());
    }
  }
  closeModal() {
    this.closeButton.click();
  }

  initialState() {
    return {
      data: {
        vendor_id: 0,
        role_id: "",
        first_name: "",
        last_name: "",
        email: "",
        client_id: this.props.user.user.isSuper()
          ? ""
          : this.props.user.user.client.id,
      },
      emailError: "",
    };
  }
  handleSubmit() {
    const { email } = this.state.data;

    if (this.state?.data?.vendor_id === 0) {
      delete this.state.data.vendor_id;
    }

    // Email validation
    if (email && !validateEmail(email)) {
      this.setState({ emailError: "Invalid email address format" });
      return;
    }

    // Clear error if email is valid
    this.setState({ emailError: "" });

    // Proceed with form submission
    this.props.createUser(this.state.data);
    this.clearFields();
    this.closeModal();
  }
  render() {
    const {
      modal: { selectedData },
      user: { user, roles },
      vendor: { allVendors },
      client: { clients },
    } = this.props;
    const { data, emailError } = this.state;

    const roleId =
      (selectedData.role && selectedData.role.id) || this.state.data.role_id;
    const clientId =
      (selectedData.client && selectedData.client.id) ||
      this.state.data.client_id;
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="firstname">First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First name"
                  pattern="[a-zA-Z ]*$"
                  value={data.first_name}
                  onChange={(e) =>
                    this.updateUser("first_name", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="lastname">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  pattern="[a-zA-Z ]*$"
                  placeholder="Last name"
                  value={data.last_name}
                  onChange={(e) => this.updateUser("last_name", e.target.value)}
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <div className="input-group-prepend" />
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    value={data.email}
                    aria-describedby="inputGroupPrepend"
                    onChange={(e) => this.updateUser("email", e.target.value)}
                    required
                  />
                </div>
                {emailError && <div className="error-color">{emailError}</div>}
              </div>
            </div>
            <div className="form-row">
              {user && user.isClient() && (
                <div className="col-md-6 mb-3">
                  <label htmlFor="vendor">Vendors</label>
                  <Typeahead
                    id="vendor"
                    labelKey="name"
                    multiple={false}
                    options={allVendors}
                    placeholder="Vendor"
                    onChange={(e) => {
                      if (e.length > 0) this.updateUser("vendor_id", e[0].id);
                    }}
                    ref="typeahead"
                  />
                </div>
              )}

              <div className="col-md-3 mb-3">
                <label htmlFor="role">Role</label>
                <select
                  className="form-control"
                  value={roleId}
                  required
                  onChange={(e) => {
                    this.updateUser("role_id", e.target.value);
                    if (e.target.value === "3") {
                      let st = this.state;
                      delete st.data.client_id;
                      this.setState(st);
                    }
                  }}
                >
                  <option value="">select a role</option>
                  {roles.map((r) =>
                    user && !user.isSuper() && r.name !== "super" ? (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ) : user &&
                      user.isSuper() &&
                      r.client ===
                        (this.state.data.client_id === ""
                          ? null
                          : this.state.data.client_id) ? (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ) : (
                      ""
                    ),
                  )}
                </select>
              </div>
              {user && user.isSuper() && this.state.data.role_id !== "3" && (
                <div className="col-md-3 mb-3">
                  <label htmlFor="vendor">Client</label>
                  <select
                    className="form-control"
                    value={clientId}
                    onChange={(e) =>
                      this.updateUser(
                        "client_id",
                        e.target.value === "select a client"
                          ? null
                          : e.target.value,
                      )
                    }
                  >
                    <option value={null} selected>
                      select a client
                    </option>
                    {clients.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.clearFields()}
              data-dismiss="modal"
              ref={(button) => (this.closeButton = button)}
            >
              Close
            </button>
            <button id="submit-btn" type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user, vendor, client }) => ({ modal, user, vendor, client }),
  (dispatch) => bindActionCreators({ createUser }, dispatch),
)(UserModalForm);
