import React from "react";
import OpenModalIcon from "./components/OpenModalIcon";

const cellRender = ({ data, context }) => {
  return (
    <OpenModalIcon
      click={() =>
        context.componentParent.updateData(data, "shipment", "Warehouse")
      }
      icon={"icon-office"}
    />
  );
};

export const ShipmentWarehouse = {
  headerName: "Warehouse",
  filter: null,
  cellStyle: { display: "flex", "justify-content": "center" },
  field: "warehouse",
  width: 100,
  sortable: false,
  cellRendererFramework: cellRender,
  suppressSizeToFit: true,
};
