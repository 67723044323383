export default class User {
  vendor = {};
  routes = {
    client: [
      "home",
      "pos",
      "asn",
      "shipment",
      "vendor",
      "carrier",
      "user",
      "report",
      // "email",
      "profile",
    ],
    vendorStaff: [
      "home",
      "pos",
      "asn",
      "shipment",
      "warehouse",
      "carton",
      "profile",
    ],
    vendorAdmin: [
      "home",
      "pos",
      "asn",
      "shipment",
      "warehouse",
      "carrier",
      // "carton",
      "user",
      "report",
      //"settings",
      "profile",
    ],
    super: ["home", "user", "client", "vendor", "logs", "tools", "profile"],
  };

  constructor(userObj) {
    Object.assign(this, userObj);
  }

  updateClientRoutes(routes) {
    this.routes.client = routes;
  }

  isSuper() {
    return this.role.id === 3;
  }

  isClient() {
    return this.vendor === null && !this.isSuper();
  }

  isVendor() {
    return this.vendor !== null && this.client;
  }

  isAdmin() {
    return this.role.name === "admin";
  }

  getRoutes() {
    if (this.isSuper()) {
      return this.routes.super;
    } else if (this.isClient()) {
      return this.routes.client;
    } else if (this.isVendor()) {
      return this.isAdmin() ? this.routes.vendorAdmin : this.routes.vendorStaff;
    }
  }
}
