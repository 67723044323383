import React from "react";
import { Link } from "react-router-dom";

const BreadcrumbLink = ({ pathname, breadcrumb, url, field }) => (
  <div
    style={{
      alignItems: "center",
      justifyConten: "center",
      display: "flex",
      height: "100%",
    }}
  >
    <Link
      to={{
        pathname: pathname,
        state: { breadcrumb: breadcrumb, url: url },
      }}
    >
      {field ? (
        <span style={{ color: "black", textDecoration: "none" }}>{field}</span>
      ) : (
        <i id="add-partial-icon" className="fa fa-list-ul" />
      )}
    </Link>
  </div>
);

export default BreadcrumbLink;
