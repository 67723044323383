import {
  CreatedAtColumnDefinition,
  NameColumnDefVendor,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { VendorDetails } from "components/AGTable/NewColumns/VendorDetails";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const vendorsGrid = {
  columnDefs: [
    VendorDetails,
    {
      headerName: "Vendor #",
      width: 135,
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "vendor_number",
    },
    NameColumnDefVendor,
    CreatedAtColumnDefinition,
  ],
  defaultColDef,
};
