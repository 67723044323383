import React from "react";
import Layout from "../Layout";

const LinkExpired = () => (
  <Layout>
    <p>Link is expired.</p>
  </Layout>
);

export default LinkExpired;
