import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateVendor } from "redux/actions/vendor.action";

class VendorUpdateForm extends React.Component {
  state = {
    data: {},
  };

  updateVendor(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  render() {
    const {
      modal: { selectedData },
    } = this.props;

    // eslint-disable-next-line
    this.state.data.id = selectedData.id;

    return (
      <>
        <form>
          <div className="modal-body">
            <div className="form-row">
              <div className=" mb-6">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  defaultValue={selectedData.name}
                  onKeyUp={(e) => this.updateVendor("name", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className=" mb-6">
                <label htmlFor="contact-name">Contact Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact-name"
                  placeholder="Contact Name"
                  defaultValue={selectedData.contact_name}
                  onKeyUp={(e) =>
                    this.updateVendor("contact_name", e.target.value)
                  }
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className=" mb-6">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  placeholder="Phone Number"
                  defaultValue={selectedData.phone}
                  onKeyUp={(e) => this.updateVendor("phone", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className=" mb-6">
                <label htmlFor="fax">Fax Number</label>
                <input
                  type="number"
                  className="form-control"
                  id="fax"
                  placeholder="Fax Number"
                  defaultValue={selectedData.fax}
                  onKeyUp={(e) => this.updateVendor("fax", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className=" mb-6">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <div className="input-group-prepend" />
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    defaultValue={selectedData.email}
                    aria-describedby="inputGroupPrepend"
                    onKeyUp={(e) => this.updateVendor("email", e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                this.props.updateVendor(this.state.data);
              }}
              data-dismiss="modal"
            >
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user, vendor }) => ({ modal, user, vendor }),
  (dispatch) => bindActionCreators({ updateVendor }, dispatch),
)(VendorUpdateForm);
