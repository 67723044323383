import React from "react";
import ActionIcon from "./components/ActionIcon";

const removeIcon = ({ data, context }) => {
  return (
    <ActionIcon
      click={(e) => {
        e.preventDefault();
        context.componentParent.removeWarehouseFromCarrier(data);
      }}
      icon={"icon-subtract"}
    />
  );
};
export const RemoveWarehouseFromCarrier = {
  headerName: "Remove from Carrier",
  cellRendererFramework: removeIcon,
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 169,
};
