import React, { useRef } from "react";
import "./serializeWithStyles";
import { Button } from "components";

const PrintMe = ({ children }) => {
  const parent = useRef(null);
  const print = () => {
    const html = `<body>
      <style>
      @media print {
        @page { margin: 0; }
      }
      </style>
      ${parent.current.serializeWithStyles()}
      </body>
      <script>setTimeout(window.print, 0)</script>`;
    window.open(URL.createObjectURL(new Blob([html], { type: "text/html" })));
  };
  return (
    <>
      <Button
        onClick={print}
        style={{ marginBottom: "1rem", width: "max-content" }}
      >
        Print
      </Button>
      <div style={{ width: "max-content" }} ref={parent}>
        {children}
      </div>
    </>
  );
};

export default PrintMe;
