import React from "react";
import moment from "moment";

const cellRender = ({ data, context }) => {
  const resendEmail = () => {
    const { email } = data;
    context.componentParent.resendEmail({
      email,
      initial_login: true,
    });
  };

  const { last_login: lastLogin } = data;
  if (lastLogin) {
    const formattedDate = moment(lastLogin).format("YYYY-MM-DD");
    return <p>{formattedDate}</p>;
  }
  return (
    <button
      className="btn btn-primary btn-sm"
      onClick={resendEmail}
      style={{
        lineHeight: "0.9",
        padding: "0.3rem",
      }}
    >
      Resend Email
    </button>
  );
};

export const ResendEmailButton = {
  headerName: "Last Login",
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 125,
  sortable: false,
  cellRendererFramework: cellRender,
  filter: "RadioSetFilter",
};
