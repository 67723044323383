import Service from "./service";
import { resetAlerts } from "./global.action";

export const getClients = (queryParam) => (dispatch) => {
  Service.get(dispatch, "client/clients/", queryParam || {}, {
    init: "GET_CLIENTS",
    success: "GET_CLIENTS_SUCCESS",
    error: "GET_CLIENTS_FAILED",
  });
};

export const createClient = (client) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "client/clients/", client, {
    init: "ADD_CLIENT",
    success: "ADD_CLIENT_SUCCESS",
    error: "ADD_CLIENT_FAILED",
  });
};

export const getEmails = () => (dispatch) => {
  Service.get(dispatch, "client/shipmentEmails/", {
    init: "GET_EMAILS",
    success: "GET_EMAILS_SUCCESS",
    error: "GET_EMAILS_FAILED",
  });
};

export const createShipmentEmail = (data) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "client/shipmentEmails/", data, {
    init: "ADD_EMAIL",
    success: "ADD_EMAIL_SUCCESS",
    error: "ADD_EMAIL_FAILED",
  });
};

export const deleteShipmentEmail = (id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.delete(dispatch, `client/shipmentEmails/${id}`, {
    init: "DELETE_EMAIL",
    success: "DELETE_EMAIL_SUCCESS",
    error: "DELETE_EMAIL_FAILED",
  });
};

export const updateShipmentEmail = (email) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.patch(dispatch, `client/shipmentEmails/${email.id}`, email, {
    init: "UPDATE_EMAIL",
    success: "UPDATE_EMAIL_SUCCESS",
    error: "UPDATE_EMAIL_FAILED",
  });
};
