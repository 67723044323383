import React, { useEffect } from "react";
import { UncontrolledAlert } from "reactstrap";

import { pos as c } from "config/constants";
import config from "config/config";

const PoAlerts = ({ currentPo, unlockPo, user }) => {
  const { first_name, last_name } = currentPo.locked_by || {};
  const lockedByName = first_name + " " + last_name;
  const currentUserName = user.first_name + " " + user.last_name;

  useEffect(() => {
    const unloadHandler = (ev) => {
      ev.preventDefault();
      unlockPo(currentPo.id);
    };

    currentPo.locked && window.addEventListener("beforeunload", unloadHandler);
    return () => {
      currentPo.locked &&
        window.removeEventListener("beforeunload", unloadHandler);
    };
  });

  return (
    <>
      {currentPo && !currentPo.active && (
        <UncontrolledAlert color="danger">
          <i className="icon-warning" /> {c.poInactive}
        </UncontrolledAlert>
      )}
      {currentPo &&
        lockedByName !== currentUserName &&
        currentPo.locked &&
        user.isVendor() && (
          <UncontrolledAlert color="danger">
            {/* <i className="icon-lock" /> {c.poLocked} */}
            <i className="icon-lock" /> {c.poLocked + lockedByName}
          </UncontrolledAlert>
        )}
      {currentPo &&
        config.getFlaggedFeatureFromLocalStorage() &&
        currentPo.flagged &&
        currentPo.active && (
          <UncontrolledAlert color="warning">
            <i className="icon-pencil5" /> {c.poFlagged}
          </UncontrolledAlert>
        )}
    </>
  );
};

export default PoAlerts;
