/* eslint-disable default-case */
function base64UrlDecode(str) {
  try {
    let base64 = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (base64.length % 4) {
      case 2:
        base64 += "==";
        break;
      case 3:
        base64 += "=";
        break;
    }
    return atob(base64);
  } catch (error) {
    throw new Error("Invalid base64 string");
  }
}

export const parseJwtPayload = (token) => {
  try {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT token");
    }
    const payload = parts[1];
    const decodedPayload = base64UrlDecode(payload);
    return JSON.parse(decodedPayload);
  } catch (error) {
    throw new Error("Failed to parse JWT payload");
  }
};

export const getJwtExpiration = (token) => {
  try {
    const payload = parseJwtPayload(token);
    if (!payload.exp) {
      throw new Error("Expiration (exp) claim not found in token");
    }
    return payload?.exp;
  } catch (error) {
    console.error("Error getting JWT expiration:", error.message);
    return null;
  }
};
