import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ScrollManager, VendorSetup } from "components";
import { resetAlerts } from "redux/actions/global.action";
import styles from "./Content.module.css";
import { getAllCartons } from "redux/actions/carton.action";

const Content = ({ children, resetAlerts, getAllCartons, carton, user }) => {
  useEffect(() => {
    resetAlerts();
  }, [resetAlerts]);

  useEffect(() => {
    if (!carton.allCartonsUpToDate && user?.user?.isVendor()) {
      getAllCartons();
    }
  }, [carton.allCartonsUpToDate, getAllCartons, user.user]);

  return (
    <ScrollManager>
      {({ connectScrollTarget }) => (
        <div className={styles.content} ref={connectScrollTarget}>
          <VendorSetup>{children}</VendorSetup>
        </div>
      )}
    </ScrollManager>
  );
};

export default connect(({ carton, user }) => ({ carton, user }), {
  resetAlerts,
  getAllCartons,
})(Content);
