import React from "react";
import { updateSidebarClass } from "redux/actions/layout.action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Links from "./Links";
import { NavLink } from "react-router-dom";

class Sidebar extends React.Component {
  getLinks(routes) {
    const links = { Links };
    return routes.map(function (link, index) {
      return (
        <li className="nav-item" key={index}>
          <NavLink
            to={`/${link}`}
            activeClassName="active"
            className={"nav-link"}
          >
            {links.Links[link]}
          </NavLink>
        </li>
      );
    });
  }

  userRoutes(user) {
    let routes = user.getRoutes();
    const index = routes.indexOf("profile");
    if (index !== -1) routes = routes.slice(0, index);
    return this.getLinks(routes);
  }

  render() {
    const {
      user: { user },
    } = this.props;
    const { layout } = this.props;
    return (
      <div
        className={`${layout.sidebarClass ? "coustom-w-50" : "coustom-w-150"} transition  sidebar sidebar-dark sidebar-main sidebar-fixed sidebar-expand-md `}
      >
        <div className="sidebar-mobile-toggler text-center">
          <a
            onClick={() => this.props.updateSidebarClass()}
            href="# "
            className="sidebar-mobile-main-toggle"
          >
            <i className="icon-arrow-left8" />
          </a>
        </div>

        <div className="sidebar-content">
          <div className="card card-sidebar-mobile">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              {this.userRoutes(user)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  ({ user, layout }) => ({ user, layout }),
  (dispatch) => bindActionCreators({ updateSidebarClass }, dispatch),
)(Sidebar);
