import React, { Component } from "react";
import { Button as BootstrapButton } from "reactstrap";

/**
 * <ButtonLoader
 *    color={string} // Type of button (danger, warning, primary) or (#222, blue)
 *    onClick={function}
 *    disabled={boolean}
 *    btnSize={string}
 *    toggleLoader={boolean} // To show/hide loader
 *  >{Text}</ButtonLoader>
 */
export default class Button extends Component {
  state = {
    loading: false,
  };
  render() {
    const {
      children,
      show,
      className,
      color,
      onClick = () => {},
      disabled,
      btnSize,
      toggleLoader,
      useLoader = false,
      size = "sm",
      style,
      type,
    } = this.props;
    if (typeof show === "undefined" || show)
      return (
        <BootstrapButton
          color={color}
          onClick={(e) => {
            if (useLoader) {
              this.setState({ loading: true });
            }
            onClick(e);
          }}
          disabled={disabled}
          className={className}
          size={size}
          style={style}
          type={type || "button"}
        >
          <div style={{ width: btnSize }}>
            {toggleLoader || (useLoader && this.state.loading) ? (
              <>
                <i className="icon-spinner3 loading-spinner spin" />
                <span> Processing</span>
              </>
            ) : (
              children
            )}
          </div>
        </BootstrapButton>
      );
    return "";
  }
}
