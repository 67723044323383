import React from "react";
import { Button } from "components";
import { connect } from "react-redux";

const SelectCarrier = ({ data, context, carrier }) => {
  const selectCarrier = context.componentParent.props.selectCarrier;
  const id = data.id;

  if (carrier.setDefaultCarrier.id) {
    selectCarrier(carrier.setDefaultCarrier.id);
  }
  const handleClick = () => {
    selectCarrier(id);
  };

  const label = (
    <p
      style={{
        height: "1rem",
        margin: 0,
      }}
    >
      Select Carrier
    </p>
  );
  return (
    <Button color="primary" size="sm" useLoader onClick={handleClick}>
      <span
        style={{
          lineHeight: "16px",
        }}
      >
        {label}
      </span>
    </Button>
  );
};

export default connect(
  ({ shipment, carrier }) => ({ shipment, carrier }),
  {},
)(SelectCarrier);
