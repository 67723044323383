import React from "react";

class BasicMenu extends React.Component {
  render() {
    return (
      <div className="header-elements d-none">
        <div className="d-flex justify-content-center"></div>
      </div>
    );
  }
}
export default BasicMenu;
