/* eslint-disable eqeqeq */

import React from "react";
import { connect } from "react-redux";
import { Card, Button } from "components";
import { UncontrolledAlert } from "reactstrap";
import { Redirect } from "react-router-dom";
import { unselectCarrier } from "redux/actions/carrier.action";

const SelectedCarrierCard = ({ unselectCarrier, carrier, setChildValue }) => {
  const selectedCarrier =
    carrier.carriers &&
    carrier.carriers.find((c) => c.id === carrier.selectedCarrierId);
  setChildValue(selectedCarrier);
  if (selectedCarrier == undefined) return <Redirect to="asn" />;

  const { name, address, city, state, zipcode, email, pick_up_window, phone } =
    selectedCarrier;
  const formattedAddress = `${address}, ${city}, ${state} ${zipcode}`;

  return (
    <Card title={name} collapsible={false}>
      <p>
        Pick up window: <b>{pick_up_window}</b>
      </p>
      <p>
        Address: <b>{formattedAddress}</b>
      </p>
      <p>
        Phone: <b>{phone}</b>
      </p>
      <p>
        Email: <b>{email}</b>
      </p>
      <Button color="primary" btnSize="100px" onClick={unselectCarrier}>
        Change Carrier
      </Button>
      {selectedCarrier.default === false && (
        <UncontrolledAlert color="danger" style={{ marginTop: "20px" }}>
          You have selected a local carrier. You will be responsible for all
          shipping costs.
        </UncontrolledAlert>
      )}
    </Card>
  );
};

export default connect(({ carrier, shipment }) => ({ carrier, shipment }), {
  unselectCarrier,
})(SelectedCarrierCard);
