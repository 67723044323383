import {
  NameColumnDef,
  EmailColumnDef,
  CityColumnDef,
  CountryColumnDef,
  StateColumnDef,
  ZipcodeColumnDef,
  PhoneColumnDef,
  FaxColumnDef,
  ContactColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

import { RemoveWarehouseFromCarrier } from "components/AGTable/NewColumns/RemoveWarehouseFromCarrier";
const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const warehousesAttachedToCarrierGrid = {
  columnDefs: [
    RemoveWarehouseFromCarrier,
    NameColumnDef,
    CityColumnDef,
    StateColumnDef,
    CountryColumnDef,
    ZipcodeColumnDef("zip"),
    {
      headerName: "Distance (mi)",
      width: 130,
      field: "distance",
    },
    PhoneColumnDef,
    EmailColumnDef,
    FaxColumnDef,
    ContactColumnDef,
  ],
  defaultColDef,
};
