import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { addEmail } from "redux/actions/email.action";
import ModalFooterAddClose from "../components/ModalFooterAddClose";

function mapStateToProps(state) {
  const { emailLists } = state.email;
  const {
    user: { client },
  } = state.user;
  return { emailLists, client };
}

class EmailCreateForm extends React.Component {
  state = this.initialState();

  initialState() {
    return {
      data: {
        group_id: 0,
        first_name: "",
        last_name: "",
        email: "",
      },
    };
  }

  getGroup() {
    return this.props.emailLists.map((e) => {
      return { id: e.id, name: `Division: ${e.name}` };
    });
  }

  updateUser(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  clearFields() {
    this.refs.typeahead.getInstance().clear();
    this.setState(this.initialState());
  }

  closeModal() {
    this.closeButton.click();
  }

  handleSubmit() {
    const newEmail = {
      name:
        this.state.data.first_name.split(" ")[0] +
        " " +
        this.state.data.last_name,
      email: this.state.data.email.toLowerCase(),
      client: this.props.client.id,
    };
    const groupDetails = this.props.emailLists.filter(
      (e) => e.id === this.state.data.group_id,
    );
    if (groupDetails[0]) groupDetails[0].emails.push(newEmail);
    const body = groupDetails[0];
    this.props.addEmail(body, this.state.data.group_id);
    this.clearFields();
    this.closeButton.click();
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstname">First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First name"
                  value={data.first_name}
                  onChange={(e) =>
                    this.updateUser("first_name", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastname">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Last name"
                  value={data.last_name}
                  onChange={(e) => this.updateUser("last_name", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <div className="input-group-prepend" />
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={data.email}
                    aria-describedby="inputGroupPrepend"
                    onChange={(e) => this.updateUser("email", e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="group">Group</label>
                <Typeahead
                  id="group"
                  labelKey="name"
                  multiple={false}
                  ref="typeahead"
                  options={this.getGroup()}
                  placeholder="Group"
                  onChange={(e) => {
                    if (e.length > 0) this.updateUser("group_id", e[0].id);
                  }}
                />
              </div>
            </div>
          </div>
          <ModalFooterAddClose
            inputRef={(button) => (this.closeButton = button)}
            onClick={() => this.clearFields()}
          />
        </form>
      </>
    );
  }
}
export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ addEmail }, dispatch),
)(EmailCreateForm);
