import React from "react";
import { connect } from "react-redux";
import { deleteCarrier } from "redux/actions/carrier.action";
import ModalFooterDeleteClose from "../components/ModalFooterDeleteClose";
import ModalBodyDelete from "../components/ModalBodyDelete";

const CarrierDeleteForm = ({ modal: { selectedData }, deleteCarrier }) => {
  return (
    <>
      <form>
        <ModalBodyDelete name={selectedData.name} />
        <ModalFooterDeleteClose click={() => deleteCarrier(selectedData.id)} />
      </form>
    </>
  );
};

export default connect(({ modal, carrier }) => ({ modal, carrier }), {
  deleteCarrier,
})(CarrierDeleteForm);
