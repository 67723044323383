const initialState = {
  scripts: [],
};

export default (state = initialState, action = {}) => {
  state.scriptIsRunning = false;
  state.success = false;
  state.failed = false;

  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "START_SCRIPTS":
      return {
        ...state,
        success: false,
        scriptIsRunning: true,
      };
    case "START_SCRIPTS_SUCCESS":
      return {
        ...state,
        scripts: action.data,
        success: true,
        scriptIsRunning: false,
      };
    case "START_SCRIPTS_FAILED":
      return {
        ...state,
        failed: true,
      };
    default:
      return state;
  }
};
