import {
  CreatedAtColumnDefinition,
  PoCancelDateColumnDefinition,
  FlaggedColumnDefinition,
  LockedColumnDefinition,
  ShipToStoreColumnDefinition,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { ManagePo } from "components/AGTable/NewColumns/ManagePo";
import { ManagePoViaPoNumber } from "components/AGTable/NewColumns/ManagePoViaPoNumber";
import config from "config/config";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },

  filter: true,
};

const columnDefs = [
  ManagePo,
  ManagePoViaPoNumber,
  PoCancelDateColumnDefinition,
  ShipToStoreColumnDefinition,
  {
    headerName: "# of ASNs",
    width: 115,
    field: "asn_count",
  },
  CreatedAtColumnDefinition,
  {
    headerName: "Order Date",
    field: "order_date",
    filter: "RadioSetFilter",
    width: 105,
  },

  LockedColumnDefinition,
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(7, 0, FlaggedColumnDefinition);

export const poGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
