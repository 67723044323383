export const asn = {};
export const carrier = {
  savedSuccessfully: "Carrier saved successfully",
  updatedSuccessfully: "Carrier updated successfully",
  deletedSuccessfully: "Carrier deleted successfully",
  saveChanges: "Save Changes",
  noWarehousesAssigned:
    "This Carrier isn't assigned to any Warehouses, please add Warehouses to this Carrier",
  backButton: "Back",
};
export const cartons = {
  savedSuccessfully: "Carton created successfully",
  updatedSuccessfully: "Carton updated successfully",
  deletedSuccessfully: "Carton deleted successfully",
};
export const home = {};
export const login = {
  forgotPassword: "Forgot Password?",
};
export const logs = {};
export const pos = {
  noCartonFoundError: `You cannot create an ASN before first creating a Carton. You will
  now be redirected to the Carton page so that you may create a
  Carton.`,
  savedSuccessfully: "ASN saved successfully",
  updatedSuccessfully: "ASN updated successfully",
  deletedSuccessfully: "ASN deleted successfully",
  poLocked: "PO Locked - this PO is currently open by the following user: ",
  poFlagged:
    "This PO has been changed, please Edit all flagged ASNs to confirm changes.",
  poInactive:
    "This PO was deactivated by the client. Any attached ASNs will be unable to be shipped unless the client reactivates this PO.",
};
export const profile = {};
export const recover = {};
export const reports = {
  savedSuccessfully: "Report generated successfully",
  error: "Error! Report not generated",
};
export const shipment = {
  updatedSuccessfully: "Shipment updated successfully",
  deletedSuccessfully: "Shipment deleted successfully",
};
export const users = {
  savedSuccessfully: "User saved successfully",
  updatedSuccessfully: "User updated successfully",
  deletedSuccessfully: "User deleted successfully",
  emailAlert: "email sent successfully",
  profileUpdated: "Profile updated successfully",
};
export const shipmentEmail = {
  emailListTitle: "Shipment Email List",
  savedSuccessfully: "Email added to list successfully.",
  updatedSuccessfully: "Email Updated successfully",
  deletedSuccessfully: "Email deleted from list successfully.",
};
export const client = {
  savedSuccessfully: "Client created successfully.",
  updatedSuccessfully: "Client Updated successfully",
  deletedSuccessfully: "Client deleted successfully.",
};
export const vendor = {
  vendorHasNoUsers: "has no users...",
  vendorHasNoCarriers: "has no carriers...",
  vendorHasNoWarehouses: "has no warehouses...",
  savedSuccessfully: "Vendor saved successfully",
};
export const warehouse = {
  savedSuccessfully: "Warehouse saved successfully",
  updatedSuccessfully: "Warehouse updated successfully",
  deletedSuccessfully: "Warehouse deleted successfully",
  noWarehouseFoundError:
    "You have no Warehouse on file to ship from. You will now be redirected to the Warehouse Management page so that you may create a Warehouse to ship from.",
};
//COMMENT CODE 3112020-SETTINGS
// export const settings = {
//   savedSuccessfully: "Settings saved succesfully",
//   updatedSuccessfully: "Settings updated successfully",
//   deletedSuccessfully: "Settings deleted successfully",
//   error: "Error! Settings not saved successfully"
// };

export const email = {
  savedSuccessfully: "Email changes saved succesfully",
  updatedSuccessfully: "Email updated successfully",
  deletedSuccessfully: "Email deleted successfully",
  error: "Error! Email not saved successfully",
};
