import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCarrier,
  updateCarrier,
  clearCurrentCarrier,
} from "redux/actions/carrier.action";
import { carrierDetailGrid } from "./tables/carrierDetailGrid";
import { warehousesAttachedToCarrierGrid } from "./tables/warehousesAttachedToCarrierGrid";
import { warehouseGrid } from "./tables/warehouseGrid";
import {
  AGTable,
  ContentHeader,
  PageLoading,
  Card,
  Content,
  Button,
} from "components";
import { UncontrolledAlert } from "reactstrap";
import { carrier as constants } from "config/constants";
import { distance } from "utils/distance";

const Details = ({
  location,
  carrier,
  warehouse,
  getCarrier,
  updateCarrier,
  clearCurrentCarrier,
}) => {
  const { pathname, state } = location;
  const { currentCarrier, carrierWarehouses } = carrier;

  useEffect(() => {
    getCarrier(pathname.split("/")[2]);
  }, [getCarrier, pathname]);

  useEffect(() => {
    return () => {
      clearCurrentCarrier();
    };
  }, [clearCurrentCarrier]);

  const handleSubmit = () => {
    let updateWarehousesListIds = [];
    carrierWarehouses.forEach((element) => {
      updateWarehousesListIds.push(element.id);
    });
    currentCarrier.warehouses = updateWarehousesListIds;
    const { vendors, ...other } = currentCarrier;
    updateCarrier(other);
  };

  if (!currentCarrier.name) {
    return (
      <div className="content-wrapper">
        <ContentHeader info={state} pagename="Carrier" menuType="Basic" />
        <PageLoading />
      </div>
    );
  }
  return (
    <div className="content-wrapper">
      <ContentHeader info={state} pagename="Carrier" menuType="Basic" />
      <Content>
        {carrier.updated && (
          <UncontrolledAlert color="success">
            {constants.updatedSuccessfully}
          </UncontrolledAlert>
        )}
        <Card title={currentCarrier.name}>
          <AGTable
            isDoneLoading={true}
            tableName="details1"
            gridConfig={carrierDetailGrid}
            rowData={[currentCarrier]}
          />
        </Card>
        {carrierWarehouses && carrierWarehouses.length > 0 && (
          <Card title="Carrier Warehouses">
            <AGTable
              isDoneLoading={true}
              tableName="details2"
              noStyle={true}
              gridConfig={warehousesAttachedToCarrierGrid}
              rowData={carrierWarehouses.map((warehouse) => {
                warehouse["distance"] = Math.round(
                  distance(currentCarrier.zipcode, warehouse.zip),
                );
                return warehouse;
              })}
            />
          </Card>
        )}
        <Card title="Vendor List">
          <AGTable
            isDoneLoading={true}
            tableName="details3"
            gridConfig={warehouseGrid}
            rowData={warehouse.warehouses.map((warehouse) => {
              warehouse["distance"] = Math.round(
                distance(currentCarrier.zipcode, warehouse.zip),
              );
              return warehouse;
            })}
          />
        </Card>
        <div className="btn-group">
          <Button color="primary" type="button" onClick={() => handleSubmit()}>
            {constants.saveChanges}
          </Button>
          <Link to={"/carrier/"}>
            <Button color="secondary" type="button">
              {constants.backButton}
            </Button>
          </Link>
        </div>
      </Content>
    </div>
  );
};

export default connect(({ carrier, warehouse }) => ({ carrier, warehouse }), {
  getCarrier,
  updateCarrier,
  clearCurrentCarrier,
})(Details);
