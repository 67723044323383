import React from "react";
import "./ContentHeader.css";
import { connect } from "react-redux";
import { ContentHeaderMenu as Menu } from "components";
import { Link } from "react-router-dom";
import IdleTimer from "components/IdleTimer";

class ContentHeader extends React.Component {
  breadcrumbName(pagename) {
    if (pagename === "Home") {
      return "";
    } else {
      return <span className="breadcrumb-item active">{pagename}</span>;
    }
  }

  breadcrumbTrail(pagename, breadcrumb, url) {
    return (
      <>
        <Link to={"/" + url} className="breadcrumb-item">
          {breadcrumb}
        </Link>
        <span className="breadcrumb-item active">{pagename}</span>
      </>
    );
  }

  render() {
    return (
      <div className="page-header page-header-light">
        <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
          <div className="d-flex">
            <div className="breadcrumb">
              <Link to="/home" className="breadcrumb-item">
                <i className="icon-home2 mr-2" /> Home
              </Link>
              {this.props && this.props.info
                ? this.breadcrumbTrail(
                    this.props.pagename,
                    this.props.info.breadcrumb,
                    this.props.info.url,
                  )
                : this.breadcrumbName(this.props.pagename)}
            </div>
          </div>
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex p-0">
              <IdleTimer />
            </div>
            <Menu menuType={this.props.menuType} action={this.props.action} />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(({ user }) => ({ user }))(ContentHeader);
