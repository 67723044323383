import Service from "./service";

export const generateAsnReport = (data) => (dispatch) => {
  Service.get(
    dispatch,
    "reports/asn-report",
    { asn_number: data.asn_number },
    {
      init: "GENERATE_ASN_REPORT",
      success: "GENERATE_ASN_REPORT_SUCCESS",
      error: "GENERATE_ASN_REPORT_FAILED",
    },
  );
};

export const generateDataExtractReport = (data) => (dispatch) => {
  Service.get(
    dispatch,
    "reports/dataextract-report",
    {
      from_date: data.from_date,
      to_date: data.to_date,
    },
    {
      init: "GENERATE_DATAEXTRACT_REPORT",
      success: "GENERATE_DATAEXTRACT_SUCCESS",
      error: "GENERATE_DATAEXTRACT_FAILED",
    },
  );
};

export const generateInTransitReport = () => (dispatch) => {
  Service.get(dispatch, "reports/in-transit-report", {
    init: "GENERATE_INTRANSIT_REPORT",
    success: "GENERATE_INTRANSIT_SUCCESS",
    error: "GENERATE_INTRANSIT_FAILED",
  });
};

export const resendAsnFeed = (data) => (dispatch) => {
  Service.get(
    dispatch,
    "reports/asn-feed",
    { asn_number: data.asn_number },
    {
      init: "RESEND_ASN_FEED",
      success: "RESEND_ASN_FEED_SUCCESS",
      error: "RESEND_ASN_FEED_FAILED",
    },
  );
};

export const generateReport = () => (dispatch) => {
  dispatch({ type: "GENERATE_REPORT" });
};
