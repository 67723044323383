import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { addEmail } from "redux/actions/email.action";
import ModalFooterAddClose from "../components/ModalFooterAddClose";

function mapStateToProps(state) {
  const { emailLists, selectedDivision } = state.email;
  const {
    user: { client },
  } = state.user;
  const { selectedData } = state.modal;
  return { selectedData, selectedDivision, emailLists, client };
}

class EmailUpdateForm extends React.Component {
  state = this.initialState();

  initialState() {
    return {
      data: {
        group_id: 0,
        first_name: this.props.selectedData.name
          ? this.props.selectedData.name.split(" ")[0]
          : "",
        last_name: this.props.selectedData.name
          ? this.props.selectedData.name.split(" ")[1]
          : "",
        email: this.props.selectedData.email
          ? this.props.selectedData.email
          : "",
      },
      picked_record: {
        email: this.props.selectedData.email
          ? this.props.selectedData.email
          : "",
      },
    };
  }

  updateEmail(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  clearFields() {
    this.setState(this.initialState());
  }

  closeModal() {
    this.closeButton.click();
  }

  handleSubmit() {
    const updatedEmail = {
      name:
        this.state.data.first_name.split(" ")[0] +
        " " +
        this.state.data.last_name,
      email: this.state.data.email.toLowerCase(),
      client: this.props.client.id,
    };
    const groupDetails = this.props.emailLists.filter(
      (e) => e.name === this.props.selectedDivision,
    )[0];

    const body = Object.assign(groupDetails);
    body.emails = groupDetails.emails.map((e) => {
      if (e.email === this.state.picked_record.email) {
        return Object.assign(e, updatedEmail);
      }
      return e;
    });
    this.props.addEmail(body, groupDetails.id);
    this.clearFields();
    this.closeButton.click();
  }

  render() {
    const { data } = this.state;

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="firstname">First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First name"
                  value={data.first_name}
                  onChange={(e) =>
                    this.updateEmail("first_name", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="lastname">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Last name"
                  value={data.last_name}
                  onChange={(e) =>
                    this.updateEmail("last_name", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <div className="input-group-prepend" />
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={data.email}
                    aria-describedby="inputGroupPrepend"
                    onChange={(e) => this.updateEmail("email", e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <ModalFooterAddClose
            inputRef={(button) => (this.closeButton = button)}
            click={() => this.clearFields()}
          />
        </form>
      </>
    );
  }
}
export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ addEmail }, dispatch),
)(EmailUpdateForm);
