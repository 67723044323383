import React from "react";

const ModalFooterDeleteClose = ({ click }) => (
  <div className="modal-footer">
    <button type="button" className="btn btn-secondary" data-dismiss="modal">
      Close
    </button>
    <button
      type="submit"
      className="btn btn-danger"
      onClick={click}
      data-dismiss="modal"
    >
      Delete
    </button>
  </div>
);

export default ModalFooterDeleteClose;
