import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateCarton } from "redux/actions/carton.action";
import styles from "./CartonForm.module.css";
import ModalFooterUpdateClose from "../components/ModalFooterUpdateClose";

class CartonUpdateForm extends React.Component {
  state = {
    data: {},
  };
  updateCarton(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }
  closeModal() {
    this.closeButton.click();
  }
  render() {
    const {
      modal: { selectedData },
    } = this.props;

    // eslint-disable-next-line
    this.state.data.id = selectedData.id;

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.updateCarton(this.state.data);
            this.closeModal();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-5">
                <input
                  type="text"
                  required
                  className="form-control"
                  id="inputName"
                  placeholder="Name"
                  defaultValue={selectedData.name}
                  onChange={(e) => this.updateCarton("name", e.target.value)}
                  maxLength="15"
                />
              </div>
            </div>
            <div className="form-row">
              <div className={`form-group col ${styles.horizontal}`}>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  required
                  className={`${styles["number-input"]} form-control`}
                  id="inputHeight"
                  placeholder="Height"
                  defaultValue={selectedData.height}
                  onChange={(e) => this.updateCarton("height", e.target.value)}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                />
                <label htmlFor="inputHeight" className={styles.label}>
                  H
                </label>
                <span className={styles.multiplication}>x</span>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  required
                  className={`${styles["number-input"]} form-control`}
                  id="inputLength"
                  placeholder="Length"
                  defaultValue={selectedData.length}
                  onChange={(e) => this.updateCarton("length", e.target.value)}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                />
                <label htmlFor="inputLength" className={styles.label}>
                  L
                </label>
                <span className={styles.multiplication}>x</span>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  required
                  className={`${styles["number-input"]} form-control`}
                  id="inputWidth"
                  placeholder="Width"
                  defaultValue={selectedData.width}
                  onChange={(e) => this.updateCarton("width", e.target.value)}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                />
                <label htmlFor="inputWidth" className={styles.label}>
                  W
                </label>
                <select
                  required
                  className="form-control"
                  id="inputUnitOfMeasurement"
                  style={{
                    width: "auto",
                    marginLeft: "2rem",
                  }}
                  defaultValue={selectedData.width_height_length_unit}
                  onChange={(e) =>
                    this.updateCarton(
                      "width_height_length_unit",
                      e.target.value,
                    )
                  }
                >
                  <option>cm</option>
                  <option>in</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className={`form-group col ${styles.horizontal}`}>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  required
                  className={`${styles["number-input"]} form-control`}
                  id="inputWeight"
                  style={{
                    marginRight: "0.2rem",
                  }}
                  placeholder="Weight"
                  defaultValue={selectedData.max_weight}
                  onChange={(e) =>
                    this.updateCarton("max_weight", e.target.value)
                  }
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                />
                <select
                  required
                  className="form-control"
                  id="inputWeightUnit"
                  style={{
                    width: "auto",
                  }}
                  defaultValue={selectedData.weight_unit}
                  onChange={(e) =>
                    this.updateCarton("weight_unit", e.target.value)
                  }
                >
                  {/* <option>kg</option> */}
                  <option>lb</option>
                </select>
              </div>
            </div>
            <ModalFooterUpdateClose
              inputRef={(button) => (this.closeButton = button)}
            />
          </div>
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user, carton }) => ({ modal, user, carton }),
  (dispatch) => bindActionCreators({ updateCarton }, dispatch),
)(CartonUpdateForm);
