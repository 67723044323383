const initialState = {
  emailLists: [],
  emailListsUpToDate: false,
  errorMessage: "",
  loading: false,
  fetchingEmailLists: false,
  patchPending: false,
  added: false,
  updated: false,
  deleted: false,
  error: "",
  selectedDivision: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "GET_EMAIL_LIST":
      return {
        ...state,
        fetchingEmailLists: true,
      };
    case "GET_EMAIL_LISTS_SUCCESS":
      return {
        ...state,
        fetchingEmailLists: false,
        emailLists: action.data,
        emailListsUpToDate: true,
      };
    case "GET_EMAIL_LISTS_FAILED":
      return {
        ...state,
        fetchingUsers: false,
        emailListsUpToDate: false,
      };
    case "ADD_GROUP_EMAIL":
      return {
        ...state,
        patchPending: true,
        added: false,
      };
    case "ADD_GROUP_EMAIL_SUCCESS":
      return {
        ...state,
        emailLists: addNewEmail(action.data, state.emailLists),
        patchPending: false,
        added: true,
        emailListsUpToDate: false,
      };
    case "ADD_GROUP_EMAIL_FAILED":
      return {
        ...state,
        error: action.error,
        patchPending: false,
        added: false,
      };
    case "DELETE_GROUP_EMAIL":
      return {
        ...state,
        deleted: false,
      };
    case "DELETE_GROUP_EMAIL_SUCCESS":
      return {
        ...state,
        users: deleteEmail(action.data, state.emailLists),
        deleted: true,
        emailListsUpToDate: false,
      };
    case "DELETE_GROUP_EMAIL_FAILED":
      return {
        ...state,
        error: action.error,
        deleted: false,
      };
    case "UPDATE_GROUP_EMAIL":
      return {
        ...state,
        updated: false,
      };
    case "UPDATE_GROUP_EMAIL_SUCCESS":
      return {
        ...state,
        emailLists: updateEmail(action.data, state.emailLists),
        updated: true,
        emailListsUpToDate: false,
      };
    case "UPDATE_GROUP_EMAIL_FAILED":
      return {
        ...state,
        error: action.error,
        updated: false,
      };
    case "SET_SELECTED_DIVISION":
      return {
        ...state,
        selectedDivision: action.payload,
      };
    default:
      return state;
  }
};

const deleteEmail = (id, emailList) => emailList.filter((u) => u.id !== id);
const updateEmail = (email, emailLists) =>
  emailLists.map((u) => (u = u.id === email.id ? email : u));
const addNewEmail = (newEmailGroup, emailList) => {
  return emailList.map((e) => {
    if (e.id === newEmailGroup.id) e.emails = newEmailGroup.emails;
    return e;
  });
};
