import {
  CreatedAtColumnDefinition,
  ActiveColumnDef,
  EditDeleteColumnDef,
  EmailColumnDef,
  VendorColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { ResendEmailButton } from "components/AGTable/NewColumns/ResendEmailButton";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};
export const userGrid = {
  columnDefs: [
    EditDeleteColumnDef,
    EmailColumnDef,
    {
      headerName: "First Name",
      width: 125,
      field: "first_name",
    },
    {
      headerName: "Last Name",
      width: 125,
      field: "last_name",
    },
    ActiveColumnDef,
    VendorColumnDef("vendor"),
    {
      headerName: "Role",
      width: 95,
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "role",
    },
    CreatedAtColumnDefinition,
    ResendEmailButton,
  ],
  defaultColDef,
};
