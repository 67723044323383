import React, { useEffect } from "react";
import { LockedUsersGrid } from "../tables/LockedUserGrid";
import { getLockedUsers } from "redux/actions/user.action";
import { Card, AGTable } from "components";
import { connect } from "react-redux";

const LockedUsers = ({ user, getLockedUsers }) => {
  useEffect(() => {
    if (!user.lockedUsersUpToDate) getLockedUsers();
  }, [getLockedUsers, user.lockedUsersUpToDate]);
  return (
    <>
      <Card title="Locked Users">
        <AGTable
          tableName="lockedUsers"
          gridConfig={LockedUsersGrid}
          rowData={user.lockedUsers}
          isDoneLoading={user.lockedUsersRetrieved}
          paginate={true}
        />
      </Card>
    </>
  );
};

export default connect(({ user }) => ({ user }), { getLockedUsers })(
  LockedUsers,
);
