/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { refreshToken } from "../redux/actions/user.action";

const TokenRefresher = ({ user, refreshToken }) => {
  const [timer, setTimer] = useState(null);
  const INTERVAL_THRESHOLD = 240000; // 4 * 60 * 1000 ms
  const MINUTES_THRESHOLD = 10;

  useEffect(() => {
    if (!isTokenExpired()) {
      createInterval();
    }
    return () => clearInterval(timer);
  }, []);

  const createInterval = () => {
    clearInterval(timer);
    validateToken();
    const newTimer = setInterval(validateToken, INTERVAL_THRESHOLD);
    setTimer(newTimer);
    console.log("Token validation interval was created");
  };

  const isTokenExpired = () => {
    if (!user?.isLoggedIn) {
      return true;
    }

    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (currentTimestamp > window.sessionStorage.getItem("expiration")) {
      console.log("Token is expired");
      return true;
    } else {
      console.log("Token is not expired");
      return false;
    }
  };

  const validateToken = async () => {
    try {
      const expirationTime = window.sessionStorage.getItem("expiration");
      // Get the current time
      const currentTime = new Date();

      // Convert expirationTime from Unix timestamp to JavaScript Date object
      const expirationDate = new Date(expirationTime * 1000);

      // Calculate the difference in minutes between the current time and expiration time
      const minutesLeft = Math.floor((expirationDate - currentTime) / 60000);
      if (minutesLeft <= MINUTES_THRESHOLD) {
        refreshToken(window.sessionStorage.getItem("refresh"));
      }

      console.log(`Token will expire in ${minutesLeft} minutes.`);
      // return minutesLeft;
    } catch (error) {
      console.error("Error on validateTokenInterval", error);
    }
  };
  return null;
};

export default connect(({ user }) => ({ user }), { refreshToken })(
  TokenRefresher,
);
