import React from "react";
import { AGTable } from "components";
import { connect } from "react-redux";
import { shipmentASNGrid } from "./tables/shipmentASNGrid";

const ShipmentASNsView = ({ modal: { selectedData } }) => {
  return (
    <>
      <div className="modal-body">
        <AGTable
          isDoneLoading={true}
          isSearchEnable={true}
          tableName="shipmentAsnsView"
          gridConfig={shipmentASNGrid}
          rowData={selectedData.asns}
        />
      </div>
    </>
  );
};
export default connect(({ modal }) => ({ modal }), {})(ShipmentASNsView);
