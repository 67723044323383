const initialState = {
  warehouses: [],
  warehousesUpToDate: false,
  error: "",
  added: false,
  updated: false,
  deleted: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "GET_WAREHOUSES":
      return {
        ...state,
        gettingWarehouses: true,
        warehousesRetrieved: false,
        warehousesUpToDate: true,
      };
    case "GET_WAREHOUSES_SUCCESS":
      return {
        ...state,
        warehouses: action.data.results,
        warehousesRetrieved: true,
        gettingWarehouses: false,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_WAREHOUSES_FAILED":
      return {
        ...state,
        error: action.error,
        gettingWarehouses: false,
        warehousesUpToDate: false,
      };
    case "GET_ALL_WAREHOUSES_SUCCESS":
      return {
        ...state,
        allWarehouses: action.data.results,
        allWarehousesUpToDate: true,
      };
    case "GET_ALL_WAREHOUSES_FAILED":
      return {
        ...state,
        error: action.error,
        allWarehousesUpToDate: false,
      };
    case "ADD_WAREHOUSE":
      return {
        ...state,
        addingWarehouse: true,
        added: false,
      };
    case "ADD_WAREHOUSE_SUCCESS":
      return {
        ...state,
        warehouses: state.warehouses.concat([action.data.data]),
        added: true,
        addingWarehouse: false,
        warehousesUpToDate: false,
        allWarehousesUpToDate: false,
      };
    case "ADD_WAREHOUSE_FAILED":
      return {
        ...state,
        error: action.error,
        addingWarehouse: false,
        added: false,
      };
    case "DELETE_WAREHOUSE":
      return {
        ...state,
        deletingWarehouse: true,
        deleted: false,
      };
    case "DELETE_WAREHOUSE_SUCCESS":
      return {
        ...state,
        deleted: true,
        warehouses: deleteFromList(action.data, state.warehouses),
        deletingWarehouse: false,
        warehousesUpToDate: false,
        allWarehousesUpToDate: false,
      };
    case "DELETE_WAREHOUSE_FAILED":
      return {
        ...state,
        error: action.error,
        deletingWarehouse: false,
        deleted: false,
      };
    case "UPDATE_WAREHOUSE":
      return {
        ...state,
        updatingWarehouse: true,
        updated: false,
      };
    case "UPDATE_WAREHOUSE_SUCCESS":
      return {
        ...state,
        warehouses: updateWarehouse(action.data, state.warehouses),
        updated: true,
        updatingWarehouse: false,
        warehousesUpToDate: false,
        allWarehousesUpToDate: false,
      };
    case "UPDATE_WAREHOUSE_FAILED":
      return {
        ...state,
        error: action.error,
        updatingWarehouse: false,
        updated: false,
      };
    default:
      return state;
  }
};
const deleteFromList = (id, list) => list.filter((c) => c.id !== id);

const updateWarehouse = (warehouse, warehouses) =>
  warehouses.map((c) => (c = c.id === warehouse.id ? warehouse : c));
