import React, { Component } from "react";
import "./loader.css";

export default class PageLoading extends Component {
  render() {
    return (
      <>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "100%",
            widgth: "100%",
          }}
        >
          <div className="loader">Loading...</div>
        </div>
      </>
    );
  }
}
