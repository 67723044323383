import Service from "./service";
import { resetAlerts } from "./global.action";

export const getWarehouses = (queryParam) => (dispatch) => {
  Service.get(dispatch, "vendor/warehouses", queryParam || {}, {
    init: "GET_WAREHOUSES",
    success: "GET_WAREHOUSES_SUCCESS",
    error: "GET_WAREHOUSES_FAILED",
  });
};

export const getAllWarehouses = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.page_size = 20000;
  Service.get(dispatch, "vendor/warehouses", qp, {
    init: "GET_ALL_WAREHOUSES",
    success: "GET_ALL_WAREHOUSES_SUCCESS",
    error: "GET_ALL_WAREHOUSES_FAILED",
  });
};

export const createWarehouse = (warehouse) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "vendor/warehouses/", warehouse, {
    init: "ADD_WAREHOUSE",
    success: "ADD_WAREHOUSE_SUCCESS",
    error: "ADD_WAREHOUSE_FAILED",
  });
};

export const deleteWarehouse = (id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.delete(dispatch, `vendor/warehouses/${id}`, {
    init: "DELETE_WAREHOUSE",
    success: "DELETE_WAREHOUSE_SUCCESS",
    error: "DELETE_WAREHOUSE_FAILED",
  });
};

export const updateWarehouse = (warehouse) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.patch(dispatch, `vendor/warehouses/${warehouse.id}`, warehouse, {
    init: "UPDATE_WAREHOUSE",
    success: "UPDATE_WAREHOUSE_SUCCESS",
    error: "UPDATE_WAREHOUSE_FAILED",
  });
};
