import React from "react";
import "./EditDeleteButtons.css";
import OpenModalIcon from "../NewColumns/components/OpenModalIcon";

// TODO:remove this function? If this is only used for ASNs we can remove it.
class EditDeleteAsnButtons extends React.Component {
  getPoForAsn() {
    // const fetchedPo = this.props.context.componentParent.props.po.pos.find(
    // 	po => po.po_number === data.po
    // );
    const currentPo = this.props.context.componentParent.props.po.currentPo;
    return currentPo;
  }
  render() {
    const { data } = this.props;
    const { ignore_shipment_window: isIgnored } = data;
    const po = this.getPoForAsn();
    return (
      <>
        {po &&
          po.active &&
          (isIgnored ? (
            ""
          ) : (
            <>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.props.context.componentParent.editAsn(data);
                }}
                href="# "
              >
                <i id="icon" className="icon-pencil" />
              </a>
              &nbsp;
            </>
          ))}
        <OpenModalIcon
          click={(e) => {
            e.preventDefault();
            this.props.context.componentParent.updateData(
              data,
              "delete",
              "ASN",
            );
          }}
          icon={"icon-trash"}
        />
      </>
    );
  }
}
export default EditDeleteAsnButtons;
