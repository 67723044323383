const initialState = {
  sidebarClass: "",
  cardClass: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "UPDATE_SIDEBAR_CLASS":
      return {
        ...state,
        sidebarClass: action.data,
      };

    default:
      return state;
  }
};
