import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ContentHeader, AGTable, AuthRole, Content, Card } from "components";
import { asnGrid } from "./tables/asnGrid";
import { asnClientGrid } from "./tables/asnClientGrid";
import VendorAsnShipment from "./component/VendorAsnShipment";
import { getASNs } from "redux/actions/asn.action";
import { resetUpToDateFlag } from "redux/actions/global.action";
import { carrier as c } from "config/constants";
import { ActionAlerts } from "components";

const ASN = ({ asn, getASNs, user, resetUpToDateFlag }) => {
  useEffect(() => {
    if (!asn.asnsUpToDate) {
      getASNs();
    }
  }, [asn.asnsUpToDate, getASNs]);

  useEffect(() => {
    return () => {
      resetUpToDateFlag();
    };
  }, [resetUpToDateFlag]);

  const isClient = user.user.isClient();
  return (
    <div className="content-wrapper">
      <ContentHeader pagename="ASNs" menuType="Basic" />
      <Content>
        <ActionAlerts item={asn} constants={c} />
        <AuthRole userRole="vendor">
          <VendorAsnShipment />
        </AuthRole>
        <AuthRole userRole="client">
          <Card title="ASNs">
            <AGTable
              tableName="asns"
              isDoneLoading={asn.asnsRetrieved}
              paginate={true}
              gridConfig={isClient ? asnClientGrid : asnGrid}
              action={getASNs}
              rowData={asn.asns}
              rowHeight={isClient && "35"}
              next={asn.next}
              previous={asn.previous}
              totalRows={asn.totalRows}
            />
          </Card>
        </AuthRole>
      </Content>
    </div>
  );
};

export default connect(({ asn, user }) => ({ asn, user }), {
  getASNs,
  resetUpToDateFlag,
})(ASN);
