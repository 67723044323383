import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Card, AGTable } from "components";
import { poGrid } from "../tables/poGrid";
import { poClientGrid } from "../tables/poClientGrid";
import { getFlaggedPos } from "redux/actions/pos.action";
import "../home.css";

const FlaggedPos = ({ po, getFlaggedPos }) => {
  useEffect(() => {
    if (!po.flaggedPosUpToDate) {
      getFlaggedPos();
    }
  }, [getFlaggedPos, po.flaggedPosUpToDate]);
  const isClient = useSelector((state) => state.user.user.isClient());
  const isDoneLoading = po.flaggedPosRetrieved;
  return (
    <Card title="Flagged POs">
      <AGTable
        tableName="flaggedPOs"
        isDoneLoading={isDoneLoading}
        paginate={true}
        gridConfig={isClient ? poClientGrid : poGrid}
        rowData={po.flaggedPos}
      />
    </Card>
  );
};

export default connect(({ po }) => ({ po }), { getFlaggedPos })(FlaggedPos);
