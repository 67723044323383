import React from "react";

const cellRender = ({ data, context }) => {
  const unlock = () => {
    context.componentParent.unlockUser(data);
  };
  return (
    <button className="btn btn-primary btn-sm" onClick={unlock}>
      Unlock
    </button>
  );
};

export const UnlockUser = {
  headerName: "Unlock",
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 125,
  cellRendererFramework: cellRender,
};
