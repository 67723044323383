import React, { useEffect } from "react";
import { connect } from "react-redux";
import { cartonGrid } from "./tables/cartonGrid";
import { getCartons } from "redux/actions/carton.action";
import { ContentHeader, AGTable, Content, Card } from "components";
import { cartons as c } from "config/constants";
import ActionAlerts from "../../components/ActionAlerts";
import { resetUpToDateFlag } from "redux/actions/global.action";

const Carton = ({ carton, getCartons, resetUpToDateFlag }) => {
  useEffect(() => {
    if (!carton.cartonsUpToDate) {
      getCartons();
    }
  }, [carton.cartonsUpToDate, getCartons]);

  useEffect(() => {
    return () => {
      resetUpToDateFlag();
    };
  }, [resetUpToDateFlag]);

  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Cartons" />
      <Content>
        <Card title="Cartons">
          <ActionAlerts item={carton} constants={c} />
          <AGTable
            tableName="carton"
            gridConfig={cartonGrid}
            rowData={carton.cartons}
            isDoneLoading={carton.cartonsRetrieved}
            action={getCartons}
            next={carton.next}
            previous={carton.previous}
            totalRows={carton.totalRows}
          />
        </Card>
      </Content>
    </div>
  );
};

export default connect(({ carton }) => ({ carton }), {
  getCartons,
  resetUpToDateFlag,
})(Carton);
