import PoDetail from "redux/models/PoDetail.model";
import { getEarliestCancelDate } from "screens/Pos/utils";

const initialState = {
  asns: [],
  shippableAsns: [],
  asnsUpToDate: false,
  asnsRetrieved: false,
  currentAsn: {},
  lines: [],
  cancelDate: "",
  asnSaveErrors: [],
  workingOnAsn: false,
  isEditingAsn: false,
  deletingAsn: false,
  error: "",
  added: false,
  deleted: false,
  updated: false,
  expiredAsns: [],
  asnSaveChange: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "GET_ASNS":
      return {
        ...state,
        asnsUpToDate: true,
        asnsRetrieved: false,
      };
    case "GET_ASNS_SUCCESS":
      return {
        ...state,
        asns: action.data.results,
        asnsRetrieved: true,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_ASNS_FAILED":
      return {
        ...state,
        error: action.error,
        asnsUpToDate: false,
      };

    case "GET_EXPIRED_ASNS":
      return {
        ...state,
        expiredAsnsUpToDate: true,
        expiredAsnsRetrieved: false,
      };
    case "GET_EXPIRED_ASNS_SUCCESS":
      return {
        ...state,
        expiredAsns: action.data.results,
        expiredAsnsRetrieved: true,
        enext: action.data.next,
        eprevious: action.data.previous,
        etotalRows: action.data.count,
      };
    case "GET_EXPIRED_ASNS_FAILED":
      return {
        ...state,
        error: action.error,
        expiredAsnsUpToDate: false,
      };

    case "GET_SHIPPABLE_ASNS":
      return {
        ...state,
        shippableAsnsUpToDate: true,
        shippableAsnsRetrieved: false,
      };
    case "GET_SHIPPABLE_ASNS_SUCCESS":
      return {
        ...state,
        shippableAsns: action.data.results,
        shippableAsnsRetrieved: true,
        snext: action.data.next,
        sprevious: action.data.previous,
        stotalRows: action.data.count,
      };
    case "GET_SHIPPABLE_ASNS_FAILED":
      return {
        ...state,
        error: action.error,
        shippableAsnsUpToDate: false,
      };

    case "SHOW_FAILED_TO_SAVE_ASN_ERRORS":
      return {
        ...state,
        asnSaveErrors: action.data,
      };

    case "CREATE_ASN_LINE": {
      const asnDetail = action.data;
      asnDetail.addToAsn();
      asnDetail.setAsn(state.currentAsn.id);
      asnDetail.setIsNew();

      return {
        ...state,
        lines: state.lines.concat([asnDetail]),
        cancelDate: state.cancelDate || asnDetail.poLine.cancel_date,
      };
    }
    case "EDIT_ASN": {
      const { asn, po } = action.data;
      let cancelDate,
        foundPartials = [];

      const lines = asn.details.map((detail) => {
        const poLine = po.po_lines.find((p) => p.id === detail.po_line.id);
        poLine.isAddedToAsn = true;
        const poAsnDetail = new PoDetail(poLine.poLine, detail);

        if (!cancelDate) cancelDate = poAsnDetail.getCancelDate();

        if (poAsnDetail.isPartial())
          foundPartials.push(poAsnDetail.getPoLineId());

        return poAsnDetail;
      });

      // eslint-disable-next-line array-callback-return
      foundPartials.forEach((poLineId) => {
        const detail = lines.find(
          (v) => v.getPoLineId() === poLineId && !v.isPartial(),
        );
        detail.hasPartial = detail.poLine.id;
      });

      return {
        ...state,
        lines,
        currentAsn: asn,
        cancelDate,
        workingOnAsn: true,
        isEditingAsn: true,
        asnSaveErrors: [],
        added: false,
        deleted: false,
        updated: false,
      };
    }
    case "CANCEL_EDIT_ASN":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        asns: [],
        currentAsn: {},
        cancelDate: "",
        workingOnAsn: false,
        isEditingAsn: false,
        asnsUpToDate: false,
        lines: [],
        error: "",
      };

    case "CREATE_ASN_PARTIAL": {
      const asnDetail = new PoDetail(action.data.poLine);
      asnDetail.setIsPartial(true);
      asnDetail.setAsn(state.currentAsn.id);

      action.data.hasPartial = asnDetail.id;
      return {
        ...state,
        lines: state.lines.concat([asnDetail]),
      };
    }
    case "ADD_CANCEL_DATE":
      return {
        ...state,
        cancelDate: action.data,
      };
    case "REMOVE_ASN_LINE": {
      const lines = deleteASNLine(action.data.id, state.lines);

      action.data.removeFromAsn();

      return {
        ...state,
        lines,
        cancelDate: lines.length === 0 ? "" : state.cancelDate,
      };
    }
    case "REMOVE_ASN_PARTIAL":
      const owner = getPartialOwner(action.data.id, state.lines);
      owner.hasPartial = false;

      return {
        ...state,
        lines: deleteASNLinePartial(action.data.id, state.lines),
      };
    case "START_ASN": {
      return {
        ...state,
        workingOnAsn: true,
        isEditingAsn: false,
        asnSaveErrors: [],
        added: false,
        deleted: false,
        updated: false,
      };
    }
    case "CREATE_ASN": {
      return {
        ...state,
        savingAsn: true,
        added: false,
        deleted: false,
        updated: false,
        asnSaveChange: false,
      };
    }
    case "CREATE_ASN_SUCCESS": {
      return {
        ...state,
        savingAsn: false,
        added: true,
        deleted: false,
        updated: false,
        lines: [],
        asnsUpToDate: false,
        workingOnAsn: false,
        cancelDate: "",
        asnSaveChange: true,
      };
    }
    case "CREATE_ASN_FAILED": {
      return {
        ...state,
        savingAsn: false,
        error: action?.error?.error,
        lines: [],
        added: false,
        deleted: false,
        updated: false,
        workingOnAsn: false,
      };
    }
    case "DELETE_ASN":
      return {
        ...state,
        deletingAsn: true,
        deleteAsnFailed: false,
        added: false,
        deleted: false,
        updated: false,
      };
    case "DELETE_ASN_SUCCESS":
      return {
        ...state,
        deletingAsn: false,
        deleted: true,
        added: false,
        updated: false,
        deleteAsnFailed: false,
        workingOnAsn: false,
        currentAsn: {},
        cancelDate: "",
        lines: [],
        asnSaveErrors: [],
        asnsUpToDate: false,
      };
    case "DELETE_ASN_FAILED":
      return {
        ...state,
        deletingAsn: false,
        deleteAsnFailed: true,
        added: false,
        deleted: false,
        updated: false,
      };
    case "SAVE_ASN":
      return {
        ...state,
        savingAsn: true,
        savingAsnError: false,
        added: false,
        deleted: false,
        updated: false,
      };
    case "SAVE_ASN_SUCCESS":
      return {
        ...state,
        savingAsn: false,
        savingAsnError: false,
        added: false,
        deleted: false,
        updated: true,
        workingOnAsn: false,
        currentAsn: {},
        cancelDate: "",
        lines: [],
        asnSaveErrors: [],
        asnsUpToDate: false,
      };
    case "SAVE_ASN_FAILED":
      return {
        ...state,
        savingAsn: false,
        savingAsnError: action.error,
        added: false,
        deleted: false,
        updated: false,
        workingOnAsn: false,
      };
    case "TOGGLE_IGNORE_SHIP_WINDOW": {
      var all_asn = state.asns
        .filter(
          (asn) => !state.expiredAsns.map((asn) => asn.id).includes(asn.id),
        )
        .concat(state.expiredAsns);
      const asn = all_asn.find((a) => a.id === action.requestData.asn);
      asn.ignoreShipmentProcessing = true;
      return {
        ...state,
      };
    }
    case "TOGGLE_IGNORE_SHIP_WINDOW_SUCCESS": {
      var all_asns = state.asns
        .filter(
          (asn) => !state.expiredAsns.map((asn) => asn.id).includes(asn.id),
        )
        .concat(state.expiredAsns);
      const asn = all_asns.find((a) => a.id === action.requestData.asn);
      asn.ignore_shipment_window = true;
      asn.ignoreShipmentProcessing = false;
      return {
        ...state,
        asnsUpToDate: false,
      };
    }
    case "START_EDITING_ASN": {
      return {
        ...state,
        isEditingAsn: true,
      };
    }
    case "RESET_ASN_ALERT": {
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
      };
    }
    case "DELETE_SHIPMENT_SUCCESS": {
      return {
        ...state,
        asnsUpToDate: false,
      };
    }
    case "ADD_ALL_ASN_LINES": {
      const asnDetails = action.data;
      let cancelDatesSet = new Set();
      let earliestCancelDate = null;
      let newDetail = [];
      let error = "";

      if (state.lines.length === 0) {
        asnDetails.forEach((d) => {
          cancelDatesSet.add(d.poLine.cancel_date);
        });

        // Convert Set to array
        const arrayFromSet = Array.from(cancelDatesSet);

        if (arrayFromSet?.length > 1) {
          earliestCancelDate = getEarliestCancelDate(arrayFromSet);
        } else {
          earliestCancelDate = arrayFromSet?.[0];
        }

        asnDetails.forEach((d) => {
          if (earliestCancelDate === d?.poLine?.cancel_date) {
            newDetail.push(d);
            d.addToAsn();
            d.setAsn(state.currentAsn.id);
            d.setIsNew();
          }
        });
      } else {
        asnDetails.forEach((d) => {
          if (d.canAddToAsn(state.cancelDate)) {
            newDetail.push(d);
            d.addToAsn();
            d.setAsn(state.currentAsn.id);
            d.setIsNew();
          }
        });
      }

      return {
        ...state,
        lines: state.lines.concat(newDetail),
        cancelDate: state.cancelDate || earliestCancelDate,
        error: error,
      };
    }
    case "RESET_UPTODATE_FLAG":
      return {
        ...state,
        asnsUpToDate: false,
      };
    case "EXTEND_SHIP_WINDOW": {
      return {
        ...state,
        added: false,
      };
    }
    case "EXTEND_SHIP_WINDOW_SUCCESS": {
      return {
        ...state,
        added: true,
      };
    }
    case "EXTEND_SHIP_WINDOW_FAILED": {
      return {
        ...state,
        error: action.error.error,
      };
    }
    default:
      return state;
  }
};

const deleteASNLine = (id, lines) => lines.filter((v) => v.id !== id);
const deleteASNLinePartial = (id, lines) => {
  for (let i in lines) {
    let line = lines[i];
    if (line.id === id && line.isPartial()) {
      lines.splice(i, 1);
    }
  }

  return lines;
};
const getPartialOwner = (id, lines) =>
  lines.filter((v) => v.hasPartial === id)[0];
