import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteCarton } from "redux/actions/carton.action";
import ModalFooterDeleteClose from "../components/ModalFooterDeleteClose";
import ModalBodyDelete from "../components/ModalBodyDelete";

class CartonDeleteForm extends React.Component {
  render() {
    const {
      modal: { selectedData },
    } = this.props;
    return (
      <>
        <form>
          <ModalBodyDelete name={selectedData.carton_type_name} />
          <ModalFooterDeleteClose
            click={() => {
              this.props.deleteCarton(selectedData.id);
            }}
          />
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user }) => ({ modal, user }),
  (dispatch) => bindActionCreators({ deleteCarton }, dispatch),
)(CartonDeleteForm);
