import { combineReducers } from "redux";
import user from "./user.reducer";
import modal from "./modal.reducer";
import carrier from "./carrier.reducer";
import vendor from "./vendor.reducer";
import po from "./pos.reducer";
import asn from "./asn.reducer";
import role from "./role.reducer";
import layout from "./layout.reducer";
import carton from "./carton.reducer";
import client from "./client.reducer";
import log from "./log.reducer";
import script from "./tool.reducer";
import shipment from "./shipment.reducer";
import warehouse from "./warehouse.reducer";
import setting from "./setting.reducer";
import email from "./email.reducer";
import reports from "./reports.reducer";

const rootReducer = combineReducers({
  user,
  modal,
  carrier,
  vendor,
  po,
  asn,
  layout,
  role,
  carton,
  client,
  log,
  script,
  shipment,
  warehouse,
  setting,
  email,
  reports,
});

export default rootReducer;
