import { negativeToZeroFormatter } from "utils/helperFunctions";
const maintainOriginalOrder = () => 0;
export const TextCellStyle = {
  display: "flex",
  "justify-content": "flex-start",
};
export const NumberCellStyle = {
  display: "flex",
  "justify-content": "center",
};
export const IconCellStyle = { display: "flex", "justify-content": "center" };

export const FlaggedColumnDefinition = {
  headerName: "Flagged",
  width: 88,
  cellStyle: IconCellStyle,
  filter: "RadioSetFilter",
  valueGetter: (params) => (params.data.flagged ? "flagged" : "unflagged"),
  cellRenderer: (params) =>
    params.value === "flagged" ? `<i class="icon-flag3 red" />` : "",
};
const rmqty = "Remaining Qty";

export const AsnNumberColumnDefinition = {
  headerName: "ASN #",
  cellStyle: IconCellStyle,
  field: "id",
  width: 77,
};

export const LockedColumnDefinition = {
  headerName: "Locked",
  width: 105,
  cellStyle: IconCellStyle,
  valueGetter: (params) => (params.data.locked ? "locked" : "unlocked"),
  cellRenderer: "UnlockButton",
  filter: "RadioSetFilter",
  field: "locked_by",
  comparator: maintainOriginalOrder,
};

export const CreatedAtColumnDefinition = {
  headerName: "Created",
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 130,
  filter: "RadioSetFilter",
  field: "created_at",
};

// const valueFormatter = (params) => Number.parseFloat(params.value).toFixed(2);

// export const AsnVolumeColumnDefinition = (volume) => {
//   return {
//     headerName: "Vol (ft³)",
//     field: volume,
//     cellStyle: NumberCellStyle,
//     width: 85,
//     //valueGetter: valueGetter,
//     valueFormatter: valueFormatter,
//   };
// };

export const PoCancelDateColumnDefinition = (() => {
  return {
    headerName: "Cancel Date",
    colId: "cancel_date",
    filter: "RadioSetFilter",
    field: "dates",
    valueGetter: (params) => {
      const cancelDates = params.data.dates.map((dates) => dates[1]);
      return cancelDates.sort()[0];
      // return params.data.dates[1];
    },
    comparator: maintainOriginalOrder,
    width: 115,
  };
})();

export const PoStartDateColumnDefinition = (() => {
  return {
    headerName: "Start Date",
    colId: "start_date",
    filter: "RadioSetFilter",
    field: "dates",
    valueGetter: (params) => {
      const cancelDates = params.data.dates.map((dates) => dates[0]);
      return cancelDates.sort()[0];
      // return params.data.dates[0];
    },
    comparator: maintainOriginalOrder,
    width: 115,
  };
})();

export const ShipToStoreColumnDefinition = {
  headerName: "Destination Warehouse",
  cellStyle: IconCellStyle,
  valueGetter: ({ data }) => {
    if (data.po && data.po.destination_name) {
      return data.po.destination_name;
    } else if (data.destination_name) {
      return data.destination_name;
    }
    return "Store not found";
  },
  width: 175,
  filter: "RadioSetFilter",
  colId: "ship_to_store",
};

export const TotalCarton = {
  headerName: "Total Cartons",
  width: 145,
  cellStyle: IconCellStyle,
  cellRenderer: (params) => {
    return params?.data?.total_cartons;
  },
};

export const ActiveColumnDef = {
  headerName: "Active",
  cellStyle: { display: "flex", "justify-content": "center" },
  field: "active",
  width: 80,
  cellRenderer: (params) => {
    return params.value ? `<i  class="icon-check green" />` : "";
  },
};

export const EditDeleteColumnDef = {
  width: 50,
  cellStyle: { display: "flex", "justify-content": "center" },
  cellRenderer: "EditDeleteButtons",
};

export const NameColumnDef = {
  headerName: "Name",
  width: 120,
  field: "name",
  cellStyle: TextCellStyle,
};

export const NameColumnDefVendor = {
  headerName: "Name",
  width: 610,
  field: "name",
  cellStyle: TextCellStyle,
};
export const EmailColumnDef = {
  headerName: "Email",
  width: 200,
  field: "email",
  cellStyle: TextCellStyle,
};

export const AddressColumnDef = {
  headerName: "Address",
  width: 120,
  field: "address",
  cellStyle: TextCellStyle,
};

export const CityColumnDef = {
  headerName: "City",
  width: 95,
  field: "city",
};

export const CountryColumnDef = {
  headerName: "Country",
  width: 95,
  field: "country",
};

export const StateColumnDef = {
  headerName: "State",
  width: 95,
  field: "state",
};

export const ZipcodeColumnDef = (zip) => {
  return {
    headerName: "Zipcode",
    width: 95,
    field: `${zip}`,
  };
};

export const PhoneColumnDef = {
  headerName: "Phone",
  width: 115,
  field: "phone",
};

export const FaxColumnDef = {
  headerName: "Fax",
  width: 125,
  field: "fax",
};

export const ContactColumnDef = {
  headerName: "Contact",
  cellStyle: TextCellStyle,
  width: 105,
  field: "contact_name",
};

export const VendorColumnDef = (vendor) => {
  return {
    headerName: "Vendor",
    width: 225,
    field: `${vendor}`,
    cellStyle: TextCellStyle,
  };
};

export const WeightColumnDef = {
  headerName: "Wt (lbs)",
  cellStyle: NumberCellStyle,
  width: 95,
  field: "weight",
  valueGetter: (params) => {
    return params.data.weight;
  },
};

export const PoNumberColumnDef = (po) => {
  return {
    headerName: "PO #",
    cellStyle: NumberCellStyle,
    field: `${po}`,
    width: 90,
  };
};

export const PickUpWindowColumnDef = {
  headerName: "Pick up window",
  width: 145,
  field: "pick_up_window",
};

export const DivisionColumnDef = {
  headerName: "Division",
  cellStyle: IconCellStyle,
  field: "po_division",
  filter: "RadioSetFilter",
  width: 115,
};

export const SizeColumnDef = {
  headerName: "Size",
  cellStyle: IconCellStyle,
  field: "po_size",
  filter: "RadioSetFilter",
  width: 125,
};

export const ColorColumnDef = {
  headerName: "Color",
  field: "po_color",
  filter: "RadioSetFilter",
  width: 75,
};

export const RemainingQtyColumnDef = ({ field = "", colId = "" }) => ({
  headerName: `${rmqty}`,
  field,
  colId,
  cellStyle: { display: "flex", "justify-content": "center" },
  valueFormatter: negativeToZeroFormatter,
  width: 120,
});
