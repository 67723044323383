import React from "react";
import { useSelector } from "react-redux";
import { LoginLogoBar, Footer } from "components";
import RecoverPassForm from "./components/RecoverPassForm";
import Succeeded from "./components/Succeeded";
import Failed from "./components/Failed";

const StatusWrapper = ({ children }) => (
  <div className="card mb-0 box-container">
    <div className="card-body">
      <div className="text-center mb-3">{children}</div>
    </div>
  </div>
);

const Recover = () => {
  const passwordResetSucceeded = useSelector(
    (store) => store.user.passwordResetSucceeded,
  );
  const passwordResetFailed = useSelector(
    (store) => store.user.passwordResetFailed,
  );
  const hasNotYetResetPassword = !(
    passwordResetSucceeded || passwordResetFailed
  );
  return (
    <>
      <LoginLogoBar />

      <div className="page-content login-page-content">
        <div className="content-wrapper login-page">
          <div className="content d-flex justify-content-center align-items-center">
            {hasNotYetResetPassword && <RecoverPassForm />}
            {passwordResetSucceeded && (
              <StatusWrapper>
                <Succeeded />
              </StatusWrapper>
            )}
            {passwordResetFailed && (
              <StatusWrapper>
                <Failed />
              </StatusWrapper>
            )}
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};
export default Recover;
