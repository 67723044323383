import React from "react";
import ActionIcon from "./components/ActionIcon";

const addIcon = ({ data, context }) => {
  const warehouses = context.componentParent.props.carrier.carrierWarehouses;
  const alreadyHasWarehouse = warehouses.some(
    (warehouse) => warehouse.id === data.id,
  );
  if (alreadyHasWarehouse) {
    return null;
  }
  return (
    <ActionIcon
      click={(e) => {
        e.preventDefault();
        context.componentParent.addWarehouseToCarrier(data);
      }}
      icon={"icon-add"}
    />
  );
};
export const AddWarehouseToCarrier = {
  headerName: "Add To Carrier",
  cellRendererFramework: addIcon,
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 169,
};
