import React from "react";

import { UncontrolledAlert } from "reactstrap";

const ActionAlerts = ({ item, constants }) => {
  try {
    return (
      <>
        {item && item?.added && (
          <UncontrolledAlert color="success">
            <p>{constants?.savedSuccessfully}</p>
          </UncontrolledAlert>
        )}
        {item && item?.emailAlert && (
          <UncontrolledAlert color="success">
            <p>{constants?.emailAlert}</p>
          </UncontrolledAlert>
        )}
        {item && item?.updated && (
          <UncontrolledAlert color="success">
            <p>{constants?.updatedSuccessfully}</p>
          </UncontrolledAlert>
        )}
        {item && item?.deleted && (
          <UncontrolledAlert color="success">
            <p>{constants?.deletedSuccessfully}</p>
          </UncontrolledAlert>
        )}
        {item && item?.profileUpdated && (
          <UncontrolledAlert color="success">
            <p>{item && constants?.profileUpdated}</p>
          </UncontrolledAlert>
        )}
        {item?.error && (
          <UncontrolledAlert color="danger">
            <p>{item.error?.errors?.trim() || item.error?.trim()}</p>
          </UncontrolledAlert>
        )}
      </>
    );
  } catch (error) {
    return (
      <>
        <UncontrolledAlert color="danger">
          {item?.error?.status === 404 ? (
            "Sorry, the requested data isn't available at the moment. Please try again later"
          ) : (
            <p>Error: Something went wrong</p>
          )}
        </UncontrolledAlert>
      </>
    );
  }
};

export default ActionAlerts;
