import React, { useEffect, useRef } from "react";
import {
  AGTable,
  Content,
  ContentHeader,
  ActionAlerts,
  Card,
} from "components";

import { connect } from "react-redux";
import { warehouseGrid } from "./tables/warehouseGrid";
import { getWarehouses } from "redux/actions/warehouse.action";
import { warehouse as c } from "config/constants";

const Warehouse = ({ warehouse, getWarehouses }) => {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!warehouse.warehousesUpToDate || isInitialMount.current) {
      getWarehouses();
    }
    isInitialMount.current = false;
  }, [warehouse.warehousesUpToDate, getWarehouses]);

  const { warehouses, warehousesRetrieved } = warehouse;
  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Warehouses" />
      <Content>
        <Card title="Warehouses">
          <ActionAlerts item={warehouse} constants={c} />
          <AGTable
            tableName="warehouses"
            isDoneLoading={warehousesRetrieved}
            gridConfig={warehouseGrid}
            rowData={warehouses}
            action={getWarehouses}
            next={warehouse.next}
            previous={warehouse.previous}
            totalRows={warehouse.totalRows}
          />
        </Card>
      </Content>
    </div>
  );
};

export default connect(({ warehouse }) => ({ warehouse }), { getWarehouses })(
  Warehouse,
);
