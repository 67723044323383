import Service from "./service";

export const getLogs = () => (dispatch) => {
  Service.get(dispatch, "po/logs/", {
    init: "GET_LOGS",
    success: "GET_LOGS_SUCCESS",
    error: "GET_LOGS_FAILED",
  });
};

export const getUserLogs = () => (dispatch) => {
  Service.get(dispatch, "tools/user_logs/", {
    init: "GET_USER_LOGS",
    success: "GET_USER_LOGS_SUCCESS",
    error: "GET_USER_LOGS_FAILED",
  });
};
