import React from "react";
import { Link } from "react-router-dom";

import { ContentHeader, Content, Button } from "components";
import { pos as c } from "config/constants";

const CartonRedirectButton = ({ info }) => {
  return (
    <div className="content-wrapper">
      <ContentHeader info={info} pagename="Purchase Order" menuType="Basic" />
      <Content>
        <p>{c.noCartonFoundError}</p>
        <Link to="/carton">
          <Button className={"float-left"} btnSize="100px">
            Confirm
          </Button>
        </Link>
      </Content>
    </div>
  );
};

export default CartonRedirectButton;
