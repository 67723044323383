import Service from "./service";
import { resetAlerts } from "./global.action";

export const getVendors = (queryParam) => (dispatch) => {
  Service.get(dispatch, "vendor/vendors", queryParam || {}, {
    init: "GET_VENDORS",
    success: "GET_VENDORS_SUCCESS",
    error: "GET_VENDORS_FAILED",
  });
};

export const getVendorsWithZeroUsers = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.zusers = true;
  qp.page_size = 10;
  Service.get(dispatch, "vendor/vendors", qp, {
    init: "GET_VENDORS_ZEROUSERS",
    success: "GET_VENDORS_ZEROUSERS_SUCCESS",
    error: "GET_VENDORS_ZEROUSERS_FAILED",
  });
};

export const getAllVendors = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.page_size = 20000;
  Service.get(dispatch, "vendor/vendors", qp, {
    init: "GET_ALL_VENDORS",
    success: "GET_ALL_VENDORS_SUCCESS",
    error: "GET_ALL_VENDORS_FAILED",
  });
};

export const getVendorsWithWarehouses = () => (dispatch) => {
  Service.get(dispatch, "vendor/warehouseVendors", {
    init: "GET_VENDORS_WITH_WAREHOUSES",
    success: "GET_VENDORS_WITH_WAREHOUSES_SUCCESS",
    error: "GET_VENDORS_WITH_WAREHOUSES_FAILED",
  });
};

export const getVendor = (id) => (dispatch) => {
  Service.get(dispatch, `vendor/vendors/${id}`, {
    init: "GET_VENDOR",
    success: "GET_VENDOR_SUCCESS",
    error: "GET_VENDOR_FAILED",
  });
};

export const addVendor = (vendor) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "vendor/vendors/", vendor, {
    init: "ADD_VENDOR",
    success: "ADD_VENDOR_SUCCESS",
    error: "ADD_VENDOR_FAILED",
  });
};

export const deleteVendor = (id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.delete(dispatch, `vendor/vendors/${id}`, {
    init: "DELETE_VENDOR",
    success: "DELETE_VENDOR_SUCCESS",
    error: "DELETE_VENDOR_FAILED",
  });
};

export const updateVendor = (vendor) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.patch(dispatch, `vendor/vendors/${vendor.id}`, vendor, {
    init: "UPDATE_VENDOR",
    success: "UPDATE_VENDOR_SUCCESS",
    error: "UPDATE_VENDOR_FAILED",
  });
};

export const vendorIntake = (vendors) => (dispatch) =>
  Service.put(
    dispatch,
    "vendor/vendors",
    { vendors },
    {
      init: "VENDOR_INTAKE",
      success: "VENDOR_INTAKE_SUCCESS",
      error: "VENDOR_INTAKE_FAILED",
    },
  );
