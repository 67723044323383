import React from "react";
import OpenModalIcon from "./components/OpenModalIcon";

const cellRender = ({ data, context }) => {
  return (
    <OpenModalIcon
      click={() => context.componentParent.updateData(data, "view", "Shipment")}
      icon={"icon-menu6"}
    />
  );
};

export const ShipmentASNs = {
  headerName: "ASNs",
  filter: null,
  cellStyle: { display: "flex", "justify-content": "center" },
  field: "asns",
  width: 70,
  sortable: false,
  cellRendererFramework: cellRender,
  suppressSizeToFit: true,
};
