import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getLogs, getUserLogs } from "redux/actions/log.action";
import { logGrid } from "./tables/logGrid";
import { userLogsGrid } from "./tables/userLogsGrid.js";
import { ContentHeader, Content, Card, AGTable } from "components";

const Log = ({ log, getLogs, getUserLogs }) => {
  useEffect(() => {
    getLogs();
    getUserLogs();
  }, [getLogs, getUserLogs]);
  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Logs" menuType="Basic" />
      <Content>
        <Card title="PO Parser Logs">
          <AGTable
            isDoneLoading={true}
            tableName="poParserLogs"
            paginate={true}
            gridConfig={logGrid}
            rowData={log.logs}
          />
        </Card>
        <br />
        <Card title="User Logs">
          <AGTable
            isDoneLoading={true}
            tableName="userLogs"
            paginate={true}
            gridConfig={userLogsGrid}
            rowData={log.userLogs}
          />
        </Card>
      </Content>
    </div>
  );
};

export default connect(({ log }) => ({ log }), { getLogs, getUserLogs })(Log);
