import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import app from "./reducers";
import { log } from "config/config";

const logger = (store) => (next) => (action) => {
  let shouldLog = false;
  if (action.type.endsWith("FAILED")) {
    shouldLog = true;
  }
  if (action.type.endsWith("SUCCESS") && !action.type.startsWith("GET")) {
    shouldLog = true;
  }
  if (shouldLog) log(action);
  return next(action);
};

const middleware = [thunk, logger];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  app,
  composeEnhancers(applyMiddleware(...middleware)),
);
export default store;
