const initialState = {
  cartons: [],
  allCartons: [],
  cartonsUpToDate: false,
  cartonsRetrieved: false,
  added: false,
  deleted: false,
  updated: false,
  error: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "GET_CARTONS":
      return {
        ...state,
        cartonsUpToDate: true,
        cartonsRetrieved: false,
      };
    case "GET_CARTONS_SUCCESS":
      return {
        ...state,
        cartons: action.data.results,
        cartonsRetrieved: true,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_CARTONS_FAILED":
      return {
        ...state,
        error: action.error,
        cartonsUpToDate: false,
      };
    case "GET_ALL_CARTONS_SUCCESS":
      return {
        ...state,
        allCartons: action.data.results,
        allCartonsUpToDate: true,
      };
    case "GET_ALL_CARTONS_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "ADD_CARTON":
      return {
        ...state,
        added: false,
      };
    case "ADD_CARTON_SUCCESS":
      return {
        ...state,
        added: true,
        cartons: state.cartons.concat([action.data]),
        cartonsUpToDate: false,
        allCartonsUpToDate: false,
      };
    case "ADD_CARTON_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "DELETE_CARTON":
      return {
        ...state,
        deleted: false,
      };
    case "DELETE_CARTON_SUCCESS":
      return {
        ...state,
        deleted: true,
        cartons: deleteCarton(action.data, state.cartons),
        cartonsUpToDate: false,
        allCartonsUpToDate: false,
      };
    case "DELETE_CARTON_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "UPDATE_CARTON":
      return {
        ...state,
        updated: false,
      };
    case "UPDATE_CARTON_SUCCESS":
      return {
        ...state,
        cartons: updateCarton(action.data, state.cartons),
        updated: true,
        cartonsUpToDate: false,
        allCartonsUpToDate: false,
      };
    case "UPDATE_CARTON_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "RESET_UPTODATE_FLAG":
      return {
        ...state,
        cartonsUpToDate: false,
      };
    default:
      return state;
  }
};
const deleteCarton = (id, cartons) => cartons.filter((c) => c.id !== id);
const updateCarton = (carton, cartons) =>
  cartons.map((c) => (c = c.id === carton.id ? carton : c));
