import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getVendors } from "redux/actions/vendor.action";
import { getClients } from "redux/actions/client.action";
import { vendorGrid } from "./tables/vendorGrid";
import { resetUpToDateFlag } from "redux/actions/global.action";
import {
  ContentHeader,
  Content,
  AGTable,
  Card,
  ActionAlerts,
} from "components";
// import VendorImporter from "./components/VendorImporter";
import { vendor as v } from "config/constants";

const Vendor = ({
  vendor,
  getVendors,
  user,
  client,
  getClients,
  resetUpToDateFlag,
}) => {
  useEffect(() => {
    if (!vendor.vendorsUpToDate) {
      getVendors();
    }
  }, [getVendors, vendor.vendorsUpToDate]);

  useEffect(() => {
    if (user.user.isSuper() && !client.clientsUpToDate) {
      getClients();
    }
  }, [user.user, getClients, client.clientsUpToDate, resetUpToDateFlag]);

  useEffect(() => {
    return () => {
      resetUpToDateFlag();
    };
  }, [resetUpToDateFlag]);

  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Vendors" />
      <Content>
        <Card title="Vendors">
          <ActionAlerts item={vendor} constants={v} />
          {/* <VendorImporter /> */}
          <AGTable
            tableName="vendors"
            gridConfig={vendorGrid}
            rowData={vendor.vendors}
            isDoneLoading={vendor.vendorsRetrieved}
            action={getVendors}
            next={vendor.next}
            previous={vendor.previous}
            totalRows={vendor.totalRows}
          />
        </Card>
      </Content>
    </div>
  );
};

export default connect(
  ({ user, vendor, client }) => ({ user, vendor, client }),
  { getVendors, getClients, resetUpToDateFlag },
)(Vendor);
