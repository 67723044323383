import PoDetail from "redux/models/PoDetail.model";

const initialState = {
  pos: [],
  allPoLine: [],
  flaggedPos: [],
  expiringPos: [],
  posRetrieved: false,
  posUpToDate: false,
  currentPo: {
    asns: [],
  },
  currentPoHasChanged: true,
  lines: [],
  cancelDate: "",
  error: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "RESET_ALERTS":
      return {
        ...state,
        poLockError: "",
        error: "",
      };
    case "LOGOUT":
      return initialState;
    case "GET_POS":
      return {
        ...state,
        gettingPos: true,
        posUpToDate: true,
        posRetrieved: false,
      };
    case "GET_POS_SUCCESS":
      return {
        ...state,
        pos: action.data.results,
        gettingPos: false,
        posRetrieved: true,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_POLINE":
      return {
        ...state,
        gettingPos: true,
        posUpToDate: true,
        posRetrieved: false,
      };
    case "GET_POLINE_SUCCESS":
      let currentPos = {
        ...action.data.currentPo,
        po_lines: action.data.results,
      };
      state.isEditingAsn
        ? (currentPos.po_lines = state.lines)
        : (currentPos.po_lines = currentPos.po_lines.map(
            (v) => new PoDetail(v),
          ));

      return {
        ...state,
        currentPo: currentPos,
        lines: currentPos.po_lines,
        gettingPos: false,
        posRetrieved: true,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_POS_FAILED":
      return {
        ...state,
        error: action.error,
        gettingPos: false,
        posUpToDate: false,
      };
    case "GET_FLAGGED_POS":
      return {
        ...state,
        gettingFlaggedPos: true,
        flaggedPosUpToDate: true,
        flaggedPosRetrieved: false,
      };
    case "GET_FLAGGED_POS_SUCCESS":
      return {
        ...state,
        flaggedPos: action.data.results,
        gettingFlaggedPos: false,
        flaggedPosRetrieved: true,
      };
    case "GET_FLAGGED_POS_FAILED":
      return {
        ...state,
        error: action.error,
        gettingFlaggedPos: false,
        flaggedPosUpToDate: false,
      };
    case "GET_EXPIRING_POS":
      return {
        ...state,
        expiringPosRetrieved: false,
      };
    case "GET_EXPIRING_POS_SUCCESS":
      return {
        ...state,
        expiringPos: action.data.results,
        expiringPosRetrieved: true,
        exNext: action.data.next,
        exPrevious: action.data.previous,
        exTotalRows: action.data.count,
      };
    case "GET_EXPIRING_POS_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "GET_PO":
      return {
        ...state,
        currentPo: {
          asns: [],
        },
        currentPoHasChanged: false,
      };
    case "GET_PO_SUCCESS":
      let po = action.data.data;
      state.isEditingAsn
        ? (po.po_lines = state.lines)
        : (po.po_lines = po.po_lines.map((v) => new PoDetail(v)));

      return {
        ...state,
        currentPo: po,
        allPoLine: po.po_lines,
      };

    case "EDIT_ASN":
      return {
        ...state,
        isEditingAsn: true,
      };
    case "LOCK_PO_SUCCESS":
      return {
        ...state,
        posUpToDate: false,
        currentPoHasChanged: true,
      };
    case "LOCK_PO_FAILED":
      return {
        ...state,
        posUpToDate: false,
        currentPoHasChanged: true,
        poLockError: action.error.error,
      };
    case "CREATE_ASN_LINE":
      return {
        ...state,
        lines: state.lines.concat([]),
        asnLineCreated: true,
      };
    case "SAVE_ASN_SUCCESS":
      return {
        ...state,
        posUpToDate: false,
        currentPoHasChanged: true,
        added: true,
        isEditingAsn: false,
      };
    case "REMOVE_ASN_LINE":
      let removedLine = action.data;
      removedLine.asnDetail = {};
      return {
        ...state,
        lines: state.lines
          .filter((v) => v.id !== removedLine.id)
          .concat([removedLine]),
        asnLineRemoved: true,
      };

    case "DELETE_ASN_SUCCESS":
      return {
        ...state,
        posUpToDate: false,
        currentPoHasChanged: true,
        deleted: true,
      };

    case "UNLOCK_PO":
      return {
        ...state,
        unlockingPo: true,
        error: "",
      };
    case "UNLOCK_PO_SUCCESS": {
      const po_number = action.data.data.slice(12);
      const po = state.pos.find((p) => p.po_number === po_number);
      po.locked = false;
      return {
        ...state,
        posUpToDate: false,
        currentPoHasChanged: true,
        isEditingAsn: false,
      };
    }
    case "UNLOCK_PO_FAILED":
      return {
        ...state,
        error: action.error.error,
      };
    case "CREATE_ASN_SUCCESS": {
      return {
        ...state,
        currentPoHasChanged: true,
        posUpToDate: false,
      };
    }
    case "PARSE_POS": {
      return {
        ...state,
        parsing: true,
        parsed: false,
      };
    }
    case "PARSE_POS_SUCCESS": {
      return {
        ...state,
        parsing: false,
        parsed: true,
      };
    }
    case "PARSE_POS_FAILED": {
      return {
        ...state,
        parsing: false,
        parsed: false,
      };
    }
    default:
      return state;
  }
};
