import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addEmail } from "redux/actions/email.action";
import ModalFooterDeleteClose from "../components/ModalFooterDeleteClose";
import ModalBodyDelete from "../components/ModalBodyDelete";

function mapStateToProps(state) {
  const { emailLists, selectedDivision } = state.email;
  const {
    user: { client },
  } = state.user;
  const { selectedData } = state.modal;
  return { selectedData, emailLists, client, selectedDivision };
}

class EmailDeleteForm extends React.Component {
  state = this.initialState();

  initialState() {
    return {
      data: {
        group_id: 0,
        first_name: "",
        last_name: "",
        email: "",
      },
    };
  }

  getGroup() {
    return this.props.emailLists.map((e) => {
      return { id: e.id, name: e.name };
    });
  }

  updateUser(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  clearFields() {
    this.setState(this.initialState());
  }

  handleSubmit(emailId) {
    let division = this.props.emailLists.filter(
      (e) => e.name === this.props.selectedDivision,
    )[0];
    const groupId = division.id;
    const result = division.emails.filter((e) => e.id !== emailId);
    const newDivision = { ...division, emails: result };

    this.props.addEmail(newDivision, groupId);
    this.clearFields();
  }

  render() {
    const { selectedData } = this.props;
    return (
      <>
        <form>
          <ModalBodyDelete name={selectedData.name} />
          <ModalFooterDeleteClose
            click={() => {
              this.handleSubmit(selectedData.id);
            }}
          />
        </form>
      </>
    );
  }
}
export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ addEmail }, dispatch),
)(EmailDeleteForm);
