import React from "react";
import BreadcrumbLink from "./components/BreadcrumbLink";

const cellRender = ({ data }) => {
  return (
    <BreadcrumbLink
      pathname={"/shipment/" + data.id}
      breadcrumb={"Shipments"}
      url={"shipment"}
    />
  );
};
export const ShipmentDetail = {
  headerName: "Report",
  filter: null,
  width: 85,
  sortable: false,
  cellRendererFramework: cellRender,
  pinned: "left",
};
