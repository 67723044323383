import React, { useEffect } from "react";
import {
  ContentHeader,
  Content,
  AGTable,
  Card,
  ActionAlerts,
} from "components";

import { connect } from "react-redux";
import { shipmentGrid } from "./tables/shipmentGrid";
import { shipmentClientGrid } from "./tables/shipmentClientGrid";

import { getShipments } from "redux/actions/shipment.action";
import { getEmails } from "redux/actions/client.action";
import { resetUpToDateFlag } from "redux/actions/global.action";

import { shipment as s } from "config/constants";

const Shipment = ({ shipment, getShipments, client, user, getEmails }) => {
  useEffect(() => {
    if (!shipment.shipmentsUpToDate) {
      getShipments();
    }
    if (user.user.isClient()) {
      if (!client.emailsUpToDate) {
        getEmails();
      }
    }
  }, [
    shipment.shipmentsUpToDate,
    getShipments,
    user.user,
    client.emailsUpToDate,
    getEmails,
  ]);

  useEffect(() => {
    return () => {
      resetUpToDateFlag();
    };
  }, []);

  const { shipments, shipmentsRetrieved } = shipment;

  return (
    <div className="content-wrapper">
      {user.user.isClient() ? (
        <ContentHeader pagename="Shipments" />
      ) : (
        <ContentHeader pagename="Shipments" menuType="Basic" />
      )}
      <Content>
        {/* <ShowContent 
        condition={shipments.length > 0}
        response={'There are no shipments currently'}
        > */}
        <Card title="Shipments">
          <ActionAlerts item={shipment} constants={s} />
          <AGTable
            tableName="shipments"
            isDoneLoading={shipmentsRetrieved}
            gridConfig={
              user && user.user.isClient() ? shipmentClientGrid : shipmentGrid
            }
            rowData={shipments}
            action={getShipments}
            next={shipment.next}
            previous={shipment.previous}
            totalRows={shipment.totalRows}
          />
        </Card>
        {/* </ShowContent> */}
        {/* <ShowContent 
        condition={emails.length > 0}
        response={'There are no emails currently'}
        > */}

        {/* </ShowContent> */}
      </Content>
    </div>
  );
};

export default connect(
  ({ shipment, client, user }) => ({
    shipment,
    client,
    user,
  }),
  { getShipments, getEmails },
)(Shipment);
