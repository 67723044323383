import React from "react";
import "./DashboardLayout.css";
import { Header, Sidebar, Modal } from "components";

class DashboardLayout extends React.Component {
  render() {
    return (
      <div className=" App">
        <Modal />
        <Header />
        <div className="page-content">
          <Sidebar key={Date.now()} />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DashboardLayout;
