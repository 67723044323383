import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const CustomPagination = ({
  pageLength,
  totalRows,
  onPaginationChanged,
  next,
  previous,
}) => {
  let nextPage = null;
  if (next) nextPage = next.split("page=")[1].split("&")[0];
  let prevPage = null;
  if (previous)
    prevPage = previous.includes("page=")
      ? previous.split("page=")[1].split("&")[0]
      : 1;

  return (
    <>
      <Pagination className="float-right">
        <span style={{ marginTop: "7px", marginRight: "1px" }}>
          {nextPage
            ? 1 +
              (nextPage - 2) * pageLength +
              " to " +
              (nextPage - 1) * pageLength +
              " of " +
              totalRows
            : totalRows === 0
              ? "0 to 0 of 0"
              : totalRows -
                pageLength +
                1 +
                " to " +
                totalRows +
                " of " +
                totalRows}
        </span>

        <PaginationItem>
          <PaginationLink
            className="border-0 "
            disabled={!previous}
            onClick={() => onPaginationChanged(null)}
          >
            <i className="fa fa-angle-double-left"></i>
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className="border-0"
            disabled={!previous}
            onClick={() => onPaginationChanged(prevPage)}
          >
            <i className="fa fa-angle-left"></i>
          </PaginationLink>
        </PaginationItem>
        <span style={{ marginTop: "8px", marginRight: "1px" }}>
          {nextPage
            ? "Page " +
              (nextPage - 1) +
              " of " +
              Math.ceil(totalRows / pageLength)
            : "Page " +
              (parseInt(prevPage || 0) + 1) +
              " of " +
              (parseInt(prevPage || 0) + 1)}
        </span>
        <PaginationItem>
          <PaginationLink
            className="border-0"
            disabled={!next}
            onClick={() => onPaginationChanged(nextPage)}
          >
            <i className="fa fa-angle-right "></i>
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className="border-0"
            disabled={!next}
            onClick={() =>
              onPaginationChanged(Math.ceil(totalRows / pageLength))
            }
          >
            <i className="fa fa-angle-double-right"></i>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </>
  );
};

export default CustomPagination;
