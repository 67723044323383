import React, { useState } from "react";
import { connect } from "react-redux";
import { changeUsersPassword } from "redux/actions/user.action";
import logo from "assets/images/everest-logo.png";

const ChangePasswordForm = ({ changeUsersPassword }) => {
  const [password, setPassword] = useState("");
  const [invalidPwdLengthMsg, setInvalidPwdLengthMsg] = useState("");
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");

  const [passwordsDoNotMatchMsg, setPasswordsDoNotMatchMsg] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const isPasswordLengthValid = (password) => {
    return password.length >= 10 && password.length <= 20;
  };

  const isPasswordValid = (password) => {
    const result = /^(?=.{10,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^*]).*$/.test(
      password,
    );
    return result;
  };

  const handleChangePasswordSubmit = (e) => {
    e.preventDefault();
    if (!isPasswordLengthValid(password)) {
      setInvalidPwdLengthMsg("Password must be between 10 and 20 characters.");
      return false;
    } else {
      setInvalidPwdLengthMsg("");
    }
    if (!isPasswordValid(password)) {
      setInvalidPasswordMsg(
        "Password must contain atleast: one [A-Z], one [a-z], one [0-9] and any one of !@#$%^*",
      );
      return false;
    } else {
      setInvalidPasswordMsg("");
    }
    if (password !== confirmPassword) {
      setPasswordsDoNotMatchMsg("Password and confirm password do not match.");
      return false;
    } else {
      setPasswordsDoNotMatchMsg("");
    }
    changeUsersPassword(token, password);
  };
  return (
    <form
      style={{ backgroundColor: "black" }}
      className="login-form"
      onSubmit={handleChangePasswordSubmit}
    >
      <div className="card mb-0 box-container no-border-radius">
        <div className="card-body">
          <div className="text-center">
            <img id="logo" src={logo} height="80" alt="" />
          </div>
          <h5 className="mb-0 ">Password recovery</h5>
          <span className="d-block text-muted">Change your password</span>
          <div className="form-group form-group-feedback form-group-feedback-right">
            <input
              id="password"
              name="password"
              type="password"
              className="form-control"
              placeholder="New password"
              // pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^*-]).{10,}$"
              onChange={(e) => setPassword(e.target.value)}
              title="Password length must be >= 10 and should contain atleast: one [A-Z], one [a-z], one [0-9] and one !@#$%^*"
            />
            <span className="d-block">
              <div style={{ color: "red" }}>{invalidPwdLengthMsg}</div>
            </span>
            <span className="d-block">
              <div style={{ color: "red" }}>{invalidPasswordMsg}</div>
            </span>
          </div>

          <div className="form-group form-group-feedback form-group-feedback-right">
            <input
              id="confirm-password"
              name="confirm-password"
              type="password"
              className="form-control"
              placeholder="Confirm password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span className="d-block">
              <div style={{ color: "red" }}>{passwordsDoNotMatchMsg}</div>
            </span>
          </div>
          <button
            id="changePassword"
            type="submit"
            className="btn bg-blue btn-block"
          >
            Change password
          </button>
        </div>
      </div>
    </form>
  );
};

export default connect(null, { changeUsersPassword })(ChangePasswordForm);
