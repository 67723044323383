import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getDefaultCarriers } from "redux/actions/carrier.action";
import { resetUpToDateFlag } from "redux/actions/global.action";

import {
  ContentHeader,
  AGTable,
  Content,
  Card,
  ActionAlerts,
} from "components";
import { carrierGrid } from "./tables/carrierGrid";
import { carrierDefaultGrid } from "./tables/carrierDefaultGrid";
import { carrier as c } from "config/constants";

const Carrier = ({ carrier, getDefaultCarriers, resetUpToDateFlag, user }) => {
  const [isVendor] = useState(user.user.isVendor());
  const [canAddCarrier] = useState(user.user?.vendor?.canAddCarrier);

  if (isVendor && !canAddCarrier) {
    carrierDefaultGrid.columnDefs = carrierDefaultGrid.columnDefs.filter(
      (res) => res.cellRenderer !== "EditDeleteButtons",
    );
  }

  useEffect(() => {
    if (!carrier.defaultCarriersUpToDate) {
      getDefaultCarriers();
    }
  }, [
    carrier.defaultCarriersUpToDate,
    carrier.nonDefaultCarriersUpToDate,
    getDefaultCarriers,
  ]);

  useEffect(() => {
    return () => {
      resetUpToDateFlag();
    };
  }, [resetUpToDateFlag]);

  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Carriers" />
      <Content>
        <Card title="Carriers">
          <ActionAlerts item={carrier} constants={c} />
          <AGTable
            tableName="carriers1"
            isClientFilter={true}
            gridConfig={carrierGrid}
            rowData={carrier.nonDefaultCarriers}
            isDoneLoading={carrier.defaultCarriersRetrieved}
            // action={getDefaultCarriers}
            // next={carrier.defaultNext}
            // previous={carrier.defaultPrevious}
            // totalRows={carrier.defaultTotalRows}
          />
        </Card>
        <Card title="Default Carriers">
          <AGTable
            tableName="carriers2"
            isClientFilter={true}
            gridConfig={carrierDefaultGrid}
            rowData={carrier.defaultCarriers}
            isDoneLoading={carrier.defaultCarriersRetrieved}
            // action={getDefaultCarriers}
            // next={carrier.defaultNext}
            // previous={carrier.defaultPrevious}
            // totalRows={carrier.defaultTotalRows}
          />
        </Card>
      </Content>
    </div>
  );
};

export default connect(({ carrier, user }) => ({ carrier, user }), {
  getDefaultCarriers,
  resetUpToDateFlag,
})(Carrier);
