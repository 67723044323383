import {
  // AsnVolumeColumnDefinition,
  CreatedAtColumnDefinition,
  AsnNumberColumnDefinition,
  WeightColumnDef,
  TotalCarton,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { AsnDetails } from "components/AGTable/NewColumns/AsnDetails";
import { GoToPoDetailFromAsn } from "components/AGTable/NewColumns/GoToPoDetailFromAsn";
import { RemoveASNFromShipmentTable } from "components/AGTable/NewColumns/RemoveASNFromShipmentTable";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const asnInShipmentGrid = {
  columnDefs: [
    RemoveASNFromShipmentTable,
    GoToPoDetailFromAsn,
    AsnDetails,
    AsnNumberColumnDefinition,
    CreatedAtColumnDefinition,
    // AsnVolumeColumnDefinition("volume"),
    TotalCarton,
    WeightColumnDef,
  ],
  defaultColDef,
};
