import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createShipmentEmail } from "redux/actions/client.action";

class ShipmentEmailCreateForm extends React.Component {
  state = this.initialState();
  updateEmailList(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }
  initialState() {
    return {
      data: {
        name: "",
        email: "",
        client: this.props.user.user.client.id,
      },
    };
  }
  closeModal() {
    this.closeButton.click();
    this.setState(this.initialState());
  }
  clearFields() {
    this.setState(this.initialState());
  }

  render() {
    const { data } = this.state;

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.createShipmentEmail(this.state.data);
            this.closeModal();
            this.clearFields();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputName">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  required
                  placeholder="Name"
                  value={data.name}
                  onChange={(e) => this.updateEmailList("name", e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  required
                  placeholder="Email"
                  value={data.email}
                  onChange={(e) =>
                    this.updateEmailList("email", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.clearFields()}
                data-dismiss="modal"
                ref={(button) => (this.closeButton = button)}
              >
                Close
              </button>
              <button id="submit-btn" type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user, client }) => ({ modal, user, client }),
  (dispatch) => bindActionCreators({ createShipmentEmail }, dispatch),
)(ShipmentEmailCreateForm);
