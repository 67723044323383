import {
  CreatedAtColumnDefinition,
  ActiveColumnDef,
  PhoneColumnDef,
  ContactColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const detailGrid = {
  columnDefs: [
    {
      headerName: "Vendor #",
      width: 105,
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "vendor_number",
    },
    ActiveColumnDef,
    ContactColumnDef,
    PhoneColumnDef,
    CreatedAtColumnDefinition,
  ],
  defaultColDef,
};
