import React from "react";
import { connect } from "react-redux";
import { ContentHeader, Content, ProfileForm, ActionAlerts } from "components";
import { users as c } from "config/constants";
import "./profile.css";

const Profile = ({ user }) => {
  return (
    <>
      <div className="content-wrapper">
        <ContentHeader
          pagename={user.user.first_name + " " + user.user.last_name}
          menuType="Basic"
        />
        <Content>
          <ActionAlerts item={user} constants={c} />
          <ProfileForm />
        </Content>
      </div>
    </>
  );
};

export default connect(({ user }) => ({ user }), {})(Profile);
