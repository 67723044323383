import { CreatedAtColumnDefinition } from "components/AGTable/NewColumns/ColumnDefinition";
const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};
export const clientGrid = {
  columnDefs: [
    {
      headerName: "Name",
      width: 200,
      cellStyle: {
        display: "flex",
        "justify-content": "left",
        "font-size": "14px",
      },
      field: "name",
    },
    CreatedAtColumnDefinition,
  ],

  defaultColDef,
};
