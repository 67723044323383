import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createCarton } from "redux/actions/carton.action";
import styles from "./CartonForm.module.css";
import ModalFooterAddClose from "../components/ModalFooterAddClose";

class CartonCreateForm extends React.Component {
  state = this.initialState();
  updateCarton(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }
  initialState() {
    return {
      data: {
        name: "",
        height: "",
        length: "",
        width: "",
        width_height_length_unit: "in",
        max_weight: "",
        weight_unit: "lb",
      },
    };
  }
  closeModal() {
    this.closeButton.click();
  }
  clearFields() {
    this.setState(this.initialState());
  }
  render() {
    const { data } = this.state;

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.createCarton(this.state.data);
            this.closeModal();
            this.clearFields();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-5">
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Name"
                  value={data.name}
                  onChange={(e) => this.updateCarton("name", e.target.value)}
                  maxLength="15"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className={`form-group col ${styles.horizontal}`}>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  className={`${styles["number-input"]} form-control`}
                  id="inputHeight"
                  placeholder="Height"
                  value={data.height}
                  onChange={(e) => this.updateCarton("height", e.target.value)}
                  required
                />
                <label htmlFor="inputHeight" className={styles.label}>
                  H
                </label>
                <span className={styles.multiplication}>x</span>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  className={`${styles["number-input"]} form-control`}
                  id="inputLength"
                  placeholder="Length"
                  value={data.length}
                  onChange={(e) => this.updateCarton("length", e.target.value)}
                  required
                />
                <label htmlFor="inputLength" className={styles.label}>
                  L
                </label>
                <span className={styles.multiplication}>x</span>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  className={`${styles["number-input"]} form-control`}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  id="inputWidth"
                  placeholder="Width"
                  value={data.width}
                  onChange={(e) => this.updateCarton("width", e.target.value)}
                  required
                />
                <label htmlFor="inputWidth" className={styles.label}>
                  W
                </label>
                <select
                  className="form-control"
                  id="inputUnitOfMeasurement"
                  style={{
                    width: "auto",
                    marginLeft: "2rem",
                  }}
                  value={data.width_height_length_unit}
                  onChange={(e) =>
                    this.updateCarton(
                      "width_height_length_unit",
                      e.target.value,
                    )
                  }
                  required
                >
                  {/* <option value="" disabled selected /> */}
                  {/* <option value="cm">cm</option> */}
                  <option value="in">in</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className={`form-group col ${styles.horizontal}`}>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  className={`${styles["number-input"]} form-control`}
                  id="inputWeight"
                  style={{
                    marginRight: "0.2rem",
                  }}
                  placeholder="Weight"
                  value={data.max_weight}
                  onChange={(e) =>
                    this.updateCarton("max_weight", e.target.value)
                  }
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  required
                />
                <select
                  className="form-control"
                  id="inputWeightUnit"
                  style={{
                    width: "auto",
                  }}
                  value={data.weight_unit}
                  onChange={(e) =>
                    this.updateCarton("weight_unit", e.target.value)
                  }
                  required
                >
                  {/* <option value="" disabled selected /> */}
                  {/* <option value="kg">kg</option> */}
                  <option value="lb">lb</option>
                </select>
              </div>
            </div>
            <ModalFooterAddClose
              inputRef={(button) => (this.closeButton = button)}
              onClick={() => this.clearFields()}
            />
          </div>
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user, carton }) => ({ modal, user, carton }),
  (dispatch) => bindActionCreators({ createCarton }, dispatch),
)(CartonCreateForm);
