import { UnlockUser } from "components/AGTable/NewColumns/UnlockUser";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },

  filter: true,
};

const columnDefs = [
  {
    headerName: "Email",
    width: 200,
    field: "email",
  },
  {
    headerName: "First Name",
    width: 115,
    field: "first_name",
  },
  {
    headerName: "Last Name",
    width: 115,
    field: "last_name",
  },
  UnlockUser,
];

export const LockedUsersGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
