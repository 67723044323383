const initialState = { added: false, loading: false };

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "RESET_ALERTS":
      return {
        ...state,
        error: "",
        added: false,
      };
    case "GENERATE_ASN_REPORT":
      return {
        ...state,
        loading: true,
      };
    case "GENERATE_ASN_REPORT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: "",
      };
    case "GENERATE_ASN_REPORT_FAILED":
      return {
        ...state,
        loading: false,
        added: false,
        error: action.error.error,
      };
    case "GENERATE_DATAEXTRACT_REPORT":
      return {
        ...state,
        loading: true,
      };
    case "GENERATE_DATAEXTRACT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: "",
      };
    case "GENERATE_DATAEXTRACT_FAILED":
      return {
        ...state,
        loading: false,
        added: false,
        error: action.error.error,
      };
    case "GENERATE_INTRANSIT_REPORT":
      return {
        ...state,
        loading: true,
      };
    case "GENERATE_INTRANSIT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: "",
      };
    case "GENERATE_INTRANSIT_FAILED":
      return {
        ...state,
        loading: false,
        added: false,
        error: action.error.error,
      };
    case "RESEND_ASN_FEED":
      return {
        ...state,
        loading: true,
      };
    case "RESEND_ASN_FEED_SUCCESS":
      return {
        ...state,
        loading: false,
        added: true,
        error: "",
      };
    case "RESEND_ASN_FEED_FAILED":
      return {
        ...state,
        loading: false,
        added: false,
        error: action.error.error,
      };
    default:
      return state;
  }
};
