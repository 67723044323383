import React from "react";
import BreadcrumbLink from "./components/BreadcrumbLink";

const cellRender = ({ data }) => {
  return (
    <BreadcrumbLink
      pathname={"/pos/" + data.po_number + "-" + data.id}
      breadcrumb={"Purchase Orders"}
      url={"pos"}
      field={data.po_number}
    />
  );
};

export const ManagePoViaPoNumber = {
  headerName: "PO #",
  field: "po_number",
  pinned: "left",
  cellRendererFramework: cellRender,
  width: 90,
};
