import React from "react";
import { connect } from "react-redux";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class UserIdleWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.props.resetIdleWarning();
  }

  render() {
    const { vendor_constant } = this.props;
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Idle Warning</ModalHeader>
          <ModalBody>{vendor_constant.constant_value}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Resume Activity
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Close Warning
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(({ constant }) => ({
  vendor_constant: constant.vendor_constants[3],
}))(UserIdleWarning);
