import React from "react";
import { connect } from "react-redux";

import { setSelectedDivision } from "redux/actions/email.action";
import { Card, AGTable } from "components";
import { emailGrid } from "../tables/emailGrid";

function mapStateToProps(state) {
  return {};
}

const EmailListCard = ({
  emails,
  emailsRetrieved,
  name,
  division,
  setSelectedDivision,
}) => {
  const title = division ? `Division ${name}` : `Group ${name}`;

  const handleClick = () => {
    setSelectedDivision(name);
  };

  return (
    <div style={{ marginRight: "20px" }} onClick={handleClick}>
      <Card title={title}>
        <AGTable
          tableName="emialListCard"
          isDoneLoading={emailsRetrieved}
          gridConfig={emailGrid}
          rowData={emails}
        />
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, { setSelectedDivision })(EmailListCard);
