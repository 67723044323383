import {
  NameColumnDef,
  EditDeleteColumnDef,
  EmailColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
  cellStyle: { display: "flex", "justify-content": "center" },
};

export const emailGrid = {
  columnDefs: [EditDeleteColumnDef, NameColumnDef, EmailColumnDef],
  defaultColDef,
};
