import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import { DashboardLayout } from "components";
import {
  logout,
  assignIdleWarning,
  resetIdleWarning,
  resetSessionExpiredWarning,
  assignSessionExpiredWarning,
  resetErrorResponseWarning,
} from "redux/actions/user.action";
// import {
//   getVendorSettings,
//   getDefaultSettings
// } from "redux/actions/setting.action"; COMMENT CODE 3112020-SETTINGS
import UserIdleWarning from "components/Modal/UserForms/UserIdleWarning";
import SessionExpiredWarning from "components/Modal/UserForms/SessionExpiredWarning";
import ErrorResponseWarning from "components/Modal/UserForms/ErrorResponseWarning";

class AuthRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
    };
  }

  inactivityTimeoutFn() {
    let lastActivityTime = Date.now();
    const { user } = this.props;

    function mouseEventHandler() {
      lastActivityTime = Date.now();
    }

    const checkIdleLength = () => {
      const { assignIdleWarning, setting } = this.props;

      const MAX_IDLE_TIME =
        setting.constantsUpToDate &&
        setting.vendor_constants.filter(
          (e) => e.setting.name === "MAX_IDLE_TIME",
        )[0];
      const GRACE_PERIOD =
        setting.constantsUpToDate &&
        setting.vendor_constants.filter(
          (e) => e.setting.name === "GRACE_PERIOD",
        )[0];

      let idleLength = Date.now() - lastActivityTime;

      if (MAX_IDLE_TIME) {
        let totalTime =
          parseInt(MAX_IDLE_TIME.constant_value) +
          parseInt(GRACE_PERIOD.constant_value);
        if (idleLength > totalTime) {
          assignSessionExpiredWarning();
        }
        if (idleLength > parseInt(MAX_IDLE_TIME.constant_value)) {
          assignIdleWarning();
        }
      }
    };

    document.addEventListener("mousemove", mouseEventHandler);

    let intervalId = setInterval(checkIdleLength, 5 * 1000);

    if (!user.isLoggedIn) clearInterval(intervalId);
    return [intervalId, mouseEventHandler];
  }

  unloadHandler = (ev) => {
    // ev.preventDefault();
    // Commenting this out temporarily so I dont get logged out every time I hit save.
    // this.props.logout();
  };

  componentDidMount() {
    // this.props.getDefaultSettings();COMMENT CODE 3112020-SETTINGS
    // this.props.getVendorSettings();
    this.props.resetIdleWarning();
    this.props.resetSessionExpiredWarning();
    this.props.resetErrorResponseWarning();
    let [intervalId, mouseEventHandler] = this.inactivityTimeoutFn();
    this.setState({ intervalId: intervalId });
    this.setState({ mouseEventHandler: mouseEventHandler });
    window.addEventListener("beforeunload", this.unloadHandler);
  }

  componentWillUnmount() {
    this.props.resetIdleWarning();
    this.props.resetSessionExpiredWarning();
    this.props.resetErrorResponseWarning();
    clearInterval(this.state.intervalId);
    document.removeEventListener("mousemove", this.state.mouseEventHandler);
    window.removeEventListener("beforeunload", this.unloadHandler);
  }

  render() {
    const { user, children, resetIdleWarning } = this.props;
    if (
      !user.isLoggedIn ||
      !user.user.getRoutes().includes(window.location.pathname.split("/")[1])
    )
      return <Redirect to="/" />;

    return (
      <>
        {user.idleWarning && (
          <UserIdleWarning resetIdleWarning={resetIdleWarning} />
        )}
        {user.isSessionExpired && (
          <SessionExpiredWarning
            resetSessionExpiredWarning={resetSessionExpiredWarning}
          />
        )}
        {user.hasErrorOccured && (
          <ErrorResponseWarning
            resetErrorResponseWarning={resetErrorResponseWarning}
          />
        )}
        {user.reminderTime && <ErrorResponseWarning idleTimer={true} />}
        <DashboardLayout>{children}</DashboardLayout>
      </>
    );
  }
}

export default connect(
  ({ user, setting }) => ({ user, setting }),
  (dispatch) =>
    bindActionCreators(
      {
        logout,
        assignIdleWarning,
        resetIdleWarning,
        assignSessionExpiredWarning,
        resetSessionExpiredWarning,
        resetErrorResponseWarning,
        // getDefaultSettings, COMMENT CODE 3112020-SETTINGS
        // getVendorSettings
      },
      dispatch,
    ),
)(AuthRoute);
