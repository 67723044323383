import React from "react";
import "./Footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div className="navbar navbar-expand-lg navbar-dark">
        <div className="text-center d-lg-none w-100">
          <button
            type="button"
            className="navbar-toggler dropdown-toggle"
            data-toggle="collapse"
            data-target="#navbar-footer"
          >
            <i className="icon-unfold mr-2" />
            Footer
          </button>
        </div>

        <div className="navbar-collapse collapse" id="navbar-footer">
          <span className="navbar-text">
            &copy; {new Date().getFullYear()}{" "}
            <a href="# ">Inbound ASN Management System</a> by&nbsp;
            <a
              href="http://www.everesttech.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Everest Technologies, Inc.
            </a>
          </span>

          <ul className="navbar-nav ml-lg-auto">
            <li className="nav-item">
              <a href="# " className="navbar-nav-link" target="_blank">
                <i className="icon-lifebuoy mr-2" /> Support
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default Footer;
