import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UncontrolledAlert } from "reactstrap";

import {
  getPo,
  unlockPo,
  unlockPoOnCancel,
  getPoLine,
} from "redux/actions/pos.action";
import { cancelEditAsn, startAsn, getASNs } from "redux/actions/asn.action";
import {
  AGTable,
  ContentHeader,
  VendorSetup,
  PageLoading,
  Card,
  ActionAlerts,
  ScrollManager,
} from "components";
import { poHeaderGrid } from "./tables/poHeaderGrid";
import { poClientHeaderGrid } from "./tables/poClientHeaderGrid";
import { asnsInShipmentsGrid } from "./tables/asnsInShipmentsGrid";
import { asnGrid } from "./tables/asnGrid";
import { editAsnGrid } from "./tables/editAsnGrid";
import EditAsn from "./components/EditAsn";
import CartonRedirectButton from "./components/CartonRedirectButton";
import PoAlerts from "./components/PoAlerts";
import PoLinesCard from "./components/PoLinesCard";
import { getAllCartons } from "redux/actions/carton.action";
import { getPos } from "redux/actions/pos.action";
import { getVendor } from "redux/actions/vendor.action";
import { pos as c } from "config/constants";
import styles from "components/Content/Content.module.css";

class ASN extends Component {
  state = {
    needsToMakeCarton: false,
  };

  componentDidMount() {
    this.getPo();
    this.props.getPoLine();
    const { carton, po, getAllCartons, getPos, user } = this.props;
    if (!carton.allCartonsUpToDate && user?.user?.isVendor()) {
      getAllCartons();
    }
    if (po.pos.length === 0) {
      getPos();
    }
  }
  startAsn() {
    const { selectedWarehouse } = this.state;
    const { currentPo } = this.props.po;
    this.props.startAsn(currentPo.id, selectedWarehouse);
  }

  componentWillUnmount() {
    if (this.props.asn.workingOnAsn) {
      this.props.cancelEditAsn(this.props.po.currentPo);
      this.props.unlockPoOnCancel(this.props.po.currentPo.id);
    } else {
      this.props.po.currentPo = { asns: [] };
    }
  }

  componentWillUpdate({ po, vendor, getVendor, user, asn, getASNs }) {
    if (user.user.isVendor()) {
      const vendorId = user.user.vendor.id;
      if (!vendor.currentVendorUpToDate) getVendor(vendorId);
    }

    if (po.currentPoHasChanged && asn.asnSaveChange) {
      this.getPo();
      this.props.getPoLine();
    }

    if (!asn.asnsUpToDate) {
      this.resetStartAsnButton();
      getASNs();
    }
  }

  getPo() {
    const poId = this.props.location.pathname.split("-")[1];
    if (poId) {
      this.props.getPo(poId);
    }
  }

  tellUserToMakeACarton() {
    this.setState({ needsToMakeCarton: true });
  }

  resetStartAsnButton() {
    this.setState({ selectedWarehouse: "" });
  }

  asnList(asns) {
    const asnLists = {
      editableASNs: [],
      asnsInShipments: [],
    };

    if (asns) {
      asns.forEach((asn) => {
        if (asn.shipment === null) {
          asnLists.editableASNs.push(asn);
        } else {
          asnLists.asnsInShipments.push(asn);
        }
      });
    }
    return asnLists;
  }

  selectWarehouse(e) {
    if (e.length) {
      this.setState({ selectedWarehouse: e && e[0].id });
    } else this.setState({ selectedWarehouse: "" });
  }

  render() {
    const {
      po,
      asn,
      user: { user },
      location,
      unlockPoOnCancel,
    } = this.props;
    const { needsToMakeCarton, selectedWarehouse } = this.state;
    const { currentPo } = po;
    const asns = this.asnList(currentPo.asns);
    const pathPoNumber = window.location.pathname.split("/")[2].split("-")[0];

    if (!currentPo || currentPo.po_number !== pathPoNumber)
      return (
        <div className="content-wrapper">
          <ContentHeader
            info={location.state}
            pagename="Purchase Order"
            menuType="Basic"
          />
          <PageLoading />
        </div>
      );

    if (needsToMakeCarton) {
      return <CartonRedirectButton info={location.state} />;
    }
    return (
      <>
        <div className="content-wrapper">
          <ContentHeader
            info={location.state}
            pagename="Purchase Order"
            menuType="Basic"
          />
          <ScrollManager>
            {({ connectScrollTarget }) => (
              <div className={styles.content} ref={connectScrollTarget}>
                <VendorSetup>
                  <PoAlerts
                    currentPo={currentPo}
                    unlockPo={unlockPoOnCancel}
                    user={user}
                  />

                  {/* PO HEADER GRID */}
                  {!asn.workingOnAsn && (
                    <>
                      <Card title="PO">
                        <AGTable
                          isClientFilter={true}
                          tableName="po1"
                          isDoneLoading={true}
                          gridConfig={
                            user && user.isClient()
                              ? poClientHeaderGrid
                              : poHeaderGrid
                          }
                          rowData={[currentPo]}
                        />
                        <br />
                      </Card>
                    </>
                  )}
                  {/* PO HEADER GRID */}

                  {/* ASN LIST GRID */}
                  {currentPo.asns.length > 0 && (
                    <>
                      <Card title="ASNs">
                        <ActionAlerts item={asn} constants={c} />

                        <AGTable
                          isClientFilter={true}
                          tableName="po2"
                          isDoneLoading={true}
                          paginate={true}
                          gridConfig={
                            currentPo.locked ||
                            asn.workingOnAsn ||
                            !user.isVendor()
                              ? asnGrid
                              : editAsnGrid
                          }
                          rowData={asns.editableASNs}
                        />
                        {asns.editableASNs.length > 0 && user.isVendor() && (
                          <small>
                            * ASNs with ignored shipment windows cannot be
                            edited.
                          </small>
                        )}
                        <br />
                      </Card>
                      <br />
                      {asns.asnsInShipments.length > 0 && (
                        <>
                          <Card title="ASNs in Shipments">
                            <AGTable
                              isClientFilter={true}
                              tableName="po3"
                              isDoneLoading={true}
                              paginate={true}
                              gridConfig={asnsInShipmentsGrid}
                              rowData={asns.asnsInShipments}
                            />
                          </Card>
                          <br />
                        </>
                      )}
                    </>
                  )}

                  {/* ASN LIST GRID */}

                  {asn.creatingAsnError && (
                    <UncontrolledAlert color="danger">
                      {c.asnCreateFailed}
                    </UncontrolledAlert>
                  )}
                  <PoLinesCard
                    selectedWarehouse={selectedWarehouse}
                    selectWarehouse={this.selectWarehouse.bind(this)}
                    startAsn={this.startAsn.bind(this)}
                    tellUserToMakeACarton={this.tellUserToMakeACarton.bind(
                      this,
                    )}
                  />
                  <br />

                  {/* Create ASN Section */}
                  {asn.workingOnAsn && !po.poLockError && (
                    <EditAsn
                      currentPo={currentPo}
                      warehouseId={this.state.selectedWarehouse}
                    />
                  )}
                  {/* Create ASN Section */}

                  <br />
                </VendorSetup>
              </div>
            )}
          </ScrollManager>
        </div>
      </>
    );
  }
}

export default connect(
  ({ po, asn, user, carton, vendor }) => ({ po, asn, user, carton, vendor }),
  (dispatch) =>
    bindActionCreators(
      {
        getPo,
        cancelEditAsn,
        getAllCartons,
        getPos,
        getPoLine,
        getASNs,
        getVendor,
        startAsn,
        unlockPo,
        unlockPoOnCancel,
      },
      dispatch,
    ),
)(ASN);
