import React, { useEffect } from "react";
import { connect } from "react-redux";
import { validatePasswordResetToken } from "redux/actions/user.action";
import "./RecoverPassForm.css";
import Loading from "../Loading";
import LinkExpired from "../LinkExpired";
import ChangePasswordForm from "../ChangePasswordForm";
import EnterEmailForm from "../EnterEmailForm";

const RecoverPassForm = ({
  loading,
  isPasswordResetTokenValid,
  validatePasswordResetToken,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const landedFromEmail = searchParams.has("token");
  const token = searchParams.get("token");
  useEffect(() => {
    if (landedFromEmail) {
      validatePasswordResetToken(token);
    }
  }, [landedFromEmail, token, validatePasswordResetToken]);
  if (loading) {
    return <Loading />;
  }
  if (!landedFromEmail) {
    return <EnterEmailForm />;
  } else if (isPasswordResetTokenValid) {
    return <ChangePasswordForm />;
  } else {
    return <LinkExpired />;
  }
};

export default connect(
  ({ user: { loading, isPasswordResetTokenValid } }) => ({
    loading,
    isPasswordResetTokenValid,
  }),
  {
    validatePasswordResetToken,
  },
)(RecoverPassForm);
