import React from "react";
import ActionIcon from "./components/ActionIcon";

const cellRender = ({ data, context }) => {
  return (
    <ActionIcon
      click={(e) => {
        e.preventDefault();
        context.componentParent.removeASNFromShipTable(data);
      }}
      icon={"icon-subtract"}
    />
  );
};

export const RemoveASNFromShipmentTable = {
  headerName: "Remove from Shipment",
  sortable: false,
  cellRendererFramework: cellRender,
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 185,
};
