import React from "react";
import Layout from "../Layout";

const Loading = () => (
  <Layout>
    <i className="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1 loading-spinner spin" />
    <p>Loading...</p>
  </Layout>
);

export default Loading;
