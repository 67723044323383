import React from "react";
import { connect } from "react-redux";
import { Button, AGTable, Card } from "components";
import { asnGrid } from "../tables/asnGrid";
import { startShipment } from "redux/actions/shipment.action";
import { getShippableASNs, getASNs } from "redux/actions/asn.action";

const VendorAsn = ({ asn, startShipment, getShippableASNs, getASNs }) => (
  <>
    <Card title="Active ASNs">
      <div style={{ display: "block" }}>
        <Button
          color="primary"
          btnSize="100px"
          onClick={(e) => {
            startShipment();
            getShippableASNs();
          }}
          style={{
            width: "fit-content",
            justifySelf: "left",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          Create Shipment
        </Button>
        <AGTable
          tableName="vendorAsn"
          paginate={true}
          gridConfig={asnGrid}
          isSearchEnable={true}
          isDoneLoading={asn.asnsRetrieved}
          action={getASNs}
          rowData={asn.asns}
          next={asn.next}
          previous={asn.previous}
          totalRows={asn.totalRows}
        />
      </div>
    </Card>
  </>
);

export default connect(({ asn, shipment }) => ({ asn, shipment }), {
  startShipment,
  getShippableASNs,
  getASNs,
})(VendorAsn);
