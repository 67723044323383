import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createClient } from "redux/actions/client.action";

class ClientModalForm extends React.Component {
  state = this.initialState();

  updateClient(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  clearFields() {
    this.setState(this.initialState());
  }

  closeModal() {
    this.closeButton.click();
  }
  handleSubmit() {
    this.props.createClient(this.state.data);
    this.clearFields();
    this.closeModal();
  }
  initialState() {
    return { data: { name: "" } };
  }

  render() {
    const { data } = this.state;

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="modal-body">
            <div className="col-md-10 mb-3">
              <label htmlFor="clientname">Client Name</label>
              <input
                type="text"
                className="form-control"
                id="clientname"
                placeholder="Client Name"
                pattern="[a-zA-Z0-9 ]*$"
                value={data.name}
                onChange={(e) => this.updateClient("name", e.target.value)}
                required
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.clearFields()}
              data-dismiss="modal"
              ref={(button) => (this.closeButton = button)}
            >
              Close
            </button>
            <button id="submit-btn" type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default connect(
  ({ client }) => ({ client }),
  (dispatch) => bindActionCreators({ createClient }, dispatch),
)(ClientModalForm);
