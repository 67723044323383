import React from "react";
import BreadcrumbLink from "./components/BreadcrumbLink";

const cellRender = ({ data }) => {
  return (
    <BreadcrumbLink
      pathname={"/pos/" + data.po_number + "-" + data.id}
      breadcrumb={"Purchase Orders"}
      url={"pos"}
    />
  );
};
export const ManagePo = {
  headerName: "Details",
  filter: null,
  field: "id",
  width: 85,
  sortable: false,
  cellRendererFramework: cellRender,
  pinned: "left",
};
