const initialState = {
  selectedData: {},
  modalType: "add",
  header: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        selectedData: {},
      };
    case "SET_MODAL_TYPE":
      return {
        ...state,
        modalType: action.data.modalType,
        header: action.data.header,
      };
    case "SELECT_DATA":
      return {
        ...state,
        selectedData: action.data,
      };
    case "CLEAR_DATA":
      return {
        ...state,
        selectedData: null,
      };
    default:
      return state;
  }
};
