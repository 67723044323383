import React from "react";
import { asnDetailGrid } from "./tables/asnDetailGrid";
import { AGTable } from "components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class AsnDetailView extends React.Component {
  render() {
    const {
      modal: { selectedData },
    } = this.props;
    return (
      <>
        <div className="modal-body">
          <AGTable
            isSearchWidth={true}
            tableName="asnDetailView"
            isDoneLoading={true}
            gridConfig={asnDetailGrid}
            rowData={selectedData.details}
          />
        </div>
      </>
    );
  }
}
export default connect(
  ({ modal }) => ({ modal }),
  (dispatch) => bindActionCreators({}, dispatch),
)(AsnDetailView);
