const initialState = {
  clients: [],
  emails: [],
  emailsUpToDate: false,
  emailsRetrieved: false,
  gettingClients: false,
  clientsUpToDate: false,
  deleted: false,
  updated: false,
  added: false,
  error: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "GET_CLIENTS":
      return {
        ...state,
        gettingClients: true,
        clientsRetrieved: false,
      };
    case "GET_CLIENTS_SUCCESS":
      return {
        ...state,
        clients: action.data.results,
        clientsRetrieved: true,
        clientsUpToDate: true,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_CLIENTS_FAILED":
      return {
        ...state,
        error: action.error,
      };

    case "GET_EMAILS":
      return {
        ...state,
        emailsRetrieved: false,
        emailsUpToDate: true,
      };
    case "GET_EMAILS_SUCCESS":
      return {
        ...state,
        emails: action.data,
        emailsRetrieved: true,
      };
    case "GET_EMAILS_FAILED":
      return {
        ...state,
        error: action.error,
        emailsRetrieved: false,
        emailsUpToDate: false,
      };
    case "ADD_EMAIL":
      return {
        ...state,
        added: false,
      };
    case "ADD_EMAIL_SUCCESS":
      return {
        ...state,
        added: true,
        emails: state.emails.concat([action.data]),
        emailsUpToDate: false,
      };
    case "ADD_EMAIL_FAILED":
      return {
        ...state,
        added: false,
        error: "Please enter a valid email address",
      };
    case "DELETE_EMAIL":
      return {
        ...state,
        deleted: false,
      };
    case "DELETE_EMAIL_SUCCESS":
      return {
        ...state,
        deleted: true,
        emails: deleteEmail(action.data, state.emails),
        emailsUpToDate: false,
      };
    case "DELETE_EMAIL_FAILED":
      return {
        ...state,
        error: action.error,
        deleted: false,
      };
    case "UPDATE_EMAIL":
      return {
        ...state,
        updated: false,
      };
    case "UPDATE_EMAIL_SUCCESS":
      return {
        ...state,
        updated: true,
        emails: updateEmail(action.data, state.emails),
        emailsUpToDate: false,
      };
    case "UPDATE_EMAIL_FAILED":
      return {
        ...state,
        error: action.error,
        updated: false,
      };
    case "RESET_UPTODATE_FLAG":
      return {
        ...state,
        clientsUpToDate: false,
        emailsUpToDate: false,
      };
    case "ADD_CLIENT":
      return {
        ...state,
        added: false,
      };
    case "ADD_CLIENT_SUCCESS":
      return {
        ...state,
        clients: action.data.results,
        added: true,
        clientsUpToDate: false,
      };
    case "ADD_CLIENT_FAILED":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
const deleteEmail = (id, emails) => emails.filter((c) => c.id !== id);
const updateEmail = (email, emails) =>
  emails.map((c) => (c = c.id === email.id ? email : c));
