import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Collapse, CardTitle, CardBody, Card } from "reactstrap";

/**
 * @param {string}   Title Displays a title for the card.
 */

class CardCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: true };
  }

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  icon() {
    return this.state.collapse
      ? "fa fa-angle-up fa-2x"
      : "fa fa-angle-down fa-2x";
  }
  render() {
    const { children, title, collapsible = false } = this.props;
    const divStyle = {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginBottom: "-5px",
    };
    return (
      <>
        <Card className="cardStyle">
          <div>
            <CardTitle style={divStyle}>
              <h3 style={{ paddingRight: "20px" }}>{title}</h3>
              {collapsible && (
                <a
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggle();
                  }}
                >
                  <i className={this.icon()} />
                </a>
              )}
            </CardTitle>
          </div>

          <Collapse isOpen={this.state.collapse}>
            <CardBody className="cardBodyStyle">{children}</CardBody>
          </Collapse>
        </Card>
      </>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({}, dispatch),
)(CardCollapse);
