import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "reactstrap";

import "./ConfirmBox.css";

class ConfirmBox extends React.Component {
  state = {};
  startTimer() {
    this.timer = setTimeout(
      () => {
        this.setState({ showConfirm: true });
        setTimeout(() => {
          if (this.state.showConfirm) {
            this.setState({ showConfirm: false });
            this.props.timedOut();
          }
        }, 10 * 1000);
      },
      60 * 60 * 1000,
    );
  }

  clearTimer() {
    clearTimeout(this.timer);
  }
  render() {
    const {
      onClick,
      runTimerWhile,
      msg = "Are you still working on asn?",
    } = this.props;

    this.clearTimer();
    if (runTimerWhile) this.startTimer();

    return this.state.showConfirm ? (
      <>
        <div
          className="confirm-box"
          onClick={() =>
            this.setState({ showConfirm: false }) && onClick && onClick()
          }
        >
          <div className="confirm-box-contents">
            <div className="title">{msg}</div>
            <div className="buttons">
              <Button
                onClick={() =>
                  this.setState({ showConfirm: false }) && onClick && onClick()
                }
                color="primary"
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({}, dispatch),
)(ConfirmBox);
