import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateShipmentEmail } from "redux/actions/client.action";
import ModalFooterUpdateClose from "../components/ModalFooterUpdateClose";

class ShipmentEmailUpdateForm extends React.Component {
  state = {
    data: {},
  };
  updateShipmentEmail(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }
  closeModal() {
    this.closeButton.click();
  }
  render() {
    const {
      modal: { selectedData },
    } = this.props;

    // eslint-disable-next-line
    this.state.data.id = selectedData.id;

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.updateShipmentEmail(this.state.data);
            this.closeModal();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  required
                  className="form-control"
                  id="inputName"
                  placeholder="Name"
                  defaultValue={selectedData.name}
                  onChange={(e) =>
                    this.updateShipmentEmail("name", e.target.value)
                  }
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  placeholder="Email"
                  defaultValue={selectedData.email}
                  onChange={(e) =>
                    this.updateShipmentEmail("email", e.target.value)
                  }
                />
              </div>
            </div>

            <ModalFooterUpdateClose
              inputRef={(button) => (this.closeButton = button)}
            />
          </div>
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user }) => ({ modal, user }),
  (dispatch) => bindActionCreators({ updateShipmentEmail }, dispatch),
)(ShipmentEmailUpdateForm);
