import {
  CreatedAtColumnDefinition,
  FlaggedColumnDefinition,
  ShipToStoreColumnDefinition,
  VendorColumnDef,
  PoNumberColumnDef,
  DivisionColumnDef,
  SizeColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import config from "config/config";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
};

const columnDefs = [
  VendorColumnDef("vendor"),
  PoNumberColumnDef("po_number"),
  ShipToStoreColumnDefinition,
  {
    headerName: "# of ASNs",
    cellStyle: { display: "flex", "justify-content": "center" },
    field: "asns.length",
    width: 105,
  },
  CreatedAtColumnDefinition,
  {
    headerName: "Order Date",
    cellStyle: { display: "flex", "justify-content": "center" },
    field: "order_date",
    width: 105,
    cellClass: "ui-grid-cell-contents-auto",
  },
  DivisionColumnDef,
  SizeColumnDef,
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(9, 0, FlaggedColumnDefinition);

export const poClientHeaderGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
