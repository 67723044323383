import React from "react";
import { Link } from "react-router-dom";

const Succeeded = () => {
  return (
    <>
      <p>We were unable to reset your password.</p>
      <Link to="/">Go back to Login</Link>
    </>
  );
};

export default Succeeded;
