import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout, resetErrorResponseWarning } from "redux/actions/user.action";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ErrorResponseWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.props.resetErrorResponseWarning();
  }

  render() {
    const { idleTimer } = this.props;

    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {idleTimer ? "Attention" : "An error has occurred."}
          </ModalHeader>
          <ModalBody>
            {idleTimer
              ? `You are about to be signed out due to inactivity. Would you like to stay logged in?`
              : "If this issue persists, please contact support."}
          </ModalBody>
          <ModalFooter>
            {idleTimer ? (
              <div>
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => {
                    this.props.logout();
                  }}
                >
                  Log out now
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.props.resetErrorResponseWarning();
                  }}
                >
                  Stay logged in
                </Button>
              </div>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  this.props.resetErrorResponseWarning();
                }}
              >
                OK
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(
  ({ user }) => ({ user }),
  (dispatch) =>
    bindActionCreators({ resetErrorResponseWarning, logout }, dispatch),
)(ErrorResponseWarning);
