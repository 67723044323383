import React from "react";
import BreadcrumbLink from "./components/BreadcrumbLink";

const cellRender = ({ data }) => {
  const { po_number, id } = data.po;
  const poId = po_number ? id : "";
  return (
    <BreadcrumbLink
      pathname={"/pos/" + po_number + "-" + poId}
      breadcrumb={"Purchase Orders"}
      url={"pos"}
      field={po_number}
    />
  );
};
export const GoToPoDetailFromAsn = {
  headerName: "PO #",
  filter: null,
  field: "po.po_number",
  width: 90,
  cellRendererFramework: cellRender,
};
