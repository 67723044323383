import {
  NameColumnDef,
  EditDeleteColumnDef,
  NumberCellStyle,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const cartonGrid = {
  columnDefs: [
    EditDeleteColumnDef,
    NameColumnDef,
    {
      headerName: "Length",
      width: 85,
      cellStyle: { display: "flex", "justify-content": "center" },
      cellRenderer: (params) => {
        return params.data.length + " " + params.data.width_height_length_unit;
      },
    },
    {
      headerName: "Width",
      width: 85,
      cellStyle: { display: "flex", "justify-content": "center" },
      cellRenderer: (params) => {
        return params.data.width + " " + params.data.width_height_length_unit;
      },
    },
    {
      headerName: "Height",
      width: 85,
      cellStyle: { display: "flex", "justify-content": "center" },
      cellRenderer: (params) => {
        return params.data.height + " " + params.data.width_height_length_unit;
      },
    },
    {
      headerName: "Wt (lbs)",
      width: 85,
      cellStyle: NumberCellStyle,
      cellRenderer: (params) => {
        return params.data.max_weight;
      },
    },
    {
      headerName: "Vol (ft³)",
      cellStyle: NumberCellStyle,
      width: 105,
      field: "volume",
    },
  ],
  defaultColDef,
};
