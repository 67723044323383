import {
  CreatedAtColumnDefinition,
  PoNumberColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
};

export const logGrid = {
  columnDefs: [
    {
      headerName: "Message",
      field: "msg",
      width: 362,
    },
    CreatedAtColumnDefinition,
    PoNumberColumnDef("po_number"),
    {
      headerName: "PO ID",
      width: 110,
      field: "po",
      cellStyle: { display: "flex", "justify-content": "center" },
    },
    {
      headerName: "PO Line ID",
      field: "po_line",
      width: 110,
      cellStyle: { display: "flex", "justify-content": "center" },
    },
    {
      headerName: "PO Component ID",
      field: "po_component",
      width: 145,
      cellStyle: { display: "flex", "justify-content": "center" },
    },
  ],
  defaultColDef,
};
