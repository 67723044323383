const initialState = {
  logs: [],
  userLogs: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "GET_LOGS":
      return {
        ...state,
        gettingLogs: true,
      };
    case "GET_LOGS_SUCCESS":
      return {
        ...state,
        logs: action.data.results,
      };
    case "GET_LOGS_FAILED":
      return {
        ...state,
        error: action.data,
        gettingLogs: false,
      };
    case "GET_USER_LOGS":
      return {
        ...state,
        gettingUserLogs: true,
      };
    case "GET_USER_LOGS_SUCCESS":
      return {
        ...state,
        userLogs: action.data.results,
      };
    case "GET_USER_LOGS_FAILED":
      return {
        ...state,
        error: action.data,
        gettingUserLogs: false,
      };
    default:
      return state;
  }
};
