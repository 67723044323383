import React from "react";
import { Button } from "components";

class UnlockButton extends React.Component {
  state = { show: true };
  handleClick() {
    const { data } = this.props;
    this.props.context.componentParent.unlockPo(data);
    this.setState({ show: false });
  }
  render() {
    const { value } = this.props;
    const containerStyles = {
      display: "flex",
      height: "100%",
      alignItems: "center",
    };
    if (!this.state.show || value !== "locked") {
      return null;
    }
    return (
      <div style={containerStyles}>
        <Button
          toggleLoader={this.unlocking}
          type="button"
          className="btn btn-danger btn-sm"
          onClick={this.handleClick.bind(this)}
        >
          Locked
        </Button>
      </div>
    );
  }
}
export default UnlockButton;
