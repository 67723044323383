import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateProfile } from "redux/actions/user.action";

import "./ProfileForm.css";
import { Link } from "react-router-dom";

class ProfileForm extends React.Component {
  state = {
    data: {
      id: this.props.user.user.id,
    },
  };

  updateUser(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  handleSubmit() {
    const { data } = this.state;
    this.props.updateProfile(data);
  }
  render() {
    const { user } = this.props;
    return (
      <div className="row">
        <div className="col-md-9 personal-info">
          <form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
          >
            <div className="form-group">
              <label className="col-lg-3 control-label">First name:</label>
              <div className="col-lg-8">
                <input
                  className="form-control"
                  type="text"
                  defaultValue={user.user.first_name}
                  onKeyUp={(e) => this.updateUser("first_name", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-lg-3 control-label">Last name:</label>
              <div className="col-lg-8">
                <input
                  className="form-control"
                  type="text"
                  defaultValue={user.user.last_name}
                  onKeyUp={(e) => this.updateUser("last_name", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-lg-3 control-label">Email:</label>
              <div className="col-lg-8">
                <input
                  className="form-control"
                  type="email"
                  defaultValue={user.user.email}
                  onKeyUp={(e) => this.updateUser("email", e.target.value)}
                />
              </div>
            </div>
            <div className="col">
              <Link to="/recover" className="btn btn-primary">
                Change Password
              </Link>
            </div>
            <p className="col">
              Your current password is going to expire on:{" "}
              <span>{new Date(user.user.pw_expire).toLocaleString()}</span>
            </p>
            <div className="form-group">
              <label className="col-md-3 control-label" />
              <div className="col-md-8">
                <button type="submit" className="btn btn-primary btn-save">
                  Save
                </button>
                <span />
                <input
                  type="reset"
                  className="btn btn-default"
                  value="Cancel"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default connect(
  ({ user }) => ({ user }),
  (dispatch) => bindActionCreators({ updateProfile }, dispatch),
)(ProfileForm);
