import Service from "./service";
import { resetAlerts } from "./global.action";

export const startShipment = () => (dispatch) => {
  dispatch({ type: "START_SHIPMENT" });
};

export const cancelShipment = () => (dispatch) => {
  dispatch({ type: "CANCEL_SHIPMENT" });
};

export const addASNToShipment = (data) => (dispatch) => {
  dispatch({ type: "ADD_ASN_TO_SHIPMENT", data });
};

export const removeASNFromShipment = (data) => (dispatch) => {
  dispatch({ type: "REMOVE_ASN_FROM_SHIPMENT", data });
};

export const setShipToStore = (data) => (dispatch) => {
  dispatch({ type: "SET_SHIP_TO_STORE", data });
};

export const setShipFromWarehouse = (data) => (dispatch) => {
  dispatch({ type: "SET_SHIP_FROM_WAREHOUSE", data });
};

export const setLoadType = (data) => (dispatch) => {
  dispatch({ type: "SET_LOAD_TYPE", data });
};
export const setQuantity = (data) => (dispatch) => {
  dispatch({ type: "SET_QUANTITY", data });
};
export const setTotalCarton = (data) => (dispatch) => {
  dispatch({ type: "SET_TOTAL_CARTON", data });
};

export const saveShipment = (data) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "shipment/shipments", data, {
    init: "SAVE_SHIPMENT",
    success: "SAVE_SHIPMENT_SUCCESS",
    error: "SAVE_SHIPMENT_FAILED",
  });
};

export const deleteShipment = (id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.delete(dispatch, `shipment/shipments/${id}`, {
    init: "DELETE_SHIPMENT",
    success: "DELETE_SHIPMENT_SUCCESS",
    error: "DELETE_SHIPMENT_FAILED",
  });
};

export const getShipments = (queryParam) => (dispatch) => {
  Service.get(dispatch, "shipment/shipments", queryParam || {}, {
    init: "GET_SHIPMENTS",
    success: "GET_SHIPMENTS_SUCCESS",
    error: "GET_SHIPMENTS_FAILED",
  });
};

export const getShipment = (id) => (dispatch) => {
  Service.get(dispatch, `shipment/shipments/${id}`, {
    init: "GET_SHIPMENT",
    success: "GET_SHIPMENT_SUCCESS",
    error: "GET_SHIPMENT_FAILED",
  });
};

export const updateShipmentStatus = (data) => (dispatch) => {
  resetAlerts()(dispatch);
  const { comment, status } = data;
  if (!comment && !status) return;
  Service.patch(
    dispatch,
    `shipment/shipments/${data.id}`,
    {
      comment,
      status,
    },
    {
      init: "UPDATE_SHIPMENT_STATUS",
      success: "UPDATE_SHIPMENT_STATUS_SUCCESS",
      error: "UPDATE_SHIPMENT_STATUS_FAILED",
    },
  );
};
