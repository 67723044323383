import React from "react";

const ModalFooterAddClose = ({ inputRef, onClick }) => (
  <div className="modal-footer">
    <button
      ref={inputRef}
      type="button"
      className="btn btn-secondary"
      onClick={onClick}
      data-dismiss="modal"
    >
      Close
    </button>
    <button id="submit-btn" type="submit" className="btn btn-primary">
      Add
    </button>
  </div>
);

export default ModalFooterAddClose;
