const initialState = {
  roles: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "GET_ROLES":
      return {
        ...state,
        gettingRoles: true,
      };
    case "GET_ROLES_SUCCESS":
      return {
        ...state,
        roles: action.data,
      };
    case "GET_ROLES_FAILED":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
