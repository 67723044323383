import React from "react";

const Layout = ({ children }) => (
  <div className="card mb-0">
    <div className="card-body">
      <div className="text-center mb-3">{children}</div>
    </div>
  </div>
);

export default Layout;
