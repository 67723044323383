const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
};

export const userLogsGrid = {
  columnDefs: [
    {
      headerName: "Log ID",
      field: "id",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 65,
    },
    {
      headerName: "User ID",
      width: 75,
      field: "user",
      cellStyle: { display: "flex", "justify-content": "center" },
    },
    {
      headerName: "Created At",
      field: "created_at",
      width: 140,
      cellStyle: { display: "flex", "justify-content": "center" },
    },
    {
      headerName: "Activity",
      field: "activity",
      width: 155,
      cellStyle: { display: "flex", "justify-content": "center" },
    },
    {
      headerName: "Description",
      field: "description",
      width: 945,
    },
  ],
  defaultColDef,
};
