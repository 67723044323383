/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { RemoveLineFromAsn } from "components/AGTable/NewColumns/RemoveLineFromAsn";
import { RemainingQtyColumnDef } from "components/AGTable/NewColumns/ColumnDefinition";

const CellRendererFramework = ({ data }) => {
  const [quantity, setQuantity] = useState(0);
  let totalQuantity = data?.poLine?.remaining_qty;
  let asnQty = data?.asnDetail?.quantity;

  const changeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  useEffect(() => {
    setQuantity(asnQty || totalQuantity);
  }, []);

  const updateQuantity = (d, e) => {
    d.updateDetail("quantity", +quantity);
  };

  return (
    <input
      type="number"
      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
      min="0"
      className={`form-control height-22 coustom-w-80 ${totalQuantity < quantity ? "border-red" : ""}`}
      value={quantity}
      onKeyUp={(e) => updateQuantity(data, e)}
      onChange={changeQuantity}
    />
  );
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
};

export const createAsnDetailGrid = {
  columnDefs: [
    RemoveLineFromAsn,
    {
      headerName: "Description",
      field: "poLine.description",
      width: 155,
    },
    // I think they want to remove this, but I'm not 100% sure.
    // {
    //   headerName: "SKU",
    //   cellStyle: { display: "flex", "justify-content": "center" },
    //   sort: "desc",
    //   field: "poLine.sku",
    //   width: 200,
    // },
    {
      headerName: "Vendor Style",
      cellStyle: { display: "flex", "justify-content": "center" },

      field: "poLine.vendor_style",
      width: 125,
    },
    {
      headerName: "Vendor SKU",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.vendor_sku",
      width: 125,
    },

    RemainingQtyColumnDef({
      field: "poLine.remaining_qty",
      colId: "remaining_qty",
    }),
    // SelectCarton,
    // TotalCartons,
    {
      headerName: "Quantity",
      cellStyle: { display: "flex", "justify-content": "center" },
      sortable: false,
      cellRendererFramework: CellRendererFramework,
      width: 200,
    },
  ],
  defaultColDef,
};
