export default class PoDetail {
  constructor(poLine, detail = {}) {
    this.id = poLine.id;
    this.poLine = poLine;
    this.asnDetail = detail;
  }

  data() {
    return Object.assign(this.poLine, this.asnDetail);
  }

  getPoLineId() {
    return this.poLine.id;
  }

  setAsn(asnId) {
    this.asnDetail.asn = asnId;
  }

  setIsPartial(partial) {
    this.asnDetail.partial = partial;
  }

  getPoNumber() {
    return this.poLine.line.split("-")[0];
  }

  getSku() {
    return this.poLine.sku;
  }

  isPartial() {
    return this.asnDetail.partial;
  }
  getAsnDetail() {
    const obj = Object.assign(this.asnDetail, {
      po_line: this.poLine.id,
      remaining_qty: this.poLine?.remaining_qty,
      end_barcode:
        Number(this.asnDetail.start_barcode) +
        Number(this.asnDetail.total_cartons) -
        1,
    });

    return obj;
  }

  updateDetail(key, val) {
    this.asnDetail[key] = val;
  }

  getFlowQty() {
    return this.poLine.flow_qty;
  }

  getPackQty() {
    return this.poLine.pack_qty;
  }

  getStartBarcode() {
    return this.asnDetail.start_barcode;
  }

  getTotalCartons() {
    return this.asnDetail.total_cartons;
  }

  getBundlesPerCarton() {
    return this.asnDetail.bundles_per_carton;
  }

  getTotalQty() {
    return this.poLine.pack_qty * this.poLine.flow_qty;
  }

  getRemainingQty() {
    return this.poLine.remaining_qty;
  }

  canCreatePartial() {
    return !this.hasPartial && !this.isPartial();
  }

  removePartial() {
    this.hasPartial = false;
  }

  canAddToAsn(cancelDate) {
    return !this.isAddedToAsn && cancelDate === this.poLine.cancel_date;
  }

  addToAsn() {
    this.isAddedToAsn = true;
  }
  addAsnDetail(asnDetail) {
    this.asnDetail = asnDetail;
  }

  removeFromAsn() {
    this.removePartial();
    this.isAddedToAsn = false;
  }

  reset() {
    this.isAddedToAsn = false;
    this.hasPartial = false;
    this.asnDetail = {};
  }

  getCancelDate() {
    return this.poLine.cancel_date;
  }

  setIsNew() {
    this.new = true;
  }
  isNew() {
    return this.new;
  }
}
