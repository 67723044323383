import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login } from "redux/actions/user.action";
import logo from "assets/images/everest-logo.png";
import "./LoginForm.css";
import { Link, Redirect } from "react-router-dom";
import { login as constants } from "config/constants";

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
  };
  login(e) {
    this.props.login(this.state);
    e.preventDefault();
  }

  render() {
    const { user } = this.props;

    if (user.isLoggedIn === true) {
      return <Redirect to="/home" />;
    } else if (user.loginErrorRedirect) {
      return <Redirect to={user.loginErrorRedirect} />;
    } else {
      return (
        <form className="login-form" onSubmit={(e) => this.login(e)}>
          <div className="card mb-0 box-container">
            <div className="card-body">
              <div className="text-center mb-3">
                <img id="logo" src={logo} height="80" alt="" />
                {/* <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" /> */}
                <h6>Enter your credentials below</h6>
                <span className="d-block">
                  {user.loginError && (
                    <div style={{ color: "red" }}>{user.loginError}</div>
                  )}
                </span>
              </div>

              <div className="form-group form-group-feedback form-group-feedback-left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  required
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
                <div className="form-control-feedback">
                  <i className="icon-user text-muted" />
                </div>
              </div>

              <div className="form-group form-group-feedback form-group-feedback-left">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
                <div className="form-control-feedback">
                  <i className="icon-lock2 text-muted" />
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={user.loggingIn}
                >
                  {user.loggingIn ? (
                    <Fragment>
                      <i className="icon-spinner3 loading-spinner spin" />
                      <span> Processing</span>
                    </Fragment>
                  ) : (
                    <span>
                      Sign in <i className="icon-circle-right2 ml-2" />
                    </span>
                  )}
                </button>
              </div>

              <div className="text-center">
                <Link to="/recover">{constants.forgotPassword}</Link>
              </div>
            </div>
          </div>
        </form>
      );
    }
  }
}
export default connect(
  ({ user }) => ({ user }),
  (dispatch) => bindActionCreators({ login }, dispatch),
)(LoginForm);
