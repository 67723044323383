import React, { Component } from "react";
import { Card, Button, AGTable } from "components";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVendor } from "redux/actions/vendor.action";
import { lockPo, getPoLine, getPo } from "redux/actions/pos.action";
import { addAllLinesToAsn } from "redux/actions/asn.action";
import { poLineGrid } from "../tables/poLineGrid";
import { poLineClientGrid } from "../tables/poLineClientGrid";
import { poLineCreateAsnGrid } from "../tables/poLineCreateAsnGrid";

class PoLinesCard extends Component {
  state = {
    result: null,
  };

  componentWillMount() {
    const { user, vendor, getVendor } = this.props;
    if (user.user.isVendor() && !vendor.warehousesUpToDate) {
      getVendor(user.user.vendor.id);
    }
  }

  getPolines(poLine, po) {
    const selectLines = new Set(this.props.asn.lines.map((e) => e?.id));
    const activePoLines = poLine
      .filter((line) => line?.poLine?.active)
      .map((line) => {
        if (selectLines.has(line?.id)) {
          line.isAddedToAsn = true;
          line.new = true;
        }
        return this.insertPoDetailsToPoLine(line, po);
      });

    return po.active ? activePoLines : poLine;
  }

  insertPoDetailsToPoLines(lines, po) {
    lines.vendor_name = po.vendor_name;
    lines.ware_house = po.destination_name;
    lines.po_number = po.po_number;
    lines.Asn = this.asnCreatedSkus(po.asns).has(lines.sku) ? "yes" : "";
    return lines;
  }

  insertPoDetailsToPoLine(lines, po) {
    lines.poLine.vendor_name = po.vendor_name;
    lines.poLine.ware_house = po.destination_name;
    lines.poLine.po_number = po.po_number;
    lines.poLine.Asn = this.asnCreatedSkus(po.asns).has(lines.poLine.sku)
      ? "yes"
      : "";
    return lines;
  }

  asnCreatedSkus(asns) {
    let sku_list = new Set();
    asns.forEach((asn) => {
      asn.details.forEach((detail) => {
        sku_list.add(detail.po_line.sku);
      });
    });
    return sku_list;
  }

  async handleAddAll() {
    const addLineToAsn = this.props.po.allPoLine.filter(
      (item) =>
        item?.poLine?.remaining_qty > 0 && item?.poLine?.is_within_ship_window,
    );

    this.props.addAllLinesToAsn(addLineToAsn);
  }

  doesVendorHaveCartons() {
    return this.props.carton.allCartons.length > 0;
  }

  render() {
    const {
      po,
      asn,
      vendor,
      user: { user },
      selectWarehouse,
      startAsn,
      selectedWarehouse,
    } = this.props;
    const { currentPo } = po;
    const { warehouses } = vendor.currentVendor;

    // Define condition variables to make the code more readable
    const isPoLocked = currentPo.locked;
    const isVendor = user.isVendor();
    const isWorkingOnAsn = asn.workingOnAsn;
    const hasRemainingQty = currentPo.remaining_qty > 0;
    const canCreateAsn = po?.currentPo?.can_create_asn;
    const isSelectedWarehouse = Boolean(selectedWarehouse);
    const isPoActive = currentPo.active !== false;

    const showCreateAsnButton = !isPoLocked && isVendor && !isWorkingOnAsn;

    const createAsnDisabled =
      isWorkingOnAsn || !isPoActive || !isSelectedWarehouse || !hasRemainingQty;

    return (
      <Card title="PO Lines">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {showCreateAsnButton && (
            <div>
              {hasRemainingQty ? (
                canCreateAsn ? (
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      marginBottom: "10px",
                    }}
                  >
                    <Typeahead
                      className="mt-2 mr-1"
                      id="warehouse"
                      labelKey="name"
                      multiple={false}
                      options={warehouses}
                      placeholder="SELECT A WAREHOUSE"
                      onChange={selectWarehouse}
                    />
                    <Button
                      style={{
                        marginTop: "10px",
                        border: "0",
                        height: "35px",
                        marginRight: "8px",
                      }}
                      className="px-3"
                      color={isWorkingOnAsn ? "info" : "primary"}
                      onClick={(e) => {
                        e.preventDefault();
                        startAsn();
                        this.props.lockPo(currentPo.id);
                      }}
                      disabled={createAsnDisabled}
                      toggleLoader={!asn.currentAsn}
                      show={!isWorkingOnAsn}
                    >
                      Create ASN
                    </Button>
                  </div>
                ) : (
                  <p className="mb-3 my-3 error-color">
                    You may be trying to schedule your shipment before the
                    purchase order ship date or too close to the cancellation
                    date. Please review the dates listed and contact your buyer
                    if these dates need to be adjusted.
                  </p>
                )
              ) : (
                <p className="mb-3 my-3 error-color">
                  No remaining quantity for this PO.
                </p>
              )}
            </div>
          )}
          <Button
            style={{
              marginTop: "10px",
              border: "0",
              height: "35px",
              width: "80px",
              marginBottom: "8px",
            }}
            show={asn.workingOnAsn}
            color={"primary"}
            onClick={() => this.handleAddAll()}
          >
            Add all
          </Button>
        </div>

        <AGTable
          tableName="poLineCard"
          noMultiSortKey={true}
          coustomPagination={true}
          isDoneLoading={po.posRetrieved}
          paginate={true}
          isPerPage={true}
          gridConfig={
            asn.workingOnAsn && !po.poLockError
              ? poLineCreateAsnGrid
              : user.isVendor()
                ? poLineGrid
                : poLineClientGrid
          }
          rowData={this.getPolines(po.lines, currentPo)}
          action={this.props.getPoLine}
          next={po.next}
          previous={po.previous}
          totalRows={po.totalRows}
        />
        <br />
      </Card>
    );
  }
}

export default connect(
  ({ po, asn, user, carton, vendor }) => ({ po, asn, user, carton, vendor }),
  (dispatch) =>
    bindActionCreators(
      { getPoLine, lockPo, getVendor, addAllLinesToAsn, getPo },
      dispatch,
    ),
)(PoLinesCard);
