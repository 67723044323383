import React from "react";
import { connect } from "react-redux";
import "./EditDeleteButtons.css";
import OpenModalIcon from "../NewColumns/components/OpenModalIcon";

class EditDeleteButtons extends React.Component {
  render() {
    const { data, user } = this.props;
    return (
      <>
        <OpenModalIcon
          click={() =>
            this.props.context.componentParent.updateData(data, "update")
          }
          icon={"icon-pencil"}
        />
        &nbsp;
        {(!data?.last_login ||
          (data?.last_login && data?.email !== user?.user?.email)) && (
          <OpenModalIcon
            click={() =>
              this.props.context.componentParent.updateData(data, "delete")
            }
            icon={"icon-trash"}
          />
        )}
      </>
    );
  }
}
export default connect(({ user }) => ({ user }))(EditDeleteButtons);
