import {
  NameColumnDef,
  AddressColumnDef,
  PickUpWindowColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },
};

export const carrierGrid = {
  columnDefs: [
    NameColumnDef,
    AddressColumnDef,
    PickUpWindowColumnDef,
    {
      headerName: "Pick Carrier",
      cellRenderer: "SelectCarrier",
      width: 150,
    },
  ],
  defaultColDef,
};
