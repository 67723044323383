import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import App from "./screens/App";
import store from "./redux/store";
import "config/global";
import "./assets/css/limitless.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/layout.min.css";
import "./assets/css/components.min.css";
import "./assets/icons/icomoon/styles.css";
import "./assets/icons/fontawesome/styles.min.css";

import "./index.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

// agGrid
// import { LicenseManager } from "ag-grid-enterprise";
// LicenseManager.setLicenseKey("TODO buy a real license");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);
