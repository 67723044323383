import {
  PoCancelDateColumnDefinition,
  FlaggedColumnDefinition,
  LockedColumnDefinition,
  PoStartDateColumnDefinition,
  ShipToStoreColumnDefinition,
  DivisionColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { ManagePo } from "components/AGTable/NewColumns/ManagePo";
import { ManagePoViaPoNumber } from "components/AGTable/NewColumns/ManagePoViaPoNumber";
import config from "config/config";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },

  filter: true,
};

const rmqty = "Remaining Qty";

const columnDefs = [
  ManagePo,
  ManagePoViaPoNumber,
  PoStartDateColumnDefinition,
  PoCancelDateColumnDefinition,
  ShipToStoreColumnDefinition,
  {
    headerName: "# of ASNs",
    width: 115,
    field: "asn_count",
  },
  {
    headerName: `${rmqty}`,
    width: 120,
    field: "remaining_qty",
    sortable: false,
  },
  DivisionColumnDef,
  LockedColumnDefinition,
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(12, 0, FlaggedColumnDefinition);

export const poGrid = {
  headerHeight: 40,
  columnDefs: columnDefs,
  defaultColDef,
  enableServerSideSorting: true,
};
