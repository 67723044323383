const initialState = {
  vendor_settings: [],
  default_settings: [],
  settingsUpToDate: false,
  added: false,
  deleted: false,
  updated: false,
  error: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "GET_VENDOR_SETTING":
      return {
        ...state,
        settingsUpToDate: true,
      };
    case "GET_VENDOR_SETTING_SUCCESS":
      return {
        ...state,
        vendor_settings: action.data.data,
      };
    case "GET_VENDOR_SETTING_FAILED":
      return {
        ...state,
        error: action.error,
        settingsUpToDate: false,
      };
    case "GET_DEFAULT_SETTING":
      return {
        ...state,
        settingsUpToDate: true,
      };
    case "GET_DEFAULT_SETTING_SUCCESS":
      return {
        ...state,
        default_settings: action.data.results,
      };
    case "GET_DEFAULT_SETTING_FAILED":
      return {
        ...state,
        error: action.error,
        settingsUpToDate: false,
      };
    case "CU_VENDOR_SETTING":
      return {
        ...state,
        updated: false,
      };
    case "CU_VENDOR_SETTING_SUCCESS":
      return {
        ...state,
        vendor_settings: updateSetting(action.data, state.vendor_settings),
        updated: true,
      };
    case "CU_VENDOR_SETTING_FAILED":
      return {
        ...state,
        error: action.error,
        updated: false,
      };
    default:
      return state;
  }
};

const updateSetting = (setting, vendor_settings) => {
  return vendor_settings.map((c) => {
    c =
      c.setting.id === setting.setting
        ? { ...c, setting_value: setting.setting_value }
        : c;
    return c;
  });
};
