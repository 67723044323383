import React, { useEffect } from "react";
import { connect } from "react-redux";
import { warehouse as constants } from "config/constants";
import { getAllWarehouses } from "redux/actions/warehouse.action";
import { Link } from "react-router-dom";
import { Alert, Button } from "reactstrap";

const VendorSetup = ({ getAllWarehouses, warehouse, children, user }) => {
  useEffect(() => {
    if (user.user.isVendor() && !warehouse.allWarehousesUpToDate) {
      getAllWarehouses();
    }
  }, [getAllWarehouses, warehouse.allWarehousesUpToDate, user.user]);
  if (
    user &&
    user.user.isVendor() &&
    window.location.pathname !== "/warehouse" &&
    warehouse.warehouse &&
    warehouse.warehouses.length === 0 &&
    warehouse.warehousesRetrieved
  ) {
    return (
      <Alert color="danger">
        <Link
          to={{
            pathname: "/warehouse",
          }}
        >
          <p style={{ color: "black" }}>
            {constants.noWarehouseFoundError}{" "}
            <Button color="primary">Confirm</Button>
          </p>
        </Link>
      </Alert>
    );
  } else {
    return [children];
  }
};

export default connect(({ user, warehouse }) => ({ user, warehouse }), {
  getAllWarehouses,
})(VendorSetup);
