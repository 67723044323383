import React from "react";
import OpenModalIcon from "./components/OpenModalIcon";

const cellRender = ({ data, context }) => {
  return (
    <OpenModalIcon
      click={() =>
        context.componentParent.updateData(data, "detail", "Components")
      }
      icon={"icon-menu6"}
    />
  );
};

export const AsnDetails = {
  headerName: "Details ",
  filter: null,
  cellStyle: { display: "flex", "justify-content": "center" },
  field: "details",
  width: 85,
  sortable: false,
  cellRendererFramework: cellRender,
  suppressSizeToFit: true,
};
