import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getVendorsWithZeroUsers } from "redux/actions/vendor.action";
import { vendorsGrid } from "../../Vendor/tables/vendorsGrid";
import { AGTable, Card, ActionAlerts } from "components";
import { vendor as v } from "config/constants";

const NewVendors = ({ vendor, getVendorsWithZeroUsers }) => {
  useEffect(() => {
    getVendorsWithZeroUsers();
  }, [getVendorsWithZeroUsers]);

  return (
    <Card title="Vendors with No Users">
      <ActionAlerts item={vendor} constants={v} />
      <AGTable
        tableName="newVendor"
        paginate={true}
        gridConfig={vendorsGrid}
        rowData={vendor && vendor.vendorsWithZeroUsers}
        isDoneLoading={vendor.vendorsWithZeroUsersRetrieved}
        action={getVendorsWithZeroUsers}
        next={vendor.next}
        previous={vendor.previous}
        totalRows={vendor.totalRows}
      />
    </Card>
  );
};

export default connect(({ vendor }) => ({ vendor }), {
  getVendorsWithZeroUsers,
})(NewVendors);
