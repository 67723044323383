import React from "react";
import { connect } from "react-redux";
import { ContentHeader, Content, Card, Button, Footer } from "components";
import { ButtonGroup, UncontrolledAlert } from "reactstrap";
import { demoScript } from "redux/actions/tool.action";

const Tools = ({ demoScript, script }) => {
  const handleClick = (demoNumber) => {
    demoScript(demoNumber);
  };

  const icon = () => {
    if (script.scriptIsRunning) {
      return "icon-rocket";
    }
    if (script.success) {
      return "icon-thumbs-up2";
    }
    if (script.failed) {
      return "icon-cancel-circle2";
    }
  };

  const text = () => {
    if (script.scriptIsRunning) {
      return "Script is Running...";
    }
    if (script.success) {
      return "Script worked!";
    }
    if (script.failed) {
      return "There was an error, please try again.";
    }
  };

  const response = () => {
    return script && script.success ? script.scripts : "";
  };

  const alert = () => {
    return (
      <UncontrolledAlert
        color={script && "danger"}
        alertIcon={icon()}
        alertText={text() + " " + response()}
      />
    );
  };

  let running = script && script.scriptIsRunning ? alert() : "";
  let success = script && script.success ? alert() : "";
  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Tools" menuType="Basic" />
      <Content>
        <div key={Date.now()}>
          {running}
          {success}
        </div>
        <Card title="Demo">
          <Button
            toggleLoader={script.scriptIsRunning}
            disabled={script.scriptIsRunning}
            onClick={() => handleClick(0)}
          >
            Demo Reset Main
          </Button>

          <br />
          <br />
          <ButtonGroup>
            <Button
              toggleLoader={script.scriptIsRunning}
              disabled={script.scriptIsRunning}
              onClick={() => handleClick(1)}
            >
              Po Component Changed
            </Button>
          </ButtonGroup>
          <br />
          <br />
          <ButtonGroup>
            <Button
              toggleLoader={script.scriptIsRunning}
              disabled={script.scriptIsRunning}
              onClick={() => handleClick(3)}
            >
              Po Line Changed
            </Button>

            <Button
              toggleLoader={script.scriptIsRunning}
              disabled={script.scriptIsRunning}
              onClick={() => handleClick(4)}
            >
              Po Line Removed
            </Button>
          </ButtonGroup>
          <br />
          <br />
          <ButtonGroup>
            <Button
              toggleLoader={script.scriptIsRunning}
              disabled={script.scriptIsRunning}
              onClick={() => handleClick(5)}
            >
              Po Change
            </Button>

            <Button
              toggleLoader={script.scriptIsRunning}
              disabled={script.scriptIsRunning}
              onClick={() => handleClick(6)}
            >
              Po Remove
            </Button>
          </ButtonGroup>
        </Card>
      </Content>
      <Footer />
    </div>
  );
};

export default connect(({ script }) => ({ script }), { demoScript })(Tools);
