import Service from "./service";
import { resetAlerts } from "./global.action";

export const getEmailLists = () => (dispatch) => {
  Service.get(dispatch, "client/email-group/", {
    init: "GET_EMAIL_LISTS",
    success: "GET_EMAIL_LISTS_SUCCESS",
    error: "GET_EMAIL_LISTS_FAILED",
  });
};

export const addEmail = (body, group_id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.patch(dispatch, `client/email-group/${group_id}/`, body, {
    init: "ADD_GROUP_EMAIL",
    success: "ADD_GROUP_EMAIL_SUCCESS",
    error: "ADD_GROUP_EMAIL_FAILED",
  });
};

export const setSelectedDivision = (name) => (dispatch) => {
  dispatch({ type: "SET_SELECTED_DIVISION", payload: name });
};
