import React from "react";
import { connect } from "react-redux";
import { deleteShipment } from "redux/actions/shipment.action";
import ModalFooterDeleteClose from "../components/ModalFooterDeleteClose";

const ShipmentDeleteForm = ({ modal: { selectedData }, deleteShipment }) => {
  return (
    <>
      <form>
        <div className="modal-body">
          <div className="form-row">
            <div className="col-md">
              <p>
                Are you sure you want to delete Shipment{" "}
                <b>{selectedData.id}</b>?{" "}
              </p>
              <p>
                <b>Cancel Carrier:</b>
              </p>
              <p>
                <b>{selectedData.carrier.name}</b>
              </p>
              <p>
                Phone: <b>{selectedData.carrier.phone}</b>
              </p>
              <p>
                Email: <b>{selectedData.carrier.email}</b>
              </p>
            </div>
          </div>
        </div>
        <ModalFooterDeleteClose click={() => deleteShipment(selectedData.id)} />
      </form>
    </>
  );
};

export default connect(({ modal, warehouse }) => ({ modal, warehouse }), {
  deleteShipment,
})(ShipmentDeleteForm);
