import Service from "./service";
import { resetAlerts } from "./global.action";

export const getCarriers = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.page_size = 20000;
  Service.get(dispatch, "carrier/carriers/", qp, {
    init: "GET_CARRIERS",
    success: "GET_CARRIERS_SUCCESS",
    error: "GET_CARRIERS_FAILED",
  });
};

export const getDefaultCarriers = () => (dispatch) => {
  Service.get(dispatch, "carrier/carriers/", {
    init: "GET_DEFAULT_CARRIERS",
    success: "GET_DEFAULT_CARRIERS_SUCCESS",
    error: "GET_DEFAULT_CARRIERS_FAILED",
  });
};

export const getCarrier = (id) => (dispatch) => {
  Service.get(dispatch, `carrier/carriers/${id}`, {
    init: "GET_CARRIER",
    success: "GET_CARRIER_SUCCESS",
    error: "GET_CARRIER_FAILED",
  });
};

export const createCarrier = (carrier) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "carrier/carriers/", carrier, {
    init: "ADD_CARRIER",
    success: "ADD_CARRIER_SUCCESS",
    error: "ADD_CARRIER_FAILED",
  });
};

export const deleteCarrier = (id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.delete(dispatch, `carrier/carriers/${id}`, {
    init: "DELETE_CARRIER",
    success: "DELETE_CARRIER_SUCCESS",
    error: "DELETE_CARRIER_FAILED",
  });
};

export const updateCarrier = (carrier) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.patch(dispatch, `carrier/carriers/${carrier.id}`, carrier, {
    init: "UPDATE_CARRIER",
    success: "UPDATE_CARRIER_SUCCESS",
    error: "UPDATE_CARRIER_FAILED",
  });
};

export const selectCarrier = (data) => (dispatch) => {
  dispatch({ type: "SELECT_CARRIER", data });
};

export const addWarehouseToCarrier = (data) => (dispatch) => {
  dispatch({ type: "ADD_WAREHOUSE_TO_CARRIER", data });
};
export const removeWarehouseFromCarrier = (data) => (dispatch) => {
  dispatch({ type: "REMOVE_WAREHOUSE_FROM_CARRIER", data });
};

export const unselectCarrier = () => (dispatch) => {
  dispatch({ type: "UNSELECT_CARRIER" });
};
export const setDefaultCarrier = (data) => (dispatch) => {
  dispatch({ type: "SET_DEFAULT_CARRIER", data });
};
export const clearCurrentCarrier = () => (dispatch) => {
  dispatch({ type: "CLEAR_CURRENT_CARRIER" });
};
