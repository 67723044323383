const initialState = {
  vendors: [],
  allVendors: [],
  vendorsUpToDate: false,
  gettingVendors: false,
  vendorsRetrieved: false,
  currentVendor: {},
  gettingCurrentVendor: false,
  currentVendorUpToDate: false,
  added: false,
  updated: false,
  deleted: false,
  vendorsWithWarehouses: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        emailAlert: false,
        error: "",
      };
    case "LOGOUT":
      return initialState;
    case "GET_VENDORS":
      return {
        ...state,
        gettingVendors: true,
        vendorsUpToDate: true,
        vendorsRetrieved: false,
      };
    case "GET_VENDORS_SUCCESS":
      return {
        ...state,
        vendors: action.data.results,
        vendorsRetrieved: true,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_VENDORS_FAILED":
      return {
        ...state,
        error: action.error,
        vendorsUpToDate: false,
      };
    case "GET_ALL_VENDORS_SUCCESS":
      return {
        ...state,
        allVendors: action.data.results,
      };
    case "GET_ALL_VENDORS_FAILED":
      return {
        ...state,
        error: action.error,
      };

    case "GET_VENDORS_ZEROUSERS":
      return {
        ...state,
        gettingVendors: true,
        vendorsWithZeroUsersUpToDate: true,
        vendorsWithZeroUsersRetrieved: false,
      };
    case "GET_VENDORS_ZEROUSERS_SUCCESS":
      return {
        ...state,
        vendorsWithZeroUsers: action.data.results,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
        vendorsWithZeroUsersRetrieved: true,
      };
    case "GET_VENDORS_ZEROUSERS_FAILED":
      return {
        ...state,
        error: action.error,
        vendorsWithZeroUsersUpToDate: false,
      };

    case "GET_VENDORS_WITH_WAREHOUSES":
      return {
        ...state,
        gettingVendorsWithWarehouses: true,
      };
    case "GET_VENDORS_WITH_WAREHOUSES_SUCCESS":
      return {
        ...state,
        vendorsWithWarehouses: action.data.results,
        gettingVendorsWithWarehouses: false,
      };
    case "GET_VENDORS_WITH_WAREHOUSES_FAILED":
      return {
        ...state,
        error: action.error,
        gettingVendorsWithWarehouses: false,
      };
    case "GET_VENDOR":
      return {
        ...state,
        gettingCurrentVendor: true,
        currentVendorUpToDate: true,
      };
    case "GET_VENDOR_SUCCESS":
      return {
        ...state,
        currentVendor: action.data,
        gettingCurrentVendor: false,
        warehousesUpToDate: true,
      };
    case "GET_VENDOR_FAILED":
      return {
        ...state,
        error: action.error,
        gettingCurrentVendor: false,
      };
    case "ADD_VENDOR_SUCCESS":
      return {
        ...state,
        vendors: state.vendors.concat([action.data]),
        vendorsUpToDate: false,
        vendorsWithZeroUsersUpToDate: false,
        added: true,
      };
    case "ADD_VENDOR_FAILED":
      return {
        ...state,
        vendors: state.vendors.concat([action.data]),
        vendorsUpToDate: false,
        error: action.error.error,
      };
    case "DELETE_VENDOR_SUCCESS":
      return {
        ...state,
        vendors: deleteVendor(action.data, state.vendors),
        vendorsUpToDate: false,
      };
    case "UPDATE_VENDOR_SUCCESS":
      return {
        ...state,
        vendors: updateVendor(action.data, state.vendors),
        currentVendorUpToDate: false,
        vendorsUpToDate: false,
      };
    case "VENDOR_INTAKE_SUCCESS": {
      const vendors = state.vendors
        .filter(
          (vendor) =>
            !action.data.data.includes(
              (incomingVendor) => incomingVendor["id"] === vendor["id"],
            ),
        )
        .concat(action.data.data);
      return {
        ...state,
        vendors,
        vendorsUpToDate: false,
      };
    }
    case "ADD_WAREHOUSE_SUCCESS":
      return {
        ...state,
        warehousesUpToDate: false,
      };
    case "DELETE_WAREHOUSE_SUCCESS":
      return {
        ...state,
        warehousesUpToDate: false,
      };
    case "UPDATE_WAREHOUSE_SUCCESS":
      return {
        ...state,
        warehousesUpToDate: false,
      };
    case "RESET_UPTODATE_FLAG":
      return {
        ...state,
        vendorsUpToDate: false,
        vendorsWithZeroUsersRetrieved: false,
      };
    default:
      return state;
  }
};

const deleteVendor = (id, vendors) => vendors.filter((v) => v.id !== id);
const updateVendor = (vendor, vendors) =>
  vendors.map((v) => (v = v.id === vendor.id ? vendor : v));
