import React, { useState } from "react";
//import { Button } from "components";
import { connect } from "react-redux";
import { importPos } from "redux/actions/pos.action";

const ImportBtn = ({ importPos }) => {
  const [hasStarted] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // const handleInitClick = () => {
  //   setHasStarted(true);
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    // const file = e.target.vendorImport.files[0];
    // importPos(file);
    setIsUploading(true);
    setHasSubmitted(true);
  };

  return (
    <div
      style={{
        position: "relative",
        height: (() => {
          if (hasSubmitted) {
            return "";
          } else if (hasStarted) {
            return "2.2rem";
          } else {
            return "";
          }
        })(),
      }}
    >
      <div
        style={{
          position: "absolute",
          right: "0",
          width: "18rem",
        }}
      >
        {(() => {
          if (hasSubmitted) {
            return <p>Uploaded!</p>;
          } else if (isUploading) {
            return <p>Uploading...</p>;
          } else if (hasStarted) {
            return (
              <form onSubmit={handleSubmit}>
                <label htmlFor="PoImport">Import Po</label>
                <input
                  style={{
                    marginBottom: "0.5rem",
                  }}
                  id="PoImport"
                  type="file"
                  accept=".csv"
                  required
                />
                <input type="submit" />
              </form>
            );
          }
          // else {
          //   return (
          //     <Button
          //       color="primary"
          //       onClick={handleInitClick}
          //       style={{ fontSize: "0.9rem", marginLeft: "58%" }}
          //     >
          //       Import Po
          //     </Button>
          //   );
          // }
        })()}
      </div>
    </div>
  );
};

export default connect(null, { importPos })(ImportBtn);
