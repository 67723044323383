import React from "react";
import "./LoginLogoBar.css";
// import logo from "assets/images/rue21v1.png";

class LoginLogoBar extends React.Component {
  render() {
    return (
      <div className="navbar navbar-expand-md navbar-dark">
        <div className="navbar-brand">
          <a href="http://www.everesttech.com/" className="d-inline-block">
            {/* <img src={logo} alt="" /> */}
          </a>
        </div>
      </div>
    );
  }
}
export default LoginLogoBar;
