const initialState = {
  selectedCarrierId: 0,
  carriers: [],
  carriersUpToDate: false,
  carriersRetrieved: false,
  currentCarrier: {},
  carrierWarehouses: [],
  deleted: false,
  updated: false,
  added: false,
  error: "",
  setDefaultCarrier: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "GET_CARRIERS":
      return {
        ...state,
        gettingCarriers: true,
        carriersUpToDate: true,
        carriersRetrieved: false,
      };
    case "GET_CARRIERS_SUCCESS":
      return {
        ...state,
        gettingCarriers: false,
        carriers: action.data.results,
        carriersRetrieved: true,
      };
    case "GET_CARRIERS_FAILED":
      return {
        ...state,
        gettingCarriers: false,
        error: action.error,
        carriersUpToDate: false,
      };
    case "GET_DEFAULT_CARRIERS":
      return {
        ...state,
        gettingDefaultCarriers: true,
        defaultCarriersUpToDate: true,
        defaultCarriersRetrieved: false,
      };
    case "GET_DEFAULT_CARRIERS_SUCCESS":
      let nonDefaultCarriers = action.data.results.filter(
        (item) => !item.default,
      );
      let defaultCarriers = action.data.results.filter((item) => item.default);
      return {
        ...state,
        gettingDefaultCarriers: false,
        defaultCarriers: defaultCarriers,
        nonDefaultCarriers: nonDefaultCarriers,
        defaultCarriersRetrieved: true,
        defaultNext: action.data.next,
        defaultPrevious: action.data.previous,
        defaultTotalRows: action.data.count,
      };
    case "GET_DEFAULT_CARRIERS_FAILED":
      return {
        ...state,
        gettingDefaultCarriers: false,
        error: action.error,
        defaultCarriersUpToDate: false,
      };
    case "GET_CARRIER":
      return {
        ...state,
        gettingCarrier: true,
      };
    case "GET_CARRIER_SUCCESS":
      return {
        ...state,
        gettingCarrier: false,
        currentCarrier: action.data,
        carrierWarehouses: action.data.warehouses,
      };
    case "GET_CARRIER_FAILED":
      return {
        ...state,
        gettingCarrier: false,
        error: action.error,
      };
    case "ADD_CARRIER":
      return {
        ...state,
        addingCarrier: true,
        added: false,
      };
    case "ADD_CARRIER_SUCCESS":
      return {
        ...state,
        carriers: state.carriers.concat([action.data]),
        added: true,
        addingCarrier: false,
        defaultCarriersUpToDate: false,
        nonDefaultCarriersUpToDate: false,
      };
    case "ADD_CARRIER_FAILED":
      return {
        ...state,
        error: action.error,
        addingCarrier: false,
        added: false,
      };
    case "DELETE_CARRIER":
      return {
        ...state,
        deletingCarrier: true,
        defaultCarriersUpToDate: true,
        deleted: false,
      };
    case "DELETE_CARRIER_SUCCESS":
      return {
        ...state,
        deleted: true,
        carriers: deleteCarrierFromList(action.data, state.carriers),
        defaultCarriersUpToDate: false,
        deletingCarrier: false,
        carriersUpToDate: false,
      };
    case "DELETE_CARRIER_FAILED":
      return {
        ...state,
        error: action.error,
        deletingCarrier: false,
        deleted: false,
      };
    case "UPDATE_CARRIER":
      return {
        ...state,
        updatingCarrier: true,
        updated: false,
      };
    case "UPDATE_CARRIER_SUCCESS":
      return {
        ...state,
        carriers: updateCarrier(action.data, state.carriers),
        updated: true,
        updatingCarrier: false,
        defaultCarriersUpToDate: false,
        nonDefaultCarriersUpToDate: false,
      };
    case "UPDATE_CARRIER_FAILED":
      return {
        ...state,
        error: action.error,
        updatingCarrier: false,
        updated: false,
      };
    case "SELECT_CARRIER":
      return {
        ...state,
        selectedCarrierId: action.data,
      };
    case "UNSELECT_CARRIER":
      return {
        ...state,
        setDefaultCarrier: {},
        selectedCarrierId: 0,
        unselectedCarrier: true,
      };
    case "CANCEL_SHIPMENT":
      return {
        ...state,
        selectedCarrierId: 0,
        canceledShipment: true,
      };
    case "ADD_WAREHOUSE_TO_CARRIER": {
      const { carrierWarehouses } = state;
      const { data } = action;
      const alreadyHasWarehouse = carrierWarehouses.some(
        (warehouse) => warehouse.id === data.id,
      );
      return {
        ...state,
        carrierWarehouses: alreadyHasWarehouse
          ? carrierWarehouses
          : carrierWarehouses.concat([data]),
      };
    }
    case "REMOVE_WAREHOUSE_FROM_CARRIER":
      return {
        ...state,
        carrierWarehouses: deleteCarrierFromList(
          action.data.id,
          state.carrierWarehouses,
        ),
        removedWarehouse: true,
      };
    case "CLEAR_CURRENT_CARRIER":
      return {
        ...state,
        carrierWarehouses: [],
        currentCarrier: {},
      };
    case "RESET_UPTODATE_FLAG":
      return {
        ...state,
        carriersUpToDate: false,
        defaultCarriersUpToDate: false,
        nonDefaultCarriersUpToDate: false,
      };
    case "SET_DEFAULT_CARRIER":
      return {
        ...state,
        setDefaultCarrier: action.data,
      };
    default:
      return state;
  }
};
const deleteCarrierFromList = (id, carriers) =>
  carriers.filter((c) => c.id !== id);
const updateCarrier = (carrier, carriers) =>
  carriers.map((c) => (c = c.id === carrier.id ? carrier : c));
