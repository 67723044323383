/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateUser } from "redux/actions/user.action";
import ModalFooterUpdateClose from "../components/ModalFooterUpdateClose";
import { validateEmail } from "utils/helperFunctions";

class UserModalForm extends React.Component {
  state = {
    data: {},
    emailError: "",
  };

  updateUser(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email } = this?.state?.data;

    // Email validation
    if (email && !validateEmail(email)) {
      this.setState({ emailError: "Invalid email address format" });
      return;
    }

    // Clear error if email is valid
    this.setState({ emailError: "" });

    // Proceed with form submission
    this.props.updateUser(this.state.data);
    this.closeButton.click();
  }

  render() {
    const {
      modal: { selectedData },
      user: { user, roles },
    } = this.props;

    const { emailError } = this.state;

    // eslint-disable-next-line
    this.state.data.id = selectedData.id;

    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit(e);
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="col-md-6 mb-6">
                <label htmlFor="firstname">First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First name"
                  pattern="[a-zA-Z ]*$"
                  defaultValue={selectedData.first_name}
                  onKeyUp={(e) => this.updateUser("first_name", e.target.value)}
                  required
                />
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor="lastname">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Last name"
                  pattern="[a-zA-Z ]*$"
                  defaultValue={selectedData.last_name}
                  onKeyUp={(e) => this.updateUser("last_name", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-6">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <div className="input-group-prepend" />
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    defaultValue={selectedData.email}
                    aria-describedby="inputGroupPrepend"
                    onKeyUp={(e) => this.updateUser("email", e.target.value)}
                    required
                  />
                </div>
                {emailError && <div className="error-color">{emailError}</div>}
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor="role">Role</label>
                <select
                  className="form-control"
                  defaultValue={
                    roles.find((r) => r.name == selectedData?.role)?.id
                  }
                  onChange={(e) => {
                    this.updateUser("role_id", e.target.value);
                  }}
                >
                  {roles.map((r) =>
                    user && !user.isSuper() && r.name !== "super" ? (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ) : user &&
                      user.isSuper() &&
                      r.client === selectedData.role.client ? (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ) : (
                      ""
                    ),
                  )}
                </select>
              </div>
            </div>
          </div>
          <ModalFooterUpdateClose
            inputRef={(button) => (this.closeButton = button)}
          />
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal, user, vendor }) => ({ modal, user, vendor }),
  (dispatch) => bindActionCreators({ updateUser }, dispatch),
)(UserModalForm);
