import React, { Component } from "react";
import { Alert } from "reactstrap";

export default class AsnSaveErrors extends Component {
  formatedErrors() {
    const { errors } = this.props;

    const modellessErrors = errors
      .filter((error) => !("model" in error))
      .map((error) => error.error);
    const errorList = errors
      .filter((error) => "model" in error)
      .map((v) => ({
        sku: v.model.getSku() + (v.model.isPartial() ? " (partial)" : ""),
        msg: " " + v.error,
      }));

    let output = [];

    errorList.forEach(function (item) {
      let existing = output.filter(function (v, i) {
        return v.sku === item.sku;
      });
      if (existing.length) {
        let existingIndex = output.indexOf(existing[0]);
        output[existingIndex].msg = output[existingIndex].msg.concat(item.msg);
      } else {
        if (typeof item.msg === "string") item.msg = [item.msg];
        output.push(`${item.sku} - ${item.msg}`);
      }
    });
    output = output.concat(modellessErrors);
    return output;
  }
  render() {
    const errors = this.formatedErrors();

    return (
      <Alert color="danger">
        Failed to save asn:
        <br />
        <br />
        {errors.map((error) => (
          <div key={error}>{error}</div>
        ))}
      </Alert>
    );
  }
}
