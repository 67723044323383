import React from "react";
import { connect } from "react-redux";
import { deleteWarehouse } from "redux/actions/warehouse.action";
import ModalFooterDeleteClose from "../components/ModalFooterDeleteClose";
import ModalBodyDelete from "../components/ModalBodyDelete";

const WarehouseDeleteForm = ({ modal: { selectedData }, deleteWarehouse }) => {
  return (
    <>
      <form>
        <ModalBodyDelete name={selectedData.name} />
        <ModalFooterDeleteClose
          click={() => deleteWarehouse(selectedData.id)}
        />
      </form>
    </>
  );
};

export default connect(({ modal, warehouse }) => ({ modal, warehouse }), {
  deleteWarehouse,
})(WarehouseDeleteForm);
