import React from "react";
import BreadcrumbLink from "./components/BreadcrumbLink";

const cellRender = ({ data }) => {
  return (
    <BreadcrumbLink
      pathname={"/vendor/" + data.id}
      breadcrumb={"Vendors"}
      url={"vendor"}
    />
  );
};

export const VendorDetails = {
  headerName: "Details",
  filter: null,
  field: "id",
  width: 85,
  sortable: false,
  cellRendererFramework: cellRender,
  cellStyle: { display: "flex", "justify-content": "center" },
};
