import Zipcodes from "assets/data/zipcodes";

function getDistanceFromLatLonInKm(
  { lat: latOne, long: longOne },
  { lat: latTwo, long: longTwo },
) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(latTwo - latOne); // deg2rad below
  var dLon = deg2rad(longTwo - longOne);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(latOne)) *
      Math.cos(deg2rad(latTwo)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function distance(carrierZipcode, warehouseZipcode) {
  try {
    const carrierCoordinates = Zipcodes[carrierZipcode];
    const warehouseCoordinates = Zipcodes[warehouseZipcode];
    const distanceInKm = getDistanceFromLatLonInKm(
      carrierCoordinates,
      warehouseCoordinates,
    );
    const distanceInMiles = distanceInKm * 0.621371;
    return distanceInMiles;
  } catch (error) {
    return "";
  }
}
