import React, { useEffect } from "react";
import { connect } from "react-redux";
import { detailGrid } from "./tables/detailGrid";
import { userGrid } from "./tables/userGrid";
import { carrierGrid } from "./tables/carrierGrid";
import { warehouseGrid } from "./tables/warehouseGrid";
import { getVendor } from "../../redux/actions/vendor.action";
import { vendor as constants } from "config/constants";
import { Card, ContentHeader, Content, PageLoading, AGTable } from "components";

const Details = ({ location, vendor, getVendor }) => {
  const { pathname, state } = location;
  const { currentVendor } = vendor;
  useEffect(() => {
    getVendor(pathname.split("/")[2]);
  }, [pathname, getVendor]);

  if (vendor.gettingVendor) {
    return (
      <>
        <div className="content-wrapper">
          <ContentHeader info={state} pagename="Vendor" menuType="Basic" />
          <Content>
            <PageLoading />;
          </Content>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="content-wrapper">
        <ContentHeader
          info={state}
          pagename={currentVendor.name}
          menuType="Basic"
        />
        <Content>
          <AGTable
            isDoneLoading={true}
            tableName="vendor1"
            gridConfig={detailGrid}
            rowData={[currentVendor]}
          />
          <br />
          <br />
          <Card title="Users">
            {currentVendor.users && currentVendor.users.length > 0 ? (
              <AGTable
                isDoneLoading={true}
                tableName="vendor2"
                gridConfig={userGrid}
                rowData={currentVendor.users}
              />
            ) : (
              <p>{currentVendor.name + " " + constants.vendorHasNoUsers}</p>
            )}
          </Card>
          <br />
          <br />
          <Card title="Warehouses">
            {currentVendor.warehouses && currentVendor.warehouses.length > 0 ? (
              <AGTable
                isDoneLoading={true}
                tableName="vendor3"
                gridConfig={warehouseGrid}
                rowData={currentVendor.warehouses}
              />
            ) : (
              <p>
                {currentVendor.name + " " + constants.vendorHasNoWarehouses}
              </p>
            )}
          </Card>
          <br />
          <br />
          <Card title="Carriers">
            {currentVendor.carriers && currentVendor.carriers.length > 0 ? (
              <AGTable
                isDoneLoading={true}
                tableName="vendor4"
                gridConfig={carrierGrid}
                rowData={currentVendor.carriers}
              />
            ) : (
              <p>{currentVendor.name + " " + constants.vendorHasNoCarriers}</p>
            )}
          </Card>
        </Content>
      </div>
    </>
  );
};

export default connect(({ vendor }) => ({ vendor }), { getVendor })(Details);
