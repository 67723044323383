import React from "react";
import { connect } from "react-redux";

const AuthRole = ({ user, userRole, children }) => {
  const authorizedContent = (role, loggedInUser, child) => {
    switch (role) {
      case "client":
        return loggedInUser.isClient() &&
          !loggedInUser.isSuper() &&
          !loggedInUser.isVendor()
          ? child
          : "";
      case "vendor":
        return loggedInUser.isVendor() &&
          !loggedInUser.isSuper() &&
          !loggedInUser.isClient()
          ? child
          : "";
      case "super":
        return loggedInUser.isSuper() &&
          !loggedInUser.isClient() &&
          !loggedInUser.isVendor()
          ? child
          : "";
      case "clientAdmin":
        return (loggedInUser.isClient() && loggedInUser.isAdmin()) ||
          loggedInUser.isSuper()
          ? child
          : "";
      default:
        return "";
    }
  };

  return <> {authorizedContent(userRole, user.user, children)}</>;
};

export default connect(({ user }) => ({ user }), {})(AuthRole);
