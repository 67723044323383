import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createWarehouse } from "redux/actions/warehouse.action";
import ModalFooterAddClose from "../components/ModalFooterAddClose";
import { Typeahead } from "react-bootstrap-typeahead";
import country from "assets/data/Country";
import { validateEmail } from "utils/helperFunctions";

class WarehouseModalForm extends React.Component {
  state = this.initialState();

  initialState() {
    return {
      warehouse: {
        name: "",
        address: "",
        city: "",
        state: "",
        country: "US",
        zip: "",
        phone: "",
        email: "",
        contact_name: "",
        fax: "",
      },
      emailError: "",
      stateError: "",
    };
  }

  updateWarehouse(field, value) {
    if (!value.trim() && value.length !== 0) return;
    let st = this.state;
    st.warehouse[field] = value;
    this.setState(st);
  }

  clearFields() {
    this.setState(this.initialState());
  }

  closeModal() {
    this.closeButton.click();
  }

  handleSubmit() {
    const { email, state } = this.state.warehouse;
    let emailError = "";
    let stateError = "";

    if (!validateEmail(email)) {
      emailError = "Invalid email address format";
    }

    if (state === "" || typeof state === "undefined") {
      stateError = "Please select a valid state";
    }

    if (emailError || stateError) {
      this.setState({ emailError, stateError });
      return;
    }

    this.props.createWarehouse(this.state.warehouse);
    this.clearFields();
    this.closeButton.click();
  }

  render() {
    const { warehouse, emailError, stateError } = this.state;
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="col-md-3 mb-3">
                <label htmlFor="firstname">Name</label>
                <input
                  type="text"
                  pattern="^[A-Za-z\s]+$"
                  title="Name can only contain letters and spaces."
                  required
                  className="form-control"
                  id="firstname"
                  placeholder="warehouse name"
                  value={warehouse.name}
                  onChange={(e) => this.updateWarehouse("name", e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="contact_name">Contact</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_name"
                  placeholder="Contact"
                  value={warehouse.contact_name}
                  onChange={(e) =>
                    this.updateWarehouse("contact_name", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  minLength="8"
                  maxLength="25"
                  pattern="[0-9]{10}"
                  title="Enter a valid Phone Number"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                  value={warehouse.phone}
                  onChange={(e) =>
                    this.updateWarehouse("phone", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    title="Invalid email address format."
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={warehouse.email}
                    onChange={(e) => {
                      this.updateWarehouse("email", e.target.value);
                      this.setState({ emailError: "" });
                    }}
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                </div>
                {emailError && <div className="error-color">{emailError}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  value={warehouse.address}
                  onChange={(e) =>
                    this.updateWarehouse("address", e.target.value)
                  }
                  placeholder="Address"
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="City">City</label>
                <input
                  type="text"
                  pattern="[A-Za-z\s]{1,30}"
                  className="form-control"
                  id="city"
                  value={warehouse.city}
                  onChange={(e) => this.updateWarehouse("city", e.target.value)}
                  placeholder="City"
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="zipcode">Zipcode</label>
                <input
                  type="text"
                  pattern="^\d{5}(-\d{4})?$"
                  title="Invalid ZIP code format. Use 12345 or 12345-6789."
                  className="form-control"
                  id="zipcode"
                  value={warehouse.zip}
                  onChange={(e) => this.updateWarehouse("zip", e.target.value)}
                  placeholder="Zipcode"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3 mb-3">
                <label htmlFor="Country">Country</label>
                <Typeahead
                  id="Country"
                  paginationText="NEXT PAGE"
                  labelKey="name"
                  multiple={true}
                  options={country.map((i) => i.code)}
                  placeholder="Country"
                  ref="typeahead"
                  defaultInputValue={warehouse.country}
                  onChange={(e) => {
                    this.updateWarehouse("country", e[0]);
                  }}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  pattern="[A-Za-z]+"
                  maxLength={25}
                  className="form-control"
                  placeholder="State Like AL"
                  value={warehouse.state}
                  onChange={(e) => {
                    this.updateWarehouse("state", e.target.value);
                    this.setState({ stateError: "" });
                  }}
                  required
                />
                <span id="invalid-state-error" style={{ color: "red" }}>
                  {stateError}
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3 mb-3">
                <label htmlFor="fax">Fax</label>
                <div className="input-group">
                  <input
                    type="number"
                    value={warehouse.fax}
                    className="form-control"
                    id="fax"
                    onChange={(e) =>
                      this.updateWarehouse("fax", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <ModalFooterAddClose
            inputRef={(button) => (this.closeButton = button)}
            onClick={() => this.clearFields()}
          />
        </form>
      </>
    );
  }
}

export default connect(
  ({ modal, vendor, warehouse }) => ({ modal, vendor, warehouse }),
  (dispatch) => bindActionCreators({ createWarehouse }, dispatch),
)(WarehouseModalForm);
