function wordCase(w) {
  return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase();
}

export const removeScreamAndUnderScores = (w) => {
  return w
    .split("_")
    .map((e) => wordCase(e))
    .join(" ");
};

export const filterListByFieldEqualToValue = (list, field, value) => {
  return list.filter((i) => i[field] === value);
};

export const filterListByFieldNotEqualToValue = (list, field, value) => {
  return list.filter((i) => i[field] !== value);
};

export const negativeToZeroFormatter = (params) => {
  return params.value > 0 ? params.value : 0;
};

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
