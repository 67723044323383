import React from "react";
import "./Modal.css";
import UserCreateForm from "./UserForms/UserCreateForm";
import UserUpdateForm from "./UserForms/UserUpdateForm";
import UserDeleteForm from "./UserForms/UserDeleteForm";
import CarrierDeleteForm from "./CarrierForms/CarrierDeleteForm";
import CarrierCreateForm from "./CarrierForms/CarrierCreateForm";
import CarrierUpdateForm from "./CarrierForms/CarrierUpdateForm";
import VendorModalForm from "./VendorForms/VendorModalForm";
import VendorUpdateForm from "./VendorForms/VendorUpdateForm";
import CartonCreateForm from "./CartonForms/CartonCreateForm";
import CartonUpdateForm from "./CartonForms/CartonUpdateForm";
import CartonDeleteForm from "./CartonForms/CartonDeleteForm";
import VendorDeleteForm from "./VendorForms/VendorDeleteForm";
import WarehouseCreateForm from "./WarehouseForms/WarehouseCreateForm";
import WarehouseDeleteForm from "./WarehouseForms/WarehouseDeleteForm";
import WarehouseUpdateForm from "./WarehouseForms/WarehouseUpdateForm";

import AsnDetailView from "./PoForms/AsnDetailView";
import AsnDeleteForm from "./PoForms/AsnDeleteForm";
import CarrierWarehouseView from "./CarrierForms/CarrierWarehouseView";
import ShipmentASNsView from "./ShipmentForms/ShipmentASNsView";
import ShipmentCarrierView from "./ShipmentForms/ShipmentCarrierView";
import ShipmentWarehouseView from "./ShipmentForms/ShipmentWarehouseView";

import ShipmentEmailCreateForm from "./ShipmentForms/ShipmentEmailCreateForm";
import ShipmentEmailDeleteForm from "./ShipmentForms/ShipmentEmailDeleteForm";
import ShipmentEmailUpdateForm from "./ShipmentForms/ShipmentEmailUpdateForm";
import ShipmentStatusUpdateForm from "./ShipmentForms/ShipmentStatusUpdateForm";
import ShipmentDeleteForm from "./ShipmentForms/ShipmentDeleteForm";

import EmailCreateForm from "./EmailForms/EmailCreateForm";
import EmailDeleteForm from "./EmailForms/EmailDeleteForm";
import EmailUpdateForm from "./EmailForms/EmailUpdateForm";

import ClientModalForm from "./ClientForms/ClientCreateForm";
import { connect } from "react-redux";

class Modal extends React.Component {
  state = {
    modalPage: "user",
    pageName: "User",
    data: {},
  };

  modals = {
    user: {
      add: () => <UserCreateForm />,
      update: (d) => <UserUpdateForm key={d} />,
      delete: () => <UserDeleteForm />,
      view: () => "",
      detail: () => "",
      vendors: () => "",
      shipment: () => "",
    },
    client: {
      add: () => <ClientModalForm />,
    },
    carrier: {
      add: () => <CarrierCreateForm />,
      delete: () => <CarrierDeleteForm />,
      update: (d) => <CarrierUpdateForm key={d} />,
      warehouses: (d) => <CarrierWarehouseView key={d} />,
      shipment: (d) => <ShipmentCarrierView key={d} />,
    },
    vendor: {
      add: () => <VendorModalForm />,
      update: (d) => <VendorUpdateForm key={d} />,
      delete: () => <VendorDeleteForm />,
    },
    po: {
      add: () => "",
      update: (d) => "",
    },
    asn: {
      add: () => "",
      update: () => "",
      delete: (d) => <AsnDeleteForm key={d} />,
    },
    carton: {
      add: () => <CartonCreateForm />,
      update: (d) => <CartonUpdateForm key={d} />,
      delete: () => <CartonDeleteForm />,
    },
    components: {
      add: () => "",
      view: (d) => "",
      detail: (d) => <AsnDetailView key={d} />,
    },
    shipment: {
      add: () => <ShipmentEmailCreateForm />,
      view: (d) => <ShipmentASNsView key={d} />,
      delete: (d) => <ShipmentEmailDeleteForm key={d} />,
      update: (d) => <ShipmentEmailUpdateForm key={d} />,
      cancel: (d) => <ShipmentDeleteForm key={d} />,
    },
    warehouse: {
      add: () => <WarehouseCreateForm />,
      delete: (d) => <WarehouseDeleteForm key={d} />,
      update: (d) => <WarehouseUpdateForm key={d} />,
      shipment: (d) => <ShipmentWarehouseView key={d} />,
    },
    status: {
      shipment: (d) => <ShipmentStatusUpdateForm key={d} />,
    },
    email: {
      add: () => <EmailCreateForm />,
      delete: (d) => <EmailDeleteForm key={d} />,
      update: (d) => <EmailUpdateForm key={d} />,
    },
  };

  componentDidUpdate() {
    const page = window.location.pathname.split("/")[1];

    if (!this.state.modalPage.match(page)) {
      this.setState({ modalPage: page, pageName: page.capitalize() });
    }
  }
  deleteButton(prop) {
    return (
      <button type="button" className="btn btn-danger" data-dismiss="modal">
        Delete
      </button>
    );
  }
  render() {
    let {
        modal: { modalType, header },
      } = this.props,
      { modalPage, pageName } = this.state;

    if (header) modalPage = header.toLowerCase();
    if (pageName === "Shipment") pageName = "Shipment Email";
    if (header === "Status") header = " ";
    return (
      <div
        style={{ marginTop: "10%" }}
        className="modal fade"
        id="modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modalSize" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalType.capitalize()} {header || pageName}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.modals[modalPage] &&
              this.modals[modalPage][modalType](Date.now())}
          </div>
        </div>
      </div>
    );
  }
}
export default connect(({ modal, user }) => ({ modal, user }))(Modal);
