import React, { useEffect } from "react";
import { LoginLogoBar, Footer } from "components";
import LoginForm from "./components/LoginForm";
import { resetLoginError } from "redux/actions/user.action";
import { connect } from "react-redux";
import "./login.css";

const Login = ({ resetLoginError }) => {
  useEffect(() => {
    resetLoginError();
  }, [resetLoginError]);
  return (
    <>
      <LoginLogoBar />

      <div className="page-content login-page-content">
        <div className="content-wrapper login-page">
          <div className="content d-flex justify-content-center align-items-center">
            <LoginForm />
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};
export default connect(() => ({}), {
  resetLoginError,
})(Login);
