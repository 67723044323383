import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "redux/actions/user.action";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class SessionExpiredWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Session Expired</ModalHeader>
          <ModalBody>{"Please Login to Continue."}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.props.logout();
              }}
            >
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(
  ({ user }) => ({ user }),
  (dispatch) => bindActionCreators({ logout }, dispatch),
)(SessionExpiredWarning);
