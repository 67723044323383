import React from "react";
import config from "config/config";

export const search = (changeCallback, value, isSearchWidth, disabled) => {
  return (
    <div
      style={{ width: "200px" }}
      className="active-cyan-3 active-cyan-4 mb-4"
    >
      <input
        className="form-control"
        type="text"
        placeholder="Search"
        aria-label="Search"
        disabled={!disabled && !value}
        onChange={changeCallback}
        value={value}
      />
    </div>
  );
};

export const rowStyles = (data, asn, shipment, carrier, user) => {
  if (data) {
    if (
      data.active === false ||
      (data.po_line && data.po_line.active === false)
    ) {
      return { "background-color": "#b1b1b1" };
    }
    if (
      (user.user.isVendor() && data.locked === true) ||
      (data.poLine && data.poLine.active === false)
    ) {
      return { "background-color": "#ff000052" };
    }
    if (config.getFlaggedFeatureFromLocalStorage()) {
      if (data.flagged === true) {
        return { "background-color": "#ffff0052" };
      }
    }
    if (
      data.poLine &&
      asn.cancelDate &&
      data.getCancelDate() !== asn.cancelDate
    ) {
      return { "background-color": "#ccc" };
    }
    if (
      data.po &&
      shipment.isShipmentStarted &&
      shipment.shipToStore !== "" &&
      shipment.shipToStore !== data.po.ship_to_store &&
      (!config
        .getTogetherAllowedStores()
        .includes(Number(shipment.shipToStore.toString().split("/")[0])) ||
        !config.getTogetherAllowedStores().includes(data.po.ship_to_store))
    ) {
      return { "background-color": "#ccc" };
    }
    if (
      data.po &&
      shipment.isShipmentStarted &&
      shipment.shipFromWarehouse !== "" &&
      shipment.shipFromWarehouse.id !== data.warehouse.id
    ) {
      return { "background-color": "#ccc" };
    }
    const warehouses = carrier.carrierWarehouses;
    const alreadyHasWarehouse =
      warehouses && warehouses.some((warehouse) => warehouse.id === data.id);
    if (warehouses && warehouses.length > 0 && alreadyHasWarehouse) {
      return { "background-color": "#ccc" };
    }
  }
  return "";
};

export const setWidth = (gridConfig) => {
  return (
    2 +
    gridConfig.columnDefs
      .map((column) => {
        if (column.width) {
          return column.width;
        } else {
          if (gridConfig.defaultColDef.width) {
            return gridConfig.defaultColDef.width;
          }
          return 200;
        }
      })
      .reduce((a, b) => a + b) +
    "px"
  );
};
