import {
  // AsnVolumeColumnDefinition,
  CreatedAtColumnDefinition,
  FlaggedColumnDefinition,
  AsnNumberColumnDefinition,
  VendorColumnDef,
  WeightColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { GoToPoDetailFromAsn } from "components/AGTable/NewColumns/GoToPoDetailFromAsn";
import { AsnIgnoreShipButton } from "components/AGTable/NewColumns/AsnIgnoreShipButton";
import config from "config/config";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

const columnDefs = [
  GoToPoDetailFromAsn,
  VendorColumnDef("po.vendor_name"),
  AsnNumberColumnDefinition,
  {
    headerName: "Shipment",
    cellStyle: { display: "flex", "justify-content": "center" },
    field: "shipment",
    width: 120,
    sortable: false,
  },
  CreatedAtColumnDefinition,

  // AsnVolumeColumnDefinition("volume"),
  WeightColumnDef,
  AsnIgnoreShipButton,
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(6, 0, FlaggedColumnDefinition);

export const asnClientGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
