import moment from "moment";

import {
  CreatedAtColumnDefinition,
  PoCancelDateColumnDefinition,
  FlaggedColumnDefinition,
  ShipToStoreColumnDefinition,
  VendorColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { ManagePo } from "components/AGTable/NewColumns/ManagePo";
import { ManagePoViaPoNumber } from "components/AGTable/NewColumns/ManagePoViaPoNumber";
import config from "config/config";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },

  filter: true,
};

const columnDefs = [
  ManagePo,
  VendorColumnDef("vendor"),
  ManagePoViaPoNumber,
  PoCancelDateColumnDefinition,
  ShipToStoreColumnDefinition,
  {
    headerName: "# of ASNs",
    width: 115,
    field: "asn_count",
  },
  CreatedAtColumnDefinition,
  {
    headerName: "Order Date",
    field: "order_date",
    filter: "RadioSetFilter",
    width: 105,
    cellRenderer: (data) => String(moment(data.value).format("MM/DD/YYYY")),
  },
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(8, 0, FlaggedColumnDefinition);

export const poClientGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
