import React from "react";
import ActionIcon from "./components/ActionIcon";

const cellRender = ({ data, context }) => {
  const shouldNotRender = () => {
    const asnCancelDate = context.componentParent.props.asn.cancelDate;
    return (
      data.isAddedToAsn ||
      data.poLine.remaining_qty <= 0 ||
      !data.poLine.is_within_ship_window ||
      (asnCancelDate && data.getCancelDate() !== asnCancelDate)
    );
  };
  if (shouldNotRender()) {
    return null;
  }
  return (
    <ActionIcon
      click={(e) => {
        e.preventDefault();
        context.componentParent.createLine(data);
      }}
      icon={"icon-add"}
    />
  );
};

export const AddLineToAsn = {
  headerName: "Add to ASN",
  colId: "cancel_date",
  sortable: false,
  cellRendererFramework: cellRender,
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 115,
};
