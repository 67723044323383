import React, { Fragment } from "react";
import { ContentHeader, Content, Card, ActionAlerts } from "components";
import ClientReports from "assets/data/ClientReports";
import { connect } from "react-redux";
import moment from "moment";
import { reports as c } from "config/constants";
import {
  generateAsnReport,
  generateDataExtractReport,
  generateInTransitReport,
  resendAsnFeed,
} from "redux/actions/reports.action";
import { PageLoading } from "components";

class Reports extends React.Component {
  state = this.initialState();

  updateReport(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }

  initialState() {
    return {
      data: {
        report_name: "ASN Report",
        from_date: "",
        to_date: "",
        asn_number: "",
      },
    };
  }

  handleSubmit() {
    if (this.state.data.report_name === "ASN Report") {
      this.props.generateAsnReport(this.state.data);
    }
    if (this.state.data.report_name === "Data Extract Report") {
      this.props.generateDataExtractReport(this.state.data);
    }
    if (this.state.data.report_name === "In-Transit Report") {
      this.props.generateInTransitReport();
    }
    if (this.state.data.report_name === "ASN Feed") {
      this.props.resendAsnFeed(this.state.data);
    }
    this.clearFields();
    console.log(this.state.data.report_name);
  }

  clearFields() {
    this.setState(this.initialState());
  }

  renderExtraFields(data) {
    return this.state.data.report_name === "Data Extract Report" ? (
      <Fragment>
        <div className="col-md-2 mb-2">
          <label htmlFor="fromDate">From Date</label>
          <input
            type="date"
            required
            className="form-control"
            id="from_date"
            max="9999-12-31"
            placeholder="From Date"
            value={moment(data.from_date).format("YYYY-MM-DD")}
            onChange={(e) => {
              this.updateReport("from_date", e.target.value);
            }}
            maxLength="15"
          />
        </div>
        <div className="col-md-2 mb-2">
          <label htmlFor="toDate">To Date</label>
          <input
            type="date"
            required
            className="form-control"
            id="to_date"
            max="9999-12-31"
            placeholder="To Date"
            value={moment(data.to_date).format("YYYY-MM-DD")}
            onChange={(e) => {
              this.updateReport("to_date", e.target.value);
            }}
            maxLength="15"
          />
        </div>
      </Fragment>
    ) : this.state.data.report_name === "ASN Report" ||
      this.state.data.report_name === "ASN Feed" ? (
      <div className="col-md-2 mb-2">
        <label htmlFor="toDate">ASN</label>
        <input
          type="number"
          required
          className="form-control"
          placeholder="ASN ID"
          onChange={(e) => {
            this.updateReport("asn_number", e.target.value);
          }}
          maxLength="15"
        />
      </div>
    ) : (
      ""
    );
  }

  renderButtonText() {
    return this.state.data.report_name === "ASN Feed"
      ? "Resend ASN Feed"
      : "Submit";
  }

  render() {
    const { data } = this.state;
    const { user } = this.props;
    if (this.props.reports.loading) {
      return (
        <div className="content-wrapper">
          <ContentHeader pagename="Reports" menuType="Basic" />
          <Content>
            <PageLoading />
          </Content>
        </div>
      );
    }
    return (
      <div className="content-wrapper">
        <ContentHeader pagename="Reports" menuType="Basic" />
        <Content>
          <Card title="Reports">
            <ActionAlerts item={this.props.reports} constants={c} />
            <div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleSubmit();
                }}
              >
                <div className="form-row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="report_name">Report</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        this.updateReport("report_name", e.target.value);
                      }}
                    >
                      {ClientReports.map((r) =>
                        user && user.user.isVendor() && r === "ASN Report" ? (
                          <option key={r} value={r}>
                            {r}
                          </option>
                        ) : (
                          user &&
                          user.user.isClient() && (
                            <option key={r} value={r}>
                              {r}
                            </option>
                          )
                        ),
                      )}
                    </select>
                  </div>
                  {this.renderExtraFields(data)}
                  <button
                    id="submit-btn"
                    type="submit"
                    className="btn btn-primary m-4"
                  >
                    {this.renderButtonText()}
                  </button>
                </div>
              </form>
            </div>
          </Card>
        </Content>
      </div>
    );
  }
}
export default connect(({ user, reports }) => ({ user, reports }), {
  generateAsnReport,
  generateDataExtractReport,
  generateInTransitReport,
  resendAsnFeed,
})(Reports);
