import {
  // AsnVolumeColumnDefinition,
  CreatedAtColumnDefinition,
  AsnNumberColumnDefinition,
  PoNumberColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const shipmentASNGrid = {
  columnDefs: [
    AsnNumberColumnDefinition,
    PoNumberColumnDef("po"),
    CreatedAtColumnDefinition,
    // AsnVolumeColumnDefinition("volume"),
  ],
  defaultColDef,
};
