import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUsers, getRoles } from "redux/actions/user.action";
import { getClients } from "redux/actions/client.action";
import { getAllVendors } from "redux/actions/vendor.action";
import { resetUpToDateFlag } from "redux/actions/global.action";
import { userGrid } from "./tables/userGrid";
import { users as c } from "config/constants";
import {
  ContentHeader,
  Content,
  AGTable,
  ActionAlerts,
  Card,
} from "components";

const Users = ({
  user,
  getUsers,
  getRoles,
  getAllVendors,
  client,
  getClients,
  resetUpToDateFlag,
}) => {
  useEffect(() => {
    if (!user.usersUpToDate) {
      getUsers();
      getRoles();
    }
  }, [user.usersUpToDate, getUsers, getRoles]);

  useEffect(() => {
    if (user.user.isSuper() && !client.clientsUpToDate) {
      getClients();
    }
  }, [user.user, getClients, client.clientsUpToDate]);

  useEffect(() => {
    return () => {
      resetUpToDateFlag();
    };
  }, [resetUpToDateFlag]);

  return (
    <div className="content-wrapper">
      <ContentHeader
        pagename="Users"
        action={user.user.isClient() ? getAllVendors : null}
      />
      <Content>
        <Card title="Users">
          <ActionAlerts item={user} constants={c} />
          <AGTable
            tableName="Users"
            paginate={true}
            gridConfig={userGrid}
            rowData={user.users}
            isDoneLoading={user.userRetrieved}
            action={getUsers}
            next={user.next}
            previous={user.previous}
            totalRows={user.totalRows}
          />
        </Card>
      </Content>
    </div>
  );
};

export default connect(
  ({ user, vendor, client }) => ({ user, vendor, client }),
  {
    getUsers,
    getRoles,
    getAllVendors,
    getClients,
    resetUpToDateFlag,
  },
)(Users);
