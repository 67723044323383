import React from "react";
import {
  // AsnVolumeColumnDefinition,
  CreatedAtColumnDefinition,
  FlaggedColumnDefinition,
  AsnNumberColumnDefinition,
  WeightColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { GoToPoDetailFromAsn } from "components/AGTable/NewColumns/GoToPoDetailFromAsn";
import config from "config/config";

const CellRendererFramework = ({ data }) => {
  return (
    <div>
      {data?.shipment === "Empty" && data?.is_within_ship_window ? (
        <i class="icon-check green"></i>
      ) : (
        data?.shipment === "Empty" &&
        data?.is_within_ship_window === false && (
          <div>
            <div style={{ color: "red", fontSize: "20px", fontWeight: "500" }}>
              x
            </div>
          </div>
        )
      )}
    </div>
  );
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

const columnDefs = [
  GoToPoDetailFromAsn,
  AsnNumberColumnDefinition,
  {
    headerName: "Shipment",
    cellStyle: { display: "flex", "justify-content": "center" },
    field: "shipment",
    width: 120,
  },
  CreatedAtColumnDefinition,

  // AsnVolumeColumnDefinition("volume"),
  WeightColumnDef,
  {
    headerName: "Eligible for Shipment",
    field: "ignore_shipment_window",
    cellRendererFramework: CellRendererFramework,
    cellStyle: { display: "flex", "justify-content": "center" },
    width: 183,
  },
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(5, 0, FlaggedColumnDefinition);

export const asnGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
