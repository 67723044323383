import Service from "./service";

export const getASNs = (queryParam) => (dispatch) => {
  Service.get(dispatch, "asn/asns", queryParam || {}, {
    init: "GET_ASNS",
    success: "GET_ASNS_SUCCESS",
    error: "GET_ASNS_FAILED",
  });
};

export const getExpiredASNs = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.expired = true;
  Service.get(dispatch, "asn/asns", qp, {
    init: "GET_EXPIRED_ASNS",
    success: "GET_EXPIRED_ASNS_SUCCESS",
    error: "GET_EXPIRED_ASNS_FAILED",
  });
};

export const getShippableASNs = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.shippable = true;
  Service.get(dispatch, "asn/asns", qp, {
    init: "GET_SHIPPABLE_ASNS",
    success: "GET_SHIPPABLE_ASNS_SUCCESS",
    error: "GET_SHIPPABLE_ASNS_FAILED",
  });
};

// IMPORTANT
// {po_id, warehouse_id, weight, details}
export const createAsn = (data) => (dispatch) => {
  Service.post(dispatch, "asn/asns", data, {
    init: "CREATE_ASN",
    success: "CREATE_ASN_SUCCESS",
    error: "CREATE_ASN_FAILED",
  });
};

// IMPORTANT
// {weight, details}
// TODO:remove this function
export const saveAsn =
  ({ asn_id, ...data }) =>
  (dispatch) => {
    Service.patch(dispatch, `asn/asns/${asn_id}`, data, {
      init: "SAVE_ASN",
      success: "SAVE_ASN_SUCCESS",
      error: "SAVE_ASN_FAILED",
    });
  };

export const resetAsnAlert = () => (dispatch) => {
  dispatch({ type: "RESET_ASN_ALERT" });
};

export const deleteAsn = (id) => (dispatch) => {
  if (id)
    Service.delete(dispatch, `asn/asns/${id}`, {
      init: "DELETE_ASN",
      success: "DELETE_ASN_SUCCESS",
      error: "DELETE_ASN_FAILED",
    });
};

export const editAsn = (asn, po) => (dispatch) => {
  Service.patch(
    dispatch,
    `po/lock/${po.id}`,
    {},
    {
      init: "LOCK_PO",
      success: "LOCK_PO_SUCCESS",
      error: "LOCK_PO_FAILED",
    },
  );
  dispatch({ type: "EDIT_ASN", data: { asn, po } });
};

export const setCancelDate = (date) => (dispatch) => {
  dispatch({ type: "ADD_CANCEL_DATE", data: date });
};

export const createAsnLine = (data) => (dispatch) => {
  dispatch({ type: "CREATE_ASN_LINE", data });
};
export const addAllLinesToAsn = (data) => (dispatch) => {
  dispatch({ type: "ADD_ALL_ASN_LINES", data });
};

export const cancelEditAsn = (po) => (dispatch) => {
  dispatch({ type: "CANCEL_EDIT_ASN" });
};

export const unflagAsn = (data) => (dispatch) => {
  Service.post(dispatch, `asn/un-flag/`, data, {
    init: "UNFLAG_ASN",
    success: "UNFLAG_ASN_SUCCESS",
    error: "UNFLAG_ASN_FAILED",
  });
};

export const createAsnPartial = (data) => (dispatch) => {
  dispatch({ type: "CREATE_ASN_PARTIAL", data });
};

export const updateAsnDetail = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_ASN_DETAIL", data });
};

export const removeAsnLine = (data) => (dispatch) => {
  dispatch({ type: "REMOVE_ASN_LINE", data });
};

export const removeAsnPartial = (data) => (dispatch) => {
  dispatch({ type: "REMOVE_ASN_PARTIAL", data });
};

export const saveAsnErrors = (data) => (dispatch) => {
  dispatch({ type: "SHOW_FAILED_TO_SAVE_ASN_ERRORS", data });
};

export const toggleIgnoreShipWindow = (data) => (dispatch) =>
  Service.patch(dispatch, "asn/ignore-ship-window", data, {
    init: "TOGGLE_IGNORE_SHIP_WINDOW",
    success: "TOGGLE_IGNORE_SHIP_WINDOW_SUCCESS",
    error: "TOGGLE_IGNORE_SHIP_WINDOW_FAILED",
  });

export const startAsn = (data) => (dispatch) =>
  dispatch({ type: "START_ASN", data });

export const extendShipWindow =
  ({ asn, cancelDate }) =>
  (dispatch) =>
    Service.patch(
      dispatch,
      `asn/asns/${asn}`,
      { cancelDate },
      {
        init: "EXTEND_SHIP_WINDOW",
        success: "EXTEND_SHIP_WINDOW_SUCCESS",
        error: "EXTEND_SHIP_WINDOW_FAILED",
      },
    );
