import React from "react";
import { connect } from "react-redux";

export const daysToExpire = ({ user }) => {
  return (new Date(user.user.pw_expire) - Date.now()) / (1000 * 60 * 60 * 24);
};

const PwdExpireAlert = ({ user }) => {
  return daysToExpire({ user }) < 7 ? (
    <p className="alert alert-warning" role="alert">
      Your password expires in {Math.floor(daysToExpire({ user }))} days
    </p>
  ) : (
    ""
  );
};

export default connect(({ user }) => ({ user }))(PwdExpireAlert);
