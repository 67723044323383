import { AddLineToAsn } from "components/AGTable/NewColumns/AddLineToAsn";
import { RemainingQtyColumnDef } from "components/AGTable/NewColumns/ColumnDefinition";
const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
};

export const poLineCreateAsnGrid = {
  columnDefs: [
    AddLineToAsn,
    {
      headerName: "Cancel Date",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.cancel_date",
      width: 115,
    },
    {
      headerName: "Description",
      colId: "description",
      field: "poLine.description",
      width: 225,
    },
    {
      headerName: "Style",
      colId: "style",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.style",
      width: 225,
    },
    {
      headerName: "Vendor Style",
      colId: "vendor_style",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.vendor_style",
      width: 125,
    },
    {
      headerName: "SKU",
      colId: "sku",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.sku",
      width: 225,
    },
    {
      headerName: "Vendor SKU",
      colId: "vendor_sku",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.vendor_sku",
      width: 125,
    },
    {
      headerName: "Color",
      colId: "color",
      cellStyle: { display: "flex", "justify-content": "center" },
      field: "poLine.color",
      width: 150,
    },
    RemainingQtyColumnDef({
      field: "poLine.remaining_qty",
      colId: "remaining_qty",
    }),
  ],
  defaultColDef,
};
