import { RemainingQtyColumnDef } from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
};

export const poLineGrid = {
  columnDefs: [
    {
      headerName: "Start Date",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "start_date",
      field: "poLine.start_date",
      width: 105,
      filter: "RadioSetFilter",
    },
    {
      headerName: "Cancel Date",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "cancel_date",
      field: "poLine.cancel_date",
      width: 115,
      filter: "RadioSetFilter",
    },
    {
      headerName: "Description",
      colId: "description",
      field: "poLine.description",
      width: 225,
    },
    {
      headerName: "Style",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "style",
      field: "poLine.style",
      width: 225,
    },
    {
      headerName: "Vendor Style",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "vendor_style",
      field: "poLine.vendor_style",
      width: 125,
    },
    {
      headerName: "SKU",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "sku",
      field: "poLine.sku",
      width: 225,
    },
    {
      headerName: "Vendor SKU",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "vendor_sku",
      field: "poLine.vendor_sku",
      width: 125,
    },
    {
      headerName: "Color",
      cellStyle: { display: "flex", "justify-content": "center" },
      colId: "color",
      field: "poLine.color",
      width: 155,
    },

    RemainingQtyColumnDef({
      field: "poLine.remaining_qty",
      colId: "remaining_qty",
    }),
  ],
  defaultColDef,
};
