import React, { useEffect } from "react";
import { connect } from "react-redux";
import { poGrid } from "./tables/poGrid";
import { poClientGrid } from "./tables/poClientGrid";
import { resetAsnAlert } from "redux/actions/asn.action";
import ImportBtn from "./components/ImportBtn";
import { ContentHeader, Content, AGTable, Card } from "components";
import { carrier as c } from "config/constants";
import { ActionAlerts } from "components";
import { getPos } from "redux/actions/pos.action";

const Pos = ({ user, po, resetAsnAlert, getPos }) => {
  useEffect(() => {
    resetAsnAlert();
  }, [resetAsnAlert]);

  useEffect(() => {
    getPos();
  }, [getPos]);
  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Purchase Orders" menuType="Basic" />
      <Content>
        {po.error && <ActionAlerts item={po} constants={c} />}

        <Card title="Active Orders">
          {user && user.user.isClient() && <ImportBtn />}
          <AGTable
            tableName="activeOrders"
            poFilter={true}
            isDoneLoading={po.posRetrieved}
            paginate={true}
            gridConfig={user && user.user.isClient() ? poClientGrid : poGrid}
            rowData={po.pos}
            action={getPos}
            next={po.next}
            previous={po.previous}
            totalRows={po.totalRows}
          />
        </Card>
        {/* <Card title="Inactive Purchase Orders">
          <AGTable
            poFilter={true}
            paginate={true}
            gridConfig={user && user.user.isClient() ? poClientGrid : poGrid}
            rowData={filterListByFieldEqualToValue(pos, "active", false)}
          />
        </Card>
        <Card title="Active POs With Zero remaining quantity">
          <AGTable
            poFilter={true}
            paginate={true}
            gridConfig={user && user.user.isClient() ? poClientGrid : poGrid}
            rowData={filterListByFieldEqualToValue(
              filterListByFieldEqualToValue(pos, "active", true),
              "remaining_qty",
              0
            )}
          />
        </Card> */}
      </Content>
    </div>
  );
};

export default connect(({ user, po }) => ({ user, po }), {
  resetAsnAlert,
  getPos,
})(Pos);
