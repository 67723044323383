const initialState = {
  isShipmentStarted: false,
  loadType: "",
  asnsToShip: [],
  shipToStore: "",
  shipFromWarehouse: "",
  shipmentRetrieved: false,
  shipmentsRetrieved: false,
  isSaving: false,
  savingFailed: false,
  savingSucceeded: false,
  shipments: [],
  shipmentsUpToDate: false,
  getShipmentsErrored: false,
  errorMessage: "",
  currentShipment: {},
  deleted: false,
  updated: false,
  added: false,
  error: "",
  quantity: 0,
  totalCartonAndCarrier: [],
};

export default (state = initialState, action = {}) => {
  const allowedTogetherShipments = [49, 59];
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        error: "",
      };
    case "START_SHIPMENT":
      return {
        ...state,
        isShipmentStarted: true,
      };
    case "CANCEL_SHIPMENT":
      return {
        ...state,
        isShipmentStarted: false,
        loadType: "",
        asnsToShip: [],
        shipToStore: "",
        savingFailed: false,
        savingSucceeded: false,
        shipFromWarehouse: "",
      };
    case "ADD_ASN_TO_SHIPMENT":
      const { asnsToShip } = state;
      const { data } = action;
      return {
        ...state,
        asnsToShip: asnsToShip.includes(data)
          ? asnsToShip
          : asnsToShip.concat([data]),
      };
    case "REMOVE_ASN_FROM_SHIPMENT":
      return {
        ...state,
        shipToStore: state.asnsToShip.length === 1 ? "" : state.shipToStore,
        shipFromWarehouse:
          state.asnsToShip.length === 1 ? "" : state.shipFromWarehouse,
        asnsToShip: removeItem(action.data.id, state.asnsToShip),
      };
    case "SET_SHIP_TO_STORE":
      return {
        ...state,
        shipToStore:
          state.shipToStore === ""
            ? action.data
            : allowedTogetherShipments.includes(action.data) &&
                action.data !== state.shipToStore
              ? "49/59"
              : state.shipToStore,
      };
    case "SET_SHIP_FROM_WAREHOUSE":
      return {
        ...state,
        shipFromWarehouse: state.shipFromWarehouse.id
          ? state.shipFromWarehouse
          : action.data,
      };
    case "SET_LOAD_TYPE":
      return {
        ...state,
        loadType: action.data,
      };
    case "SET_QUANTITY":
      return {
        ...state,
        quantity: action.data,
      };
    case "SAVE_SHIPMENT":
      return {
        ...state,
        isSaving: true,
        savingFailed: false,
        savingSucceeded: false,
      };
    case "SAVE_SHIPMENT_SUCCESS":
      return {
        ...state,
        isSaving: false,
        savingSucceeded: true,
        shipmentsUpToDate: false,
        added: true,
      };
    case "SAVE_SHIPMENT_FAILED":
      return {
        ...state,
        errorMessage: action.error.error,
        savingFailed: true,
        isSaving: false,
      };
    case "GET_SHIPMENTS":
      return {
        ...state,
        errorMessage: "",
        shipmentsUpToDate: true,
        shipmentsRetrieved: false,
      };
    case "GET_SHIPMENTS_SUCCESS":
      return {
        ...state,
        shipments: action.data.results,
        shipmentsRetrieved: true,
        getShipmentsErrored: false,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_SHIPMENTS_FAILED":
      return {
        ...state,
        getShipmentsErrored: true,
        errorMessage: action.error.error,
        error: action.error,
        shipmentsUpToDate: false,
      };
    case "GET_SHIPMENT":
      return {
        ...state,
        errorMessage: "",
        gettingShipment: true,
        shipmentRetrieved: false,
        shipmentsUpToDate: true,
      };
    case "GET_SHIPMENT_SUCCESS":
      return {
        ...state,
        currentShipment: action.data,
        gettingShipment: false,
        shipmentRetrieved: true,
        getShipmentErrored: false,
      };
    case "GET_SHIPMENT_FAILED":
      return {
        ...state,
        getShipmentErrored: true,
        gettingShipment: false,
        errorMessage: action.error.error,
        shipmentsUpToDate: false,
      };
    case "UPDATE_SHIPMENT_STATUS":
      return {
        ...state,
        updated: false,
      };
    case "UPDATE_SHIPMENT_STATUS_SUCCESS":
      return {
        ...state,
        shipments: updateShipment(action.data, state.shipments),
        updated: true,
        shipmentsUpToDate: false,
      };
    case "UPDATE_SHIPMENT_STATUS_FAILED":
      return {
        ...state,
        error: action.error,
        updated: false,
      };
    case "DELETE_SHIPMENT":
      return {
        ...state,
        deleted: false,
      };
    case "DELETE_SHIPMENT_SUCCESS":
      return {
        ...state,
        deleted: true,
        shipments: removeItem(action.data, state.shipments),
        shipmentsUpToDate: false,
      };
    case "DELETE_SHIPMENT_FAILED":
      return {
        ...state,
        deleted: false,
        error: action.error,
      };
    case "RESET_UPTODATE_FLAG":
      return {
        ...state,
        shipmentsUpToDate: false,
      };
    case "SET_TOTAL_CARTON":
      return {
        ...state,
        totalCartonAndCarrier: action.data,
      };
    default:
      return state;
  }
};

const removeItem = (id, item) => item.filter((i) => i.id !== id);
const updateShipment = (shipment, shipments) =>
  shipments.map((s) => (s = s.id === shipment.id ? shipment : s));
