import React, { useEffect } from "react";
import { connect } from "react-redux";

import { email as c } from "config/constants";
import { ContentHeader, ActionAlerts, Content, Card } from "components";
import { getEmailLists } from "redux/actions/email.action";
import EmailListCard from "./components/EmailListCard";

function mapStateToProps(state) {
  const { emailListsUpToDate, emailLists, added, updated, deleted, error } =
    state.email;
  return { emailListsUpToDate, emailLists, added, updated, deleted, error };
}

const Email = ({
  emailListsUpToDate,
  getEmailLists,
  emailLists = [],
  added,
  updated,
  deleted,
  error,
}) => {
  const alertTriggers = {
    added,
    updated,
    deleted,
    error,
  };

  useEffect(() => {
    if (!emailListsUpToDate) getEmailLists();
  });

  return (
    <div className="content-wrapper">
      <ContentHeader pagename="Email Lists" />
      <Content>
        <Card title={"Email Groups"}>
          <ActionAlerts item={alertTriggers} constants={c} />
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {emailLists
              .filter(({ emails }) => emails.length > 0)
              .map((group) => (
                <EmailListCard
                  key={group.id}
                  name={group.name}
                  division={group.division}
                  emails={group.emails}
                  emailsRetrieved={emailListsUpToDate}
                />
              ))}
          </div>
          {emailLists.some(({ emails }) => emails.length === 0) && (
            <div>
              <h4>These email groups had no emails:</h4>
              <ul>
                {emailLists
                  .filter(({ emails }) => emails.length === 0)
                  .sort(({ name: a }, { name: b }) => a - b)
                  .map(({ name }) => (
                    <li key={name}>Division {name}</li>
                  ))}
              </ul>
            </div>
          )}
        </Card>
      </Content>
    </div>
  );
};

export default connect(mapStateToProps, {
  getEmailLists,
})(Email);
