import React from "react";
import { ContentHeader, Content, AuthRole } from "components";
import FlaggedPos from "./components/FlaggedPos";
import NewVendors from "./components/NewVendors";
import ExpiringPos from "./components/ExpiringPos";
import ExpiredAsns from "./components/ExpiredAsns";
import LockedUsers from "./components/LockedUsers";
import PwdExpireAlert from "./components/PwdExpireAlert";
import config from "config/config";

const Home = () => {
  return (
    <>
      <div className="content-wrapper">
        <ContentHeader pagename="Home" menuType="Basic" />
        <Content>
          <PwdExpireAlert />
          <AuthRole userRole="vendor">
            {config.getFlaggedFeatureFromLocalStorage() && <FlaggedPos />}
            <ExpiringPos />
          </AuthRole>
          <AuthRole userRole="client">
            {config.getFlaggedFeatureFromLocalStorage() && <FlaggedPos />}
            <ExpiredAsns />
            <NewVendors />
          </AuthRole>
          <AuthRole userRole="clientAdmin">
            <LockedUsers />
          </AuthRole>
        </Content>
      </div>
    </>
  );
};

export default Home;
