import React from "react";
import OpenModalIcon from "./components/OpenModalIcon";
const cellRender = ({ data, context }) => {
  const getUser = sessionStorage.getItem("user");
  const users = JSON.parse(getUser);

  const editAction = (
    <OpenModalIcon
      click={() =>
        context.componentParent.updateData(data, "shipment", "Status")
      }
      icon={"icon-pencil"}
    />
  );

  const deleteAction = (
    <OpenModalIcon
      click={() =>
        context.componentParent.updateData(data, "cancel", "Shipment")
      }
      icon={"icon-trash"}
    />
  );

  if (!users?.vendor) {
    if (data?.carrier?.name !== "CHR") {
      return (
        <>
          {editAction}
          &nbsp;
          {deleteAction}
        </>
      );
    } else {
      return null;
    }
  } else {
    return (
      <>
        {editAction}
        &nbsp;
        {deleteAction}
      </>
    );
  }
};

export const ShipmentUpdate = {
  headerName: "Update",
  filter: null,
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 118,
  field: "pick_up_date",
  sortable: false,
  cellRendererFramework: cellRender,
  suppressSizeToFit: true,
};
