import Service from "./service";
import { resetAlerts } from "./global.action";

export const getUsers = (queryParam) => (dispatch) => {
  Service.get(dispatch, "user/users/", queryParam || {}, {
    init: "GET_USERS",
    success: "GET_USERS_SUCCESS",
    error: "GET_USERS_FAILED",
  });
};

export const assignIdleWarning = () => (dispatch) => {
  dispatch({ type: "ASSIGN_IDLE_WARNING" });
};

export const resetIdleWarning = () => (dispatch) => {
  dispatch({ type: "RESET_IDLE_WARNING" });
};

export const assignSessionExpiredWarning = () => (dispatch) => {
  dispatch({ type: "ASSIGN_SESSION_EXPIRED_WARNING" });
};

export const resetSessionExpiredWarning = () => (dispatch) => {
  dispatch({ type: "RESET_SESSION_EXPIRED_WARNING" });
};

export const assignErrorResponseWarning = () => (dispatch) => {
  dispatch({ type: "ASSIGN_ERROR_RESPONSE_WARNING" });
};
export const resetErrorResponseWarning = () => (dispatch) => {
  dispatch({ type: "RESET_ERROR_RESPONSE_WARNING" });
};
export const reminderTimeWarning = (time) => (dispatch) => {
  dispatch({ type: "REMINDER_TIME_WARNING", time });
};
export const recoverErrorClear = () => (dispatch) => {
  dispatch({ type: "RECOVER_ERROR_MESSAGE" });
};

export const getRoles = () => (dispatch) => {
  Service.get(dispatch, "user/role/", {
    init: "GET_ROLES",
    success: "GET_ROLES_SUCCESS",
    error: "GET_ROLES_FAILED",
  });
};

export const logout = () => (dispatch) => {
  dispatch({ type: "LOGOUT" });
};

export const login = (creds) => (dispatch) => {
  Service.post(dispatch, "login/", creds, {
    init: "LOGIN",
    success: "LOGIN_SUCCESS",
    error: "LOGIN_FAILED",
  });
};
export const refreshToken = (refresh) => (dispatch) => {
  Service.post(
    dispatch,
    "token/refresh",
    { refresh },
    {
      init: "GET_REFRESH_TOKEN",
      success: "GET_REFRESH_TOKEN_SUCCESS",
      error: "GET_REFRESH_TOKEN_FAILED",
    },
  );
};

export const createUser = (user) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.post(dispatch, "user/users/", user, {
    init: "ADD_USER",
    success: "ADD_USER_SUCCESS",
    error: "ADD_USER_FAILED",
  });
};

export const updateUser = (user) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.patch(dispatch, `user/users/${user.id}`, user, {
    init: "UPDATE_USER",
    success: "UPDATE_USER_SUCCESS",
    error: "UPDATE_USER_FAILED",
  });
};

export const updateProfile = (user) => (dispatch) => {
  Service.patch(dispatch, `user/users/${user.id}`, user, {
    init: "UPDATE_PROFILE",
    success: "UPDATE_PROFILE_SUCCESS",
    error: "UPDATE_PROFILE_FAILED",
  });
};

export const deleteUser = (id) => (dispatch) => {
  resetAlerts()(dispatch);
  Service.delete(dispatch, `user/users/${id}/`, {
    init: "DELETE_USER",
    success: "DELETE_USER_SUCCESS",
    error: "DELETE_USER_FAILED",
  });
};

export const validatePasswordResetToken = (token) => (dispatch) => {
  Service.get(
    dispatch,
    `user/password-token/`,
    { token },
    {
      init: "VALIDATE_PASSWORD_TOKEN",
      success: "VALIDATE_PASSWORD_TOKEN_SUCCESS",
      error: "VALIDATE_PASSWORD_TOKEN_FAILED",
    },
  );
};

export const forgotUsersPassword = (data) => (dispatch) => {
  Service.post(dispatch, `user/password-token/`, data, {
    init: "FORGOT_PASSWORD",
    success: "FORGOT_PASSWORD_SUCCESS",
    error: "FORGOT_PASSWORD_FAILED",
  });
};

export const changeUsersPassword = (token, password) => (dispatch) => {
  Service.post(
    dispatch,
    `user/change-password/`,
    { token, password },
    {
      init: "CHANGE_PASSWORD",
      success: "CHANGE_PASSWORD_SUCCESS",
      error: "CHANGE_PASSWORD_FAILED",
    },
  );
};

export const resetLoginError = () => (dispatch) => {
  dispatch({ type: "RESET_LOGIN_ERROR" });
};

export const getLockedUsers = (queryParam) => (dispatch) => {
  Service.get(dispatch, "user/locked-users/", queryParam || {}, {
    init: "GET_LOCKED_USERS",
    success: "GET_LOCKED_USERS_SUCCESS",
    error: "GET_LOCKED_USERS_FAILED",
  });
};

export const unlockUser = (id) => (dispatch) => {
  Service.post(
    dispatch,
    `user/unlock/${id}`,
    {},
    {
      init: "UNLOCK_USER",
      success: "UNLOCK_USER_SUCCESS",
      error: "UNLOCK_USER_FAILED",
    },
  );
};
