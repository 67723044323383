import Service from "./service";

export const getPos = (queryParam) => (dispatch) => {
  Service.get(dispatch, "po/pos/", queryParam || {}, {
    init: "GET_POS",
    success: "GET_POS_SUCCESS",
    error: "GET_POS_FAILED",
  });
};

export const getFlaggedPos = () => (dispatch) => {
  Service.get(
    dispatch,
    "po/pos/",
    { flagged: true },
    {
      init: "GET_FLAGGED_POS",
      success: "GET_FLAGGED_POS_SUCCESS",
      error: "GET_FLAGGED_POS_FAILED",
    },
  );
};

export const getExpiringPos = (queryParam) => (dispatch) => {
  let qp = queryParam || {};
  qp.expiring = true;
  Service.get(dispatch, "po/pos/", qp, {
    init: "GET_EXPIRING_POS",
    success: "GET_EXPIRING_POS_SUCCESS",
    error: "GET_EXPIRING_POS_FAILED",
  });
};

export const getPo = (id) => (dispatch) => {
  Service.get(dispatch, `po/pos/${id}`, {
    init: "GET_PO",
    success: "GET_PO_SUCCESS",
    error: "GET_PO_FAILED",
  });
};

export const getPoLine = (queryParam) => (dispatch) => {
  const poLineId = getValueAfterHyphen(window.location.pathname);
  if (isPositiveNumber(poLineId)) {
    let id = poLineId;
    Service.get(dispatch, `po/pos/${id}/po-lines`, queryParam || {}, {
      init: "GET_POLINE",
      success: "GET_POLINE_SUCCESS",
      error: "GET_POLINE_FAILED",
    });
  }
};
export const unlockPo = (id) => (dispatch) => {
  Service.patch(dispatch, `po/unlock/${id}`, null, {
    init: "UNLOCK_PO",
    success: "UNLOCK_PO_SUCCESS",
    error: "UNLOCK_PO_FAILED",
  });
};

export const unlockPoOnCancel = (id) => (dispatch) => {
  Service.patch(
    dispatch,
    `po/unlock/${id}`,
    { action: "cancel" },
    {
      init: "UNLOCK_PO",
      success: "UNLOCK_PO_SUCCESS",
      error: "UNLOCK_PO_FAILED",
    },
  );
};

export const lockPo = (id) => (dispatch) => {
  Service.patch(dispatch, `po/lock/${id}`, null, {
    init: "LOCK_PO",
    success: "LOCK_PO_SUCCESS",
    error: "LOCK_PO_FAILED",
  });
};

export const importPos = (data) => (dispatch) => {
  Service.post(dispatch, `po/parser/`, data, {
    init: "PARSE_POS",
    success: "PARSE_POS_SUCCESS",
    error: "PARSE_POS_FAILED",
  });
};

const getValueAfterHyphen = (str) => {
  const parts = str.split("-");
  if (parts.length >= 2) {
    return parts[1];
  } else {
    return "";
  }
};

const isPositiveNumber = (num) => !isNaN(num) && parseFloat(num) >= 0;
