import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setModalType } from "redux/actions/modal.action";
import { updateModalData } from "redux/actions/modal.action";

class AddDeleteMenu extends React.Component {
  updateModal() {
    this.props.updateModalData({});
    this.props.setModalType("add");
    this.props.action && this.props.action();
  }
  render() {
    return (
      <div className="header-elements d-none">
        <div className="d-flex justify-content-center">
          <a
            href="# "
            data-toggle="modal"
            data-target="#modal"
            className="btn btn-float text-default"
            onClick={() => this.updateModal()}
          >
            <i className="icon-add text-primary" style={{ fontSize: "23px" }} />
            <span>Add</span>
          </a>

          {/* <a href="# " className="btn btn-link btn-float text-default">
            <i className="icon-subtract text-primary" />
            <span>Remove</span>
          </a> */}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ setModalType, updateModalData }, dispatch),
)(AddDeleteMenu);
