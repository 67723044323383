import React from "react";
import { CarrierWarehouseGrid } from "./tables/CarrierWarehouseGrid";
import { AGTable } from "components";
import { connect } from "react-redux";
import { carrier as c } from "config/constants";

class CarrierWarehouseView extends React.Component {
  render() {
    const {
      modal: { selectedData },
    } = this.props;
    return (
      <>
        <div className="modal-body">
          {selectedData.warehouses && selectedData.warehouses.length === 0 ? (
            c.noWarehousesAssigned
          ) : (
            <AGTable
              isDoneLoading={true}
              tableName="carrierWarehouse"
              gridConfig={CarrierWarehouseGrid}
              rowData={selectedData.warehouses}
            />
          )}
        </div>
      </>
    );
  }
}
export default connect(({ modal }) => ({ modal }), {})(CarrierWarehouseView);
