import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteAsn } from "redux/actions/asn.action";
import ModalFooterDeleteClose from "../components/ModalFooterDeleteClose";
import ModalBodyDelete from "../components/ModalBodyDelete";

class AsnDeleteForm extends React.Component {
  render() {
    const {
      modal: { selectedData },
    } = this.props;
    return (
      <>
        <form>
          <ModalBodyDelete name={selectedData.id} />
          <ModalFooterDeleteClose
            click={() => {
              this.props.deleteAsn(selectedData.id);
            }}
          />
        </form>
      </>
    );
  }
}
export default connect(
  ({ modal }) => ({ modal }),
  (dispatch) => bindActionCreators({ deleteAsn }, dispatch),
)(AsnDeleteForm);
