/* eslint-disable eqeqeq, no-unused-vars, react-hooks/exhaustive-deps, react/no-direct-mutation-state */

import React, { lazy, Suspense } from "react";
import { PageLoading } from "components";
import "./AGTable.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import { connect } from "react-redux";
import { updateModalData, setModalType } from "redux/actions/modal.action";

import {
  createAsnLine,
  createAsnPartial,
  removeAsnLine,
  removeAsnPartial,
  editAsn,
  unflagAsn,
  toggleIgnoreShipWindow,
  extendShipWindow,
} from "redux/actions/asn.action";
import {
  addASNToShipment,
  removeASNFromShipment,
  setShipToStore,
  setShipFromWarehouse,
} from "redux/actions/shipment.action";

import { unlockPo } from "redux/actions/pos.action";
import { forgotUsersPassword, unlockUser } from "redux/actions/user.action";
import {
  selectCarrier,
  addWarehouseToCarrier,
  removeWarehouseFromCarrier,
} from "redux/actions/carrier.action";
import EditDeleteButtons from "./Columns/EditDeleteButtons";
// TODO:remove this
import EditDeleteAsnButtons from "./Columns/EditDeleteAsnButtons";
import Components from "./Columns/Components";
import UnlockButton from "./Columns/UnlockButton";
import SelectCarrier from "./Columns/SelectCarrier";

import RadioSetFilter from "./Filters/RadioSetFilter";
import { search, rowStyles, setWidth } from "./AGTableFunctions";
import config from "config/config";
import Pagination from "./Pagination";
const AgGridReact = lazy(() => import("./GridLazyLoad"));
class AGTable extends React.Component {
  gridRef = React.createRef();
  state = {
    perPageSelection: null,
    sortingOrder: null,
    quickFilterText: null,
    value: "",
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">Currently there are no rows.</span>',
    isSearchDisable: false,
    minRowLengthForSearch: 5,
    defaultPageForSorting: 1,
    notDataMessage: "",
  };

  onGridReady(params) {
    this.api = params.api;
    this.columnApi = params.columnApi;
  }

  serverSideDataSource(page) {
    this.queryParams = {
      page: page,
      ...(this.state.sortingOrder && { ordering: this.state.sortingOrder }),
    };

    if (this.state.quickFilterText)
      this.queryParams.search = this.state.quickFilterText;
    this.props.action && this.props.action(this.queryParams);
  }

  searchAsYouType = (search) => {
    if (this.props.isClientFilter) {
      if (
        this.gridRef.current.api.getModel().rootNode.childrenAfterFilter
          .length === 0
      ) {
        this.gridRef.current.api.showNoRowsOverlay();
      } else {
        this.gridRef.current.api.hideOverlay();
      }
    }
    if (this.props.rowData.length < 21) {
      this.queryParams = {
        search: search,
      };

      this.props.action && this.props.action(this.queryParams);
    }
  };

  onPaginationChanged = (page) => {
    this.setState({ currentPage: page });
    this.serverSideDataSource(page);
  };

  updateData = (data, type, header) => {
    this.props.setModalType(type, header);
    this.props.updateModalData(data);
  };

  unlockPo = (data) => {
    this.props.unlockPo(data.id);
  };

  unlockUser = (data) => {
    this.props.unlockUser(data.id);
  };

  unflagAsn = (data) => {
    if (config.getFlaggedFeatureFromLocalStorage()) this.props.unflagAsn(data);
  };

  //  Carrier Vendor management
  addWarehouseToCarrier = (data) => {
    this.props.addWarehouseToCarrier(data);
  };

  //  Carrier Vendor management
  removeWarehouseFromCarrier = (data) => {
    this.props.removeWarehouseFromCarrier(data);
  };

  timeout = null;
  setSearchState = (event) => {
    this.setState({
      value: event.target.value,
      quickFilterText: event.target.value,
    });

    // Clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than <MILLISECONDS>
    clearTimeout(this.timeout);
    // Make a new timeout set to go off in 1000ms (1 second)
    this.timeout = setTimeout(() => {
      this.searchAsYouType(this.state.value);
    }, 1000);
  };

  createLine(data) {
    if (
      !this.props.asn.cancelDate ||
      data.canAddToAsn(this.props.asn.cancelDate)
    ) {
      this.props.createAsnLine(data);
    }
  }

  selectCarrier(data) {
    this.props.selectCarrier(data);
  }

  addASNToShipTable(data) {
    const po = data.po;
    const { shipFromWarehouse, shipToStore } = this.props.shipment;
    if (
      !shipFromWarehouse.id ||
      (data.warehouse.id === shipFromWarehouse.id &&
        (po.ship_to_store === shipToStore ||
          config
            .getTogetherAllowedStores()
            .includes(Number(shipToStore.toString().split("/")[0])) ||
          config.getTogetherAllowedStores().includes(po.ship_to_store)))
    ) {
      this.props.setShipToStore(po.ship_to_store);
      this.props.setShipFromWarehouse(data.warehouse);
      this.props.addASNToShipment(data);
    }
  }

  addPartial(data) {
    data.canCreatePartial() && this.props.createAsnPartial(data);
  }

  resendEmail(data) {
    this.props.forgotUsersPassword(data);
  }

  getRowNodeId(node) {
    return node.id;
  }
  removeLine(data) {
    if (data.isPartial()) this.props.removeAsnPartial(data);
    else this.props.removeAsnLine(data);
  }
  toggleIgnoreShipWindow(data) {
    this.props.toggleIgnoreShipWindow(data);
  }
  extendShipWindow(data) {
    this.props.extendShipWindow(data);
  }

  removeASNFromShipTable(data) {
    this.props.removeASNFromShipment(data);
  }

  editAsn(asn) {
    const { po } = this.props;
    this.props.editAsn(asn, po.currentPo);
  }

  handlePerPageSelection(e) {
    let perPage = e;
    let page = this.props.next?.split("page=")?.[1]?.split("&")[0];
    this.pages = {
      page: page,
      page_size: perPage,
    };
    this.props.action(this.pages);
  }

  handleSortData(event) {
    const orderData = event.api.getSortModel();
    if (orderData && orderData.length > 0) {
      const sort = orderData
        .map((item) => {
          let colId = item.colId;
          if (colId === "poLine.cancel_date") colId = "cancel_date";
          if (colId === "po.po_number") colId = "po_number";
          return item.sort === "desc" ? `-${colId}` : colId;
        })
        .join(",");
      const { next, action } = this.props;
      const page = next?.split("page=")?.[1]?.split("&")[0];

      this.state.sortingOrder = sort;
      if (this.props.previous || page) {
        action({
          page: this.state.defaultPageForSorting,
          ordering: sort,
        });
      }
    } else {
      this.state.sortingOrder = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rowData !== this.props.rowData) {
      if (
        this.props.rowData.length > this.state.minRowLengthForSearch ||
        this.props.next == null
      ) {
        this.setState({ isSearchDisable: true });
      } else {
        this.setState({ isSearchDisable: false });
      }
      this.setState({ notDataMessage: "" });
    }
  }

  render() {
    const {
      isSearchEnable,
      coustomPagination,
      noMultiSortKey,
      isSearchWidth,
      tableName,
      onSortChanged,
      gridConfig,
      noStyle,
      rowData,
      isDoneLoading = false,
      contentName = "data",
      rowHeight,
      carton,
      asn,
      po,
      user,
      shipment,
      carrier,
      isPerPage,
    } = this.props;

    const perPageSelection = [20, 50, 100]; // Numbers array

    gridConfig.context = {
      componentParent: this,
    };
    // export default CustomSpinner;
    gridConfig.gridOptions = {
      getRowStyle(params) {
        const { data } = params;
        if (!noStyle) {
          return rowStyles(data, asn, shipment, carrier, user);
        }
      },
    };

    gridConfig.frameworkComponents = {
      EditDeleteButtons,
      // TODO:remove this
      EditDeleteAsnButtons,
      Components,
      UnlockButton,
      SelectCarrier,
      RadioSetFilter,
    };

    const hasLoadedNoData =
      isDoneLoading &&
      rowData &&
      rowData.length === 0 &&
      !this.state.quickFilterText;

    const hasLoadedData =
      isDoneLoading ||
      (rowData && rowData.length > 0) ||
      this.state.quickFilterText;

    // TODO: We should probably implement something like this when we have time to flush out the details
    // const defaultColDef={};
    // const maintainOriginalOrder = ()=> 0;
    // if (gridConfig.enableServerSideSorting){defaultColDef.comparator=maintainOriginalOrder};

    if (hasLoadedNoData) {
      setTimeout(() => {
        this.setState({ notDataMessage: "No Data found." });
      }, 1000);
      return <> {this.state.notDataMessage} </>;
    } else if (hasLoadedData) {
      return (
        <>
          {search(
            this.setSearchState,
            this.state.value,
            isSearchWidth,
            isSearchEnable
              ? rowData?.length > this.state.minRowLengthForSearch
              : this.state.isSearchDisable,
          )}
          <Suspense fallback={<PageLoading />}>
            <div
              id="myGrid"
              className="ag-theme-balham ag-gride-coustomize"
              style={{ width: setWidth(gridConfig) }}
            >
              {isPerPage && (
                <div className="pb-2">
                  <label
                    htmlFor="number"
                    className="pr-1 selection-font font-size-lg"
                  >
                    Per Page :{" "}
                  </label>
                  <select
                    id="number"
                    defaultValue={50}
                    className="selection-input"
                    value={this.state.perPageSelection} // Set the selected value
                    onChange={(e) =>
                      this.handlePerPageSelection(e.target.value)
                    } // Handle change event
                  >
                    {perPageSelection.map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <AgGridReact
                key={`${rowData?.length}_${this.state.perPageSelection}_${asn?.lines?.length}_${shipment?.asnsToShip?.length}`}
                quickFilterText={
                  this.props.action ? undefined : this.state.quickFilterText
                }
                ref={this.gridRef}
                animateRows
                onGridReady={this.onGridReady.bind(this)}
                domLayout={"autoHeight"}
                multiSortKey={noMultiSortKey ? "" : "ctrl"}
                rowHeight={rowHeight}
                pagination={
                  coustomPagination ? 50 : rowData && rowData.length >= 21
                }
                paginationPageSize={this.state.perPageSelection || 50}
                {...gridConfig}
                loadingOverlayComponentParams={gridConfig.gridOptions}
                rowData={isDoneLoading ? rowData : []}
                onSortChanged={(e) => this.handleSortData(e)}
                getRowNodeId={this.getRowNodeId}
                carton={carton}
                po={po}
                user={user}
                suppressHorizontalScroll="true"
                overlayNoRowsTemplate={
                  !this.state.value || !isDoneLoading
                    ? `<div class="ag-overlay-loading-left d-flex align-items-center justify-content-end border-0 mt-2"> <div class="custom-spinner"></div> <span class="mx-2 loader-text"> One Moment Please...  </span></div>`
                    : `<span class="ag-overlay-loading-center border-0 mt-2">No Data found.</span>`
                }
                // defaultColDef={defaultColDef}
              />
              {(this.props.next || this.props.previous) && (
                <Pagination
                  totalRows={this.props.totalRows}
                  pageLength={rowData.length}
                  next={this.props.next}
                  previous={this.props.previous}
                  onPaginationChanged={this.onPaginationChanged}
                />
              )}
            </div>
          </Suspense>
        </>
      );
    }
    return <PageLoading />;
  }
}

export default connect(
  ({ carton, asn, po, user, shipment, carrier }) => ({
    carton,
    asn,
    po,
    user,
    shipment,
    carrier,
  }),
  {
    updateModalData,
    setModalType,
    createAsnLine,
    createAsnPartial,
    removeAsnLine,
    removeAsnPartial,
    toggleIgnoreShipWindow,
    unlockPo,
    editAsn,
    unflagAsn,
    forgotUsersPassword,
    addASNToShipment,
    removeASNFromShipment,
    setShipToStore,
    setShipFromWarehouse,
    selectCarrier,
    addWarehouseToCarrier,
    removeWarehouseFromCarrier,
    unlockUser,
    extendShipWindow,
  },
)(AGTable);
