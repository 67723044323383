import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createCarrier } from "redux/actions/carrier.action";
import { Typeahead } from "react-bootstrap-typeahead";
import country from "assets/data/Country";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { validateEmail } from "utils/helperFunctions";

class CarrierModalForm extends React.Component {
  initialState() {
    return {
      data: {
        client: this.props.user.user.client.id,
        name: "",
        city: "",
        state: "",
        zipcode: "",
        country: "US",
        email: "",
        contact_name: "",
        phone: "",
        address: "",
        max_weight: "999999",
        min_weight: "0",
        max_volume: "999999",
        min_volume: "0",
        pick_up_window: "12:00 AM-12:00 P.M",
        xml: 0,
        default: 0,
      },
      errors: {
        email: "",
        state: "",
      },
    };
  }

  state = this.initialState();

  updateCarrier(field, value) {
    let trimmedValue = value;

    // If the field is not "default" or "xml", process the value
    if (field !== "default" && field !== "xml") {
      trimmedValue =
        value === "" || value.trim() !== "" ? value : this.state.data[field];
    }

    // Update the state
    let st = this.state;
    st.data[field] = trimmedValue;
    this.setState(st);
  }

  clearFields() {
    this.refs.typeahead.getInstance().clear();
    this.setState(this.initialState());
  }

  closeModal() {
    this.clearFields();
    this.closeButton.click();
  }

  handleSubmit() {
    const { email, state } = this.state.data;
    let errors = {};

    if (email && !validateEmail(email)) {
      errors.email = "Invalid email address format";
    }

    if (state === "" || typeof state === "undefined") {
      errors.state = "Please select a valid state";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.props.createCarrier(this.state.data);
    this.clearFields();
    this.closeModal();
  }

  render() {
    const { data, errors } = this.state;
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="modal-body">
            <div className="form-row">
              <div className="col-md-3 mb-3">
                <label htmlFor="firstname">Name</label>
                <input
                  type="text"
                  pattern="^[A-Za-z\s]+$"
                  title="Name can only contain letters and spaces."
                  className="form-control"
                  id="firstname"
                  placeholder="Carrier name"
                  value={data.name}
                  onChange={(e) => this.updateCarrier("name", e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="contact_name">Contact</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_name"
                  placeholder="Contact"
                  value={data.contact_name}
                  onChange={(e) =>
                    this.updateCarrier("contact_name", e.target.value)
                  }
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  minLength="8"
                  maxLength="25"
                  pattern="[0-9]{10}"
                  title="Enter a valid number"
                  className="form-control"
                  id="phone"
                  placeholder="000 000 0000"
                  value={data.phone}
                  onChange={(e) => this.updateCarrier("phone", e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    value={data.email}
                    onChange={(e) => {
                      this.updateCarrier("email", e.target.value);
                      this.setState((prevState) => ({
                        errors: {
                          ...prevState.errors,
                          email: "",
                        },
                      }));
                    }}
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                </div>
                {errors.email && (
                  <div className="error-color">{errors.email}</div>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  value={data.address}
                  onChange={(e) =>
                    this.updateCarrier("address", e.target.value)
                  }
                  placeholder="Address"
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="City">City</label>
                <input
                  type="text"
                  pattern="[A-Za-z\s]{1,30}"
                  className="form-control"
                  id="city"
                  value={data.city}
                  onChange={(e) => this.updateCarrier("city", e.target.value)}
                  placeholder="City"
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="zipcode">Zipcode</label>
                <input
                  type="text"
                  pattern="^\d{5}(-\d{4})?$"
                  title="Invalid ZIP code format. Use 12345 or 12345-6789."
                  className="form-control"
                  id="zipcode"
                  value={data.zipcode}
                  onChange={(e) =>
                    this.updateCarrier("zipcode", e.target.value)
                  }
                  placeholder="Zipcode"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3 mb-3">
                <label htmlFor="Country">Country</label>
                <Typeahead
                  id="Country"
                  paginationText="NEXT PAGE"
                  labelKey="name"
                  clearButton={true}
                  searchText
                  options={country.map((i) => i.code)}
                  placeholder="Country"
                  ref="typeahead"
                  defaultInputValue={data.country}
                  onChange={(selected) => {
                    this.updateCarrier(
                      "country",
                      selected.length > 0 ? selected[0] : "",
                    );
                  }}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  pattern="[A-Za-z]+"
                  maxLength={25}
                  className="form-control"
                  id="State"
                  placeholder="State Like AL"
                  value={data.state}
                  onChange={(e) => {
                    this.updateCarrier("state", e.target.value);
                    this.setState((prevState) => ({
                      errors: {
                        ...prevState.errors,
                        state: "",
                      },
                    }));
                  }}
                  required
                />
                {errors.state && (
                  <div style={{ color: "red" }}>{errors.state}</div>
                )}
              </div>

              {this.props.user.user?.vendor ? (
                ""
              ) : (
                <>
                  <div className="col-md-3 mb-3 pr-5">
                    <label htmlFor="consolidator">Consolidator</label>
                    <div className="pr-5">
                      <input
                        type="checkbox"
                        className="form-control"
                        id="consolidator"
                        value="1"
                        onChange={(e) => {
                          const value =
                            e.target.type === "checkbox"
                              ? e.target.checked
                              : e.target.value;
                          this.updateCarrier("xml", value === true ? 1 : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 mb-3">
                    <label htmlFor="default">Default</label>
                    <input
                      type="checkbox"
                      className="form-control"
                      id="default"
                      value="1"
                      onChange={(e) => {
                        const value =
                          e.target.type === "checkbox"
                            ? e.target.checked
                            : e.target.value;
                        this.updateCarrier("default", value === true ? 1 : 0);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.clearFields()}
              data-dismiss="modal"
              ref={(button) => (this.closeButton = button)}
            >
              Close
            </button>
            <button id="submit-btn" type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default connect(
  ({ modal, vendor, carrier, user }) => ({ modal, vendor, carrier, user }),
  (dispatch) => bindActionCreators({ createCarrier }, dispatch),
)(CarrierModalForm);
